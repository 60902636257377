import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import { BaseModal } from '../base/BaseModal';
import { mq } from '@styles/breakpoints';
import { useI18NextContext } from '@components/hooks';
import { ImageGallery } from '@components/common/image-gallery/ImageGallery';
import { Icon } from '@components/common/generic-icon';

/* ***************** *
 *       Types       *
 * ***************** */
export interface InfoCardModalProps {
  theme?: Theme;
  testId?: string;
  title: string;
  show: boolean;
  gallery: Array<string>;
  onDismiss: () => void;
  description: string | JSX.Element;
  showGalleryDots?: boolean;
  cabinInformation?: string | null;
}

/* ***************** *
 *       Styles      *
 * ***************** */

const MoreDetailsWrapper = styled.div({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const MoreDetailsContainer = styled.div(({ theme }: ThemeProps) => ({
  backgroundColor: theme.custom.colors.white,
  position: 'relative',
  margin: theme.custom.spacing.medium,
  maxWidth: 550,

  [mq.medium]: {
    maxWidth: 720,
    width: '50%',
    margin: '0 auto'
  }
}));

export const Fade = styled.div(({ theme }: ThemeProps) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: theme.custom.spacing.large,
  height: 50,
  background: 'url("/assets/images/description-gradient.png") bottom left repeat-x'
}));

const CloseIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  cursor: 'pointer',
  fontSize: '2rem',
  color: theme.custom.colors.group4.light,
  transition: `color ${theme.custom.transitions.default}`,

  ['&:hover']: {
    color: theme.custom.colors.group4.base
  }
}));

const Title = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  margin: 0,
  padding: theme.custom.spacing.large,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Description = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  marginTop: theme.custom.spacing.large,
  padding: theme.custom.spacing.large,
  paddingTop: 0,
  overflowY: 'scroll',
  maxHeight: 170
}));

const DescriptionWrapper = styled.div(({ theme }: ThemeProps) => ({
  paddingBottom: theme.custom.spacing.xLarge,
  ['&:last-of-type']: {
    paddingBottom: theme.custom.spacing.xxLarge
  }
}));

const SubTitle = styled.h6(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h6 as any)
}));

/* **************************** *
 *    InfoCardModalComponent    *
 * **************************** */

export const InfoCardModalComponent = ({
  testId,
  show,
  gallery = [],
  onDismiss,
  description,
  title,
  showGalleryDots,
  cabinInformation
}: InfoCardModalProps) => {
  const t: any = useI18NextContext();
  return (
    <BaseModal testId={testId || TestId.modal.infoCardModal.main} open={show} onDismiss={onDismiss} hideCloseButton>
      <MoreDetailsWrapper>
        <MoreDetailsContainer>
          <Title>
            {title}
            <CloseIcon name={'mhi-clear'} onClick={onDismiss} />
          </Title>
          <ImageGallery
            galleryImages={gallery.map((url: string) => ({ thumbnail: url, original: url }))}
            showGalleryDots={showGalleryDots}
          />
          <Description>
            <DescriptionWrapper>{description}</DescriptionWrapper>
            {cabinInformation && (
              <DescriptionWrapper>
                <SubTitle>{t('vessel-info-group__cabin-information')}</SubTitle>
                {cabinInformation}
              </DescriptionWrapper>
            )}
          </Description>
          <Fade />
        </MoreDetailsContainer>
      </MoreDetailsWrapper>
    </BaseModal>
  );
};

export const InfoCardModal = withTheme(InfoCardModalComponent);
