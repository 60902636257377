import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '@state/modal/modalOperations';
import { PaymentFormValues } from '@model/forms';
import { getPaymentForm } from '@state/payment/paymentFormSelectors';
import { PaymentPageFormError } from '@components/modal/form-error/payment-page-form-error/PaymentPageFormError';
import { getIsAgent, getRequiresPayment } from '@state/agent/agentSelectors';

export const PaymentPageErrorFormContainer = () => {
  const dispatch = useDispatch();
  const handleOnClick = () => dispatch(closeModal());
  const paymentForm: PaymentFormValues = useSelector(getPaymentForm);
  const isAgent: boolean = useSelector(getIsAgent);
  const requiresPayment: boolean = useSelector(getRequiresPayment);
  return (
    <PaymentPageFormError
      isAgent={isAgent}
      requiresPayment={requiresPayment}
      form={paymentForm}
      onClick={handleOnClick}
    />
  );
};
