import { FormikValues } from 'formik';
import { isEqual } from 'lodash';
import { Validation } from '@model/forms/validation/validation';

export const validateEmailSignUpForm = (values: FormikValues) => {
  const errors: any = {};

  const validFormErrors = {};

  if (!values.email) {
    errors.email = 'form__field-error--required';
  } else if (!Validation.isEmailValid(values.email)) {
    errors.email = 'form__field-error--email';
  }

  const isValid = isEqual(validFormErrors, errors);

  return { errors, isValid };
};
