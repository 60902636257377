import { CheckboxResponse } from '@components/common/checkbox';

export const getErrors: (touched: any, errors: any, fieldName: string) => string = (
  touched: any,
  errors: any,
  fieldName: string
) => {
  let isTouched = touched;
  let errorMsg = errors;
  const fields = fieldName.split('.');
  let i = 0;

  while (i < fields.length) {
    const isPropAnArray = fields[i].includes('[') && fields[i].includes(']');
    const propIndex = isPropAnArray
      ? parseInt(fields[i].substring(fields[i].indexOf('[') + 1, fields[i].indexOf(']')))
      : null;
    const propName = isPropAnArray ? fields[i].substring(0, fields[i].indexOf('[')) : fields[i];

    if (
      (isPropAnArray && isTouched[propName] && isTouched[propName][propIndex!]) ||
      (!isPropAnArray && isTouched[propName])
    ) {
      isTouched = isPropAnArray ? isTouched[propName][propIndex!] : isTouched[propName];
    } else {
      isTouched = false;
    }

    if (
      (isPropAnArray && errorMsg[propName] && errorMsg[propName][propIndex!]) ||
      (!isPropAnArray && errorMsg[propName])
    ) {
      errorMsg = isPropAnArray ? errorMsg[propName][propIndex!] : errorMsg[propName];
    } else {
      errorMsg = '';
    }

    i += 1;
  }

  return !!isTouched && !!errorMsg && errorMsg;
};

export const checkboxResponseToEvent: any = (checkboxResponse: CheckboxResponse, useValue?: boolean) => ({
  target: {
    name: checkboxResponse.name,
    value: checkboxResponse.checked ? (useValue ? checkboxResponse.value : true) : useValue ? '' : false,
    checked: checkboxResponse.checked
  }
});

export const datePickerToEvent: any = ({ name, startDate, endDate }: any) => ({
  target: {
    name,
    value: { startDate, endDate }
  }
});

export const toFormikEvent: any = ({ name, value }: any) => ({
  target: {
    name,
    value
  }
});
