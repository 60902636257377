import React, { useEffect, useRef, useState } from 'react';
import { AirportGroup, BooleanTagBuilder, BooleanTagType, GeographyResult } from '@model/search';
import { Airport } from '@model/common';
import { flatten } from 'lodash';
import { SelectedDestination } from '@model/search/fit/selected-destination';
import { Destinations } from '@components/search/tabs/fit/destinations/Destinations';
import { useAsync } from 'react-use';
import { AlgoliaApi, AlgoliaFilter, AlgoliaIndex } from '@model/iceberg/service/algolia/AlgoliaApi';

export interface DestinationsContainerProps {
  destination: SelectedDestination;
  airports: AirportGroup;
  onChange: (destination: SelectedDestination) => void;
  onClear: () => void;
  condensed: boolean;
  error?: boolean;
}

export const DestinationsContainer = (props: DestinationsContainerProps) => {
  const { condensed, airports, destination, onChange, onClear, error } = props;
  const [filters, setFilters] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);
  const searchRef: any = useRef();
  const flattenedAirports: Array<Airport> = flatten(Object.values(airports));
  const tags: Array<string> = flattenedAirports.map((airport: Airport) => airport.code);
  useEffect(() => {
    setFilters(new BooleanTagBuilder(tags, BooleanTagType.OR).build() as any);
  }, [airports]);

  const searchableDestinations: {
    loading: boolean;
    value?: Array<GeographyResult>;
    error?: any;
  } = useAsync(
    async () => await new AlgoliaApi().browse({ index: AlgoliaIndex.GEOGRAPHY, filters: AlgoliaFilter.DESTINATIONS }),
    [filters]
  );
  const handleOnChange = ({ name, _tags }: GeographyResult) => onChange({ name, airports: _tags });
  const handleOnBlur = () => {
    if (active) {
      setActive(false);
    }
  };
  const handleOnFocus = () => {
    setActive(true);
    searchRef && searchRef.current && searchRef.current.focus();
  };
  return (
    <Destinations
      active={active}
      condensed={condensed}
      filters={filters}
      destination={destination.name}
      onFocus={handleOnFocus}
      onChange={handleOnChange}
      onClear={onClear}
      onBlur={handleOnBlur}
      searchRef={searchRef}
      searchableDestinations={searchableDestinations?.value || []}
      airports={tags}
      error={error}
    />
  );
};

DestinationsContainer.defaultProps = {
  condensed: false
};
