import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '@state/modal/modalOperations';
import { FormSubmitErrorModal } from '@components/modal/form-submit-status/error/FormSubmitError';
import { getModalData } from '@state/modal/modalSelectors';

export const FormSubmitErrorModalContainer = () => {
  const modalData = useSelector(getModalData);
  const { closeButtonLabel, titleLabel, bodyLabel } = modalData;
  const dispatch = useDispatch();
  const handleOnClick = () => dispatch(closeModal());
  return (
    <FormSubmitErrorModal
      onClick={handleOnClick}
      closeButtonLabel={closeButtonLabel}
      titleLabel={titleLabel}
      bodyLabel={bodyLabel}
    />
  );
};
