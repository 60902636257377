import { EndPoint, getURL, Request } from '@model/service';
import uri from 'urijs';
import { CustomerEndpoints } from '@model/iceberg/service/customer/customer-endpoints';
import { OrderBrochureFormValues } from '@model/forms';

export interface SubscribeToNewsletterPayload {
  email: string;
  reCaptcha: string;
}

interface CustomerApiRequests {
  subscribeToNewsletter: (payload: SubscribeToNewsletterPayload) => Promise<any>;
}

export class CustomerApi implements CustomerApiRequests {
  private api: Request = new Request();

  public async subscribeToNewsletter(payload: SubscribeToNewsletterPayload): Promise<any> {
    return await this.api.post(
      uri(getURL()).path(`${EndPoint.CUSTOMER}${CustomerEndpoints.NEWSLETTER_SUBSCRIBE}`).href(),
      payload
    );
  }

  public async orderBrochure(payload: OrderBrochureFormValues): Promise<any> {
    return await this.api.post(
      uri(getURL()).path(`${EndPoint.CUSTOMER}${CustomerEndpoints.ORDER_BROCHURE}`).href(),
      payload
    );
  }
}
