import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { FieldLabel, FieldSubLabel, FormSearchFieldContainer } from '@styles/forms';
import { Localisation } from '@model/common';
import { getTranslation } from '@util/common';
import { AirportGroup } from '@model/search';
import { SelectedDestination } from '@model/search/fit/selected-destination';
import { Collapse } from '@mui/material';
import { DestinationsContainer } from '@components/search/tabs/fit/destinations/DestinationsContainer';
import { toFormikEvent } from '@util/forms';

/* ***************** *
 *       Types       *
 * ***************** */
export interface FormikDestinationFieldProps {
  theme?: Theme;
  testId?: string;
  name: string;
  label?: string | Localisation;
  subLabel?: string;
  destination: SelectedDestination;
  onChange: (destination: SelectedDestination) => void;
  css?: any;
  airports?: AirportGroup | null;
}

export const EMPTY_SELECTED_DESTINATION: SelectedDestination = {
  airports: [],
  name: {
    path: '',
    display: ''
  }
};

/* ************************************* *
 *    FormikDestinationFieldComponent    *
 * ************************************* */

export const FormikDestinationFieldComponent = (props: FormikDestinationFieldProps) => {
  const { label, onChange, css, subLabel, airports, destination, name } = props;
  const t: any = useI18NextContext();

  /* *** LOGIC *** */

  const handleDestinationChange = (value: SelectedDestination) => onChange(toFormikEvent({ name, value }));

  return (
    <FormSearchFieldContainer data-testid={props.testId || TestId.formikDestinationField.main} css={css}>
      <Collapse in={!!label}>
        <FieldLabel>
          {getTranslation(t, label)}
          {subLabel && <FieldSubLabel>{getTranslation(t, subLabel)}</FieldSubLabel>}
        </FieldLabel>
      </Collapse>
      <DestinationsContainer
        onChange={(destination: SelectedDestination) => handleDestinationChange(destination)}
        destination={destination}
        airports={airports || []}
        onClear={() => handleDestinationChange(EMPTY_SELECTED_DESTINATION)}
        condensed
      />
    </FormSearchFieldContainer>
  );
};

export const FormikDestinationField = withTheme(FormikDestinationFieldComponent);
