import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Page, Keyable } from '@model/common';
import { isTimeoutError } from '@model/common/error-codes';
import { closeModal } from '@state/modal/modalOperations';
import { getModalData } from '@state/modal/modalSelectors';
import { getSearchParams, getToursByType } from '@state/search/tours';
import { receiveToursTokenFailure } from '@state/search-results/tours';
import { unsetLeadInTourReferences } from '@state/tours/tour-references';
import { performNavigate } from '@state/app';
import { useI18NextContext } from '@components/hooks';
import { ErrorModal } from '@components/common/modal/alert/error/ErrorModal';
import { getToursSearchUrlParams } from '@util/tours';

export const ToursErrorModalContainer = () => {
  const t: any = useI18NextContext();
  const dispatch = useDispatch();
  const { errorCode, tripType } = useSelector(getModalData);
  const searchParams = useSelector(getSearchParams);
  const tours = useSelector(getToursByType(tripType));
  const isTimeout = isTimeoutError(errorCode);

  const handleOnClick = () => {
    if (isTimeout) {
      dispatch(receiveToursTokenFailure());
      dispatch(unsetLeadInTourReferences());
      const query = getToursSearchUrlParams({ searchParams, tours, tripType }) as Keyable;
      dispatch(
        performNavigate({
          pathname: Page.TOURS_SEARCH,
          query
        })
      );
    } else {
      dispatch(closeModal());
    }
  };
  const renderBody = () => {
    return <span>{t(isTimeout ? 'error-modal-tours-timeout-body' : 'error-modal-tours-body')}</span>;
  };
  return (
    <ErrorModal
      onClick={handleOnClick}
      iconName="mhi-search-error"
      titleKey={isTimeout ? 'token__expiration-modal--title' : 'common__label--something-wrong'}
      renderBody={renderBody}
      buttonLabelKey={isTimeout ? 'token__expiration-message--button' : 'error-modal-tours-button'}
      code={errorCode}
    />
  );
};
