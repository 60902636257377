const CARICOM_NATIONS = ['AG', 'BB', 'GD', 'LC'];

export const countryCodeMapping = (code?: string): string => {
  if (code && CARICOM_NATIONS.includes(code)) {
    return 'XC';
  }
  return code || '';
};

export const countrySpriteMapping: { [key: string]: number } = {
  ['AD']: 0,
  ['AE']: -26,
  ['AF']: -52,
  ['AG']: -78,
  ['AI']: -104,
  ['AL']: -130,
  ['AM']: -156,
  ['AN']: -182,
  ['AO']: -208,
  ['AQ']: -234,
  ['AR']: -260,
  ['AS']: -286,
  ['AT']: -312,
  ['AU']: -338,
  ['AW']: -364,
  ['AX']: -390,
  ['AZ']: -416,
  ['BA']: -442,
  ['BB']: -468,
  ['BD']: -494,
  ['BE']: -520,
  ['BF']: -546,
  ['BG']: -572,
  ['BH']: -598,
  ['BI']: -624,
  ['BJ']: -650,
  ['BL']: -676,
  ['BM']: -702,
  ['BN']: -728,
  ['BO']: -754,
  ['BR']: -780,
  ['BS']: -806,
  ['BT']: -832,
  ['BW']: -858,
  ['BY']: -884,
  ['BZ']: -910,
  ['CA']: -936,
  ['CC']: -962,
  ['CD']: -988,
  ['CF']: -1014,
  ['CG']: -1040,
  ['CH']: -1066,
  ['CI']: -1092,
  ['CK']: -1118,
  ['CL']: -1144,
  ['CM']: -1170,
  ['CN']: -1196,
  ['CO']: -1222,
  ['CR']: -1248,
  ['CT']: -1274,
  ['CU']: -1300,
  ['CV']: -1326,
  ['CW']: -1352,
  ['CX']: -1378,
  ['CY']: -1404,
  ['CZ']: -1430,
  ['DE']: -1456,
  ['DJ']: -1482,
  ['DK']: -1508,
  ['DM']: -1534,
  ['DO']: -1560,
  ['DZ']: -1586,
  ['EC']: -1612,
  ['EE']: -1638,
  ['EG']: -1664,
  ['EH']: -1690,
  ['ER']: -1716,
  ['ES']: -1742,
  ['ET']: -1768,
  ['EU']: -1794,
  ['FI']: -1820,
  ['FJ']: -1846,
  ['FK']: -1872,
  ['FM']: -1898,
  ['FO']: -1924,
  ['FR']: -1950,
  ['GA']: -1976,
  ['GB']: -2002,
  ['GD']: -2028,
  ['GE']: -2054,
  ['GG']: -2080,
  ['GH']: -2106,
  ['GI']: -2132,
  ['GL']: -2158,
  ['GM']: -2184,
  ['GN']: -2210,
  ['GQ']: -2236,
  ['GR']: -2262,
  ['GS']: -2288,
  ['GT']: -2314,
  ['GU']: -2340,
  ['GW']: -2366,
  ['GY']: -2392,
  ['HK']: -2418,
  ['HN']: -2444,
  ['HR']: -2470,
  ['HT']: -2496,
  ['HU']: -2522,
  ['IC']: -2548,
  ['ID']: -2574,
  ['IE']: -2600,
  ['IL']: -2626,
  ['IM']: -2652,
  ['IN']: -2678,
  ['IQ']: -2704,
  ['IR']: -2730,
  ['IS']: -2756,
  ['IT']: -2782,
  ['JE']: -2808,
  ['JM']: -2834,
  ['JO']: -2860,
  ['JP']: -2886,
  ['KE']: -2912,
  ['KG']: -2938,
  ['KH']: -2964,
  ['KI']: -2990,
  ['KM']: -3016,
  ['KN']: -3042,
  ['KP']: -3068,
  ['KR']: -3094,
  ['KW']: -3120,
  ['KY']: -3146,
  ['KZ']: -3172,
  ['LA']: -3198,
  ['LB']: -3224,
  ['LC']: -3250,
  ['LI']: -3276,
  ['LK']: -3302,
  ['LR']: -3328,
  ['LS']: -3354,
  ['LT']: -3380,
  ['LU']: -3406,
  ['LV']: -3432,
  ['LY']: -3458,
  ['MA']: -3484,
  ['MC']: -3510,
  ['MD']: -3536,
  ['ME']: -3562,
  ['MF']: -3588,
  ['MG']: -3614,
  ['MH']: -3640,
  ['MK']: -3666,
  ['ML']: -3692,
  ['MM']: -3718,
  ['MN']: -3744,
  ['MO']: -3770,
  ['MP']: -3796,
  ['MQ']: -3822,
  ['MR']: -3848,
  ['MS']: -3874,
  ['MT']: -3900,
  ['MU']: -3926,
  ['MV']: -3952,
  ['MW']: -3978,
  ['MX']: -4004,
  ['MY']: -4030,
  ['MZ']: -4056,
  ['NA']: -4082,
  ['NC']: -4108,
  ['NE']: -4134,
  ['NF']: -4160,
  ['NG']: -4186,
  ['NI']: -4212,
  ['NL']: -4238,
  ['NO']: -4264,
  ['NP']: -4290,
  ['NR']: -4316,
  ['NU']: -4342,
  ['NZ']: -4368,
  ['OM']: -4394,
  ['PA']: -4420,
  ['PE']: -4446,
  ['PF']: -4472,
  ['PG']: -4498,
  ['PH']: -4524,
  ['PK']: -4550,
  ['PL']: -4576,
  ['PN']: -4602,
  ['PR']: -4628,
  ['PS']: -4654,
  ['PT']: -4680,
  ['PW']: -4706,
  ['PY']: -4732,
  ['QA']: -4758,
  ['RE']: -4784,
  ['RO']: -4810,
  ['RU']: -4836,
  ['RS']: -4862,
  ['SA']: -4888,
  ['RW']: -4914,
  ['SB']: -4940,
  ['SC']: -4966,
  ['SG']: -4992,
  ['SD']: -5018,
  ['SE']: -5044,
  ['SH']: -5070,
  ['SI']: -5096,
  ['SK']: -5122,
  ['SL']: -5148,
  ['SM']: -5174,
  ['SN']: -5200,
  ['SR']: -5226,
  ['SO']: -5252,
  ['SV']: -5278,
  ['SX']: -5304,
  ['ST']: -5330,
  ['SS']: -5356,
  ['SZ']: -5382,
  ['SY']: -5408,
  ['TD']: -5434,
  ['TC']: -5460,
  ['TK']: -5486,
  ['TG']: -5512,
  ['TL']: -5538,
  ['TF']: -5564,
  ['TH']: -5590,
  ['TJ']: -5616,
  ['TN']: -5642,
  ['TM']: -5668,
  ['TO']: -5694,
  ['TW']: -5720,
  ['TZ']: -5746,
  ['TR']: -5772,
  ['TT']: -5798,
  ['TV']: -5824,
  ['UA']: -5850,
  ['UG']: -5876,
  ['US']: -5902,
  ['UY']: -5928,
  ['UZ']: -5954,
  ['VA']: -5980,
  ['VC']: -6006,
  ['VE']: -6032,
  ['VG']: -6058,
  ['VI']: -6084,
  ['VN']: -6110,
  ['VU']: -6136,
  ['WF']: -6162,
  ['WS']: -6188,
  ['YE']: -6214,
  ['YT']: -6240,
  ['ZA']: -6266,
  ['ZM']: -6292,
  ['ZW']: -6318,
  ['XC']: -6344
};
