import { FormError } from '@components/modal/form-error/FormError';
import React from 'react';
import { GuestFormValues } from '@model/forms';
import styled from '@emotion/styled';
import { useI18NextContext } from '@components/hooks';
import { Validation } from '@model/forms/validation/validation';
import { ThemeProps } from '@theme/base';

export interface GuestError {
  name: string;
  errors: Array<string>;
}

export interface GuestPageFormErrorProps {
  guests: Array<GuestFormValues>;
  isAgent: boolean;
  onClick: () => void;
}

const ListItem: any = styled.li(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any)
}));

const GuestTitle: any = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 'bold'
}));

export function getGuestErrors(guests: Array<GuestFormValues>, isAgent: boolean, t: any): Array<GuestError> {
  return guests.map((curr: GuestFormValues, index: number) => {
    const labels: Array<string> = [];
    if (!curr.title) {
      labels.push(t('modal-error--field-missing-label', { field: 'Title' }));
    }
    if (!curr.firstNames) {
      labels.push(t('modal-error--field-missing-label', { field: 'First name' }));
    }
    if (!curr.lastName) {
      labels.push(t('modal-error--field-missing-label', { field: 'Last name' }));
    }

    if (!curr.dob.dayOfBirth) {
      labels.push(t('modal-error--field-missing-label', { field: 'Birth day' }));
    } else if (
      isNaN(parseInt(curr.dob.dayOfBirth)) ||
      parseInt(curr.dob.dayOfBirth) < 1 ||
      parseInt(curr.dob.dayOfBirth) > 31
    ) {
      labels.push(t('modal-error--field-error-label', { field: 'Birth day' }));
    }

    if (!curr.dob.monthOfBirth) {
      labels.push(t('modal-error--field-missing-label', { field: 'Birth month' }));
    } else if (
      isNaN(parseInt(curr.dob.monthOfBirth)) ||
      parseInt(curr.dob.monthOfBirth) < 1 ||
      parseInt(curr.dob.monthOfBirth) > 12
    ) {
      labels.push(t('modal-error--field-error-label', { field: 'Birth month' }));
    }

    if (!curr.dob.yearOfBirth) {
      labels.push(t('modal-error--field-missing-label', { field: 'Birth year' }));
    } else if (
      isNaN(parseInt(curr.dob.yearOfBirth)) ||
      parseInt(curr.dob.yearOfBirth) < 1900 ||
      parseInt(curr.dob.yearOfBirth) > new Date().getFullYear()
    ) {
      labels.push(t('modal-error--field-error-label', { field: 'Birth year' }));
    }

    if (curr.lead && !isAgent) {
      if (!curr.email) {
        labels.push(t('guests__modal--field-missing-label', { field: 'Email' }));
      } else if (!Validation.isEmailValid(curr.email)) {
        labels.push(t('guests__modal--field-error-label', { field: 'Email' }));
      }

      if (!curr.telephone) {
        labels.push(t('guests__modal--field-missing-label', { field: 'Telephone' }));
      } else if (!Validation.isTelephoneValid(curr.telephone)) {
        labels.push(t('guests__modal--field-error-label', { field: 'Telephone' }));
      }
    }

    const name = curr.firstNames
      ? `${curr.firstNames} ${curr.lastName}`
      : index === 0
        ? `Guest ${index + 1} (${t('guests-page__label--lead-guest')})`
        : `Guest ${index + 1}`;
    return {
      name,
      errors: labels
    };
  });
}

export const GuestPageFormError = ({ isAgent, onClick, guests }: GuestPageFormErrorProps) => {
  const t: any = useI18NextContext();
  const errors: Array<GuestError> = getGuestErrors(guests, isAgent, t);
  const renderErrors = () =>
    errors
      .filter((error: GuestError) => !!error.errors.length)
      .map((error: GuestError) => (
        <div key={error.name}>
          <GuestTitle>{error.name}</GuestTitle>
          <ul>
            {error.errors.map((message: string, index: number) => (
              <ListItem key={index}>{message}</ListItem>
            ))}
          </ul>
        </div>
      ));
  return (
    <FormError
      onClick={onClick}
      title={'guests__modal--title-label'}
      body={'guests__modal--body-label'}
      renderErrors={renderErrors}
      buttonLabel={'guests__modal--button-label'}
    />
  );
};
