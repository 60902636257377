import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { ErrorLabel, FieldLabel, FieldSubLabel, TextInput } from '@styles/forms';
import { Localisation } from '@model/common';
import { getTranslation, isCharacterValid } from '@util/common';
import { Collapse } from '@mui/material';
import { AllowedFormCharacters } from '@model/forms/validation';
import { TEXT_FIELD_MAX_LENGTH } from '@model/forms';

/* ***************** *
 *       Types       *
 * ***************** */
export interface FormikTextFieldProps {
  theme?: Theme;
  testId?: string;
  error: string;
  label?: string | Localisation;
  subLabel?: string;
  name: string;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
  value: string;
  type?: string;
  css?: any;
  className?: string;
  maxLength?: number;
  placeholder?: string;
  renderIcon?: any;
  onlyAllow?: Array<AllowedFormCharacters | string>;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const Container: any = styled.div(({ css }: any) => ({
  ['input::-webkit-outer-spin-button, input::-webkit-inner-spin-button']: {
    WebkitAppearance: 'none',
    margin: 0
  },

  ['input[type=number]']: {
    MozAppearance: 'textfield'
  },

  ...css
}));

const IconWrapper: any = styled.span(({ theme }: ThemeProps) => ({
  marginLeft: theme.custom.spacing.xSmall
}));

/* ****************************** *
 *    FormikTextFieldComponent    *
 * ****************************** */

export const FormikTextFieldComponent = (props: FormikTextFieldProps) => {
  const {
    error,
    label,
    name,
    onChange,
    onBlur,
    value,
    type,
    css,
    subLabel,
    maxLength,
    placeholder,
    className,
    renderIcon,
    onlyAllow
  } = props;
  const t: any = useI18NextContext();

  const handleChange = (event: any) => {
    if (isCharacterValid(onlyAllow!, event.target.value?.split('').pop()) || !event.nativeEvent.data) {
      onChange(event);
    }
  };

  const getAdditionalTextFieldAttributes = () => {
    switch (type) {
      case 'number':
        return {
          inputmode: 'numeric',
          pattern: '[0-9]*'
        };
      case 'password': {
        return {
          inputmode: 'password',
          type: 'password'
        };
      }
    }
  };
  const id: string = `text-field-${name}`;
  return (
    <Container data-testid={props.testId || TestId.formikTextField.main} css={css} className={className}>
      <Collapse in={!!label}>
        <FieldLabel isError={!!error} htmlFor={id}>
          {getTranslation(t, label)}
          {renderIcon && <IconWrapper>{renderIcon()}</IconWrapper>}
          {subLabel && <FieldSubLabel>{getTranslation(t, subLabel)}</FieldSubLabel>}
        </FieldLabel>
      </Collapse>
      <TextInput
        id={id}
        data-testid={TestId.formikTextField.input}
        type={'text'}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        value={value}
        isError={!!error}
        maxLength={maxLength || TEXT_FIELD_MAX_LENGTH}
        placeholder={t(placeholder)}
        {...getAdditionalTextFieldAttributes()}
      />
      <Collapse in={!!error}>
        <ErrorLabel>{t(error)}</ErrorLabel>
      </Collapse>
    </Container>
  );
};

export const FormikTextField = withTheme(FormikTextFieldComponent);
