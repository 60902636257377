import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Agent } from '@model/agents/agents-models';
import { mq } from '@styles/breakpoints';
import { ZIndex } from '@styles/z-index';
import { useI18NextContext } from '@components/hooks';
import { AgentTools } from '@components/agents/nav/agent-tools/AgentTools';
import { Icon } from '@components/common/generic-icon';
import { useTheme } from '@emotion/react';
import { Theme, ThemeProps } from '@theme/base';
import { TestId } from '@components/test-ids';
import Link from 'next/link';
import { Page } from '@model/common';
import { AgentPage, agentPageLabels } from '@model/common/pages/agent-pages';

const Container = styled.div(({ theme }: ThemeProps) => ({
  display: 'none',
  [mq.medium]: {
    position: 'relative',
    display: 'block',
    backgroundColor: theme.custom.colors.group10.lighter,
    height: 50,
    zIndex: ZIndex.AGENT_NAV
  }
}));

const Nav = styled.div(({ theme }: ThemeProps) => ({
  maxWidth: theme.custom.contentSizes.maxContentWidth,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%',
  margin: '0 auto',
  padding: theme.custom.spacing.large
}));

const UserContainer = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 500
}));

const List = styled.ul(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 500,
  listStyleType: 'none',
  display: 'block',
  [MenuItem]: {
    color: theme.custom.colors.group4.base,
    transition: `color ${theme.custom.transitions.default}`,
    marginRight: theme.custom.spacing.large,
    marginLeft: theme.custom.spacing.large,
    float: 'left',
    display: 'block',
    ['&:last-of-type']: {
      marginRight: 0
    }
  }
}));

const MenuItem: any = styled.li(({ theme }: ThemeProps) => ({
  span: {
    cursor: 'pointer',
    color: theme.custom.colors.group4.base,
    transition: `color ${theme.custom.transitions.default}`,
    ':hover': {
      color: theme.custom.colors.group10.darker
    },
    a: {
      color: theme.custom.colors.group4.base,
      textDecoration: 'none',
      ':hover': {
        color: theme.custom.colors.group10.darker
      }
    }
  }
}));

const NavItemIcon = styled(Icon)({
  marginTop: 3
});

const navIconStyles: (theme: Theme) => any = (theme: Theme) => ({
  marginTop: 1,
  marginLeft: theme.custom.spacing.xSmall,
  color: theme.custom.colors.group4.base,
  transition: `color ${theme.custom.transitions.default}`,
  fontSize: '1rem'
});

export interface AgentsNavProps {
  agent: Agent;
  onLogout: () => void;
}

export const AgentsNav = ({ agent: { abta }, onLogout }: AgentsNavProps) => {
  const t: any = useI18NextContext();
  const theme: Theme = useTheme();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Container data-testid={TestId.agents.nav}>
      <Nav>
        <UserContainer>{t('agents__navigation--logged-in-as', { abta })}</UserContainer>
        <List>
          <MenuItem onMouseEnter={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}>
            <span>{t('agents__agent-tools--title')}</span>
            <NavItemIcon name={'mhi-chevron-down'} css={navIconStyles(theme)} />
            <AgentTools showMenu={showMenu} />
          </MenuItem>
          <MenuItem>
            <span>
              <Link href={Page.AGENTS.concat(AgentPage.BOOKINGS)}>{t(agentPageLabels[AgentPage.BOOKINGS])}</Link>
            </span>
          </MenuItem>
          <MenuItem onClick={onLogout}>
            <span>{t('common__label--logout')}</span>
          </MenuItem>
        </List>
      </Nav>
    </Container>
  );
};
