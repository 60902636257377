import { Icon } from '@components/common/generic-icon';
import { TestId } from '@components/test-ids';
import { DATE_PICKER_FORMAT, ISO_8601_DATE_FORMAT, TimeUnit } from '@model/common/dates';
import { dateRangeMomentToString } from '@model/search';
import { DateRange, DateRangeMoment } from '@model/common';
import { isMobile } from '@styles/breakpoints';
import { withTheme } from '@emotion/react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { Theme } from '@theme/base';
import { CalendarInfo } from '../calendar-info/CalendarInfo';
import { Container, iconStyles } from './datePickerStyles';
import { useWindowSize } from 'react-use';

const PLACE_HOLDER: string = '_____________';
const DATE_PICKER_CONTAINER: string = 'DateRangePicker_picker';
const DATE_FIELD_CLASSNAME: string = 'date-field';
const COVOID_EMBARGO: string = '2020-09-01';

const getNights = (startDate: any, endDate: any) => {
  if (!startDate || !endDate) return 0;
  return endDate.diff(startDate, TimeUnit.DAYS);
};

export enum FocusedInput {
  START_DATE = 'startDate',
  END_DATE = 'endDate'
}

export interface DatePickerProps {
  dates: DateRange;
  onChange: (payload: DateRange) => void;
  theme: Theme;
  focusedInput: FocusedInput | null;
  onFocusChange: (value: any) => void;
  onBlur: (event: any) => void;
  numberOfMonths?: number;
}

export const ArrowIcon = (props: any) => <span {...props} />;

export const DatePickerComponent = (props: DatePickerProps) => {
  const {
    dates: { startDate, endDate },
    onChange,
    focusedInput,
    onFocusChange,
    onBlur,
    numberOfMonths
  } = props;
  const startDateMoment: any = startDate ? moment(startDate, ISO_8601_DATE_FORMAT) : null;
  const endDateMoment: any = endDate ? moment(endDate, ISO_8601_DATE_FORMAT) : null;
  const [nights, setNights] = useState(getNights(startDateMoment, endDateMoment));
  const [mobile, setMobile] = useState<boolean>();
  const [position, setPosition] = useState(0);
  const { width } = useWindowSize();
  const ref: any = useRef();
  useEffect(() => {
    const mobile = isMobile();
    setMobile(mobile);
  }, [width]);
  const onClearDates = () => {
    onChange({ startDate: '', endDate: '' });
    setNights(0);
    onFocusChange(FocusedInput.START_DATE);
  };
  const handleOnDatesChange: any = (dates: DateRangeMoment) => {
    const dateRange: DateRange = dateRangeMomentToString(dates);
    if (dates.startDate && dates.endDate) {
      setNights(getNights(dates.startDate, dates.endDate));
      onFocusChange(null);
    }
    onChange(dateRange);
  };
  const handleOnFocusChange = (input: any) => {
    if (input) {
      props.onFocusChange(input);
    }
  };
  const renderCalendarInfo = () => {
    return <CalendarInfo nights={nights} canClearDates={!!startDate || !!endDate} onClearDates={onClearDates} />;
  };
  const handleOnBlur = (event: any) => {
    if (event.relatedTarget) {
      onBlur(event);
    }
  };
  const handleOnMouseDown = (event: any) => {
    const container = document.getElementsByClassName(DATE_PICKER_CONTAINER);
    if (container.length && container[0].contains(event.target)) {
      event.stopPropagation();
    }
  };
  const isOutSideRange = (day: any) => {
    const isSameOrBeforeTodayPlus3 = day.isSameOrBefore(moment().add(3, TimeUnit.DAYS));
    const isBeforeCOVOIDEmbargo = day.isBefore(moment(COVOID_EMBARGO, ISO_8601_DATE_FORMAT));
    return isSameOrBeforeTodayPlus3 || isBeforeCOVOIDEmbargo;
  };
  const onMouseOver = (a: any) => {
    if (!!startDate && !endDate) {
      const element = a.target.querySelector(`.${DATE_FIELD_CLASSNAME}`);
      if (element) {
        const endDate: any = moment(element.id);
        const nights: number = getNights(startDateMoment, endDate);
        if (nights >= 3) {
          setNights(nights);
        } else {
          setNights(0);
        }
      }
    }
    return;
  };
  const initialVisibleMonth: any = () => {
    if (startDate && endDate) {
      return startDateMoment;
    }
    const today = moment();
    const embargo = moment(COVOID_EMBARGO, ISO_8601_DATE_FORMAT);
    return today.isBefore(embargo) ? embargo : today;
  };
  return (
    <Container
      data-testid={TestId.dates.search}
      lastDateSelected={!!endDate}
      onMouseDown={handleOnMouseDown}
      onBlur={handleOnBlur}
      onMouseOver={onMouseOver}
      ref={ref}
    >
      <DateRangePicker
        startDateId={FocusedInput.START_DATE}
        endDateId={FocusedInput.END_DATE}
        onDatesChange={handleOnDatesChange}
        startDate={startDateMoment || null}
        endDate={endDateMoment || null}
        focusedInput={focusedInput as FocusedInput}
        onFocusChange={handleOnFocusChange}
        readOnly={true}
        startDatePlaceholderText={PLACE_HOLDER}
        endDatePlaceholderText={PLACE_HOLDER}
        displayFormat={DATE_PICKER_FORMAT}
        firstDayOfWeek={1}
        daySize={mobile ? 34 : 40}
        minimumNights={3}
        hideKeyboardShortcutsPanel={true}
        navNext={<Icon name={'mhi-chevron-right'} css={iconStyles(props.theme)} />}
        navPrev={<Icon name={'mhi-chevron-left'} css={iconStyles(props.theme)} />}
        onNextMonthClick={() => setPosition(position + 1)}
        onPrevMonthClick={() => setPosition(position - 1)}
        isOutsideRange={isOutSideRange}
        numberOfMonths={numberOfMonths || mobile ? 1 : 2}
        keepOpenOnDateSelect={false}
        renderCalendarInfo={renderCalendarInfo}
        noBorder={true}
        transitionDuration={0}
        initialVisibleMonth={initialVisibleMonth}
        renderDayContents={(date: any) => (
          <span id={date.toISOString()} className={DATE_FIELD_CLASSNAME}>
            {date.date()}
          </span>
        )}
        customArrowIcon={<ArrowIcon />}
      />
    </Container>
  );
};

export const DatePicker = withTheme(DatePickerComponent);
