import { Icon } from '@components/common/generic-icon';
import { SearchBarContext } from '../searchBarContext';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { mq } from '@styles/breakpoints';
import classNames from 'classnames';
import { withTheme } from '@emotion/react';
import React, { useContext } from 'react';
import { Theme } from '@theme/base';
import { Container, iconStyles, InputContainer, SEARCH_HOVER } from './styles';
import { SearchContainerTitle } from './title/SearchContainerTitle';
import { ErrorLabel } from '@components/search/tabs/common/styles/Styles';
import { useI18NextContext } from '@components/hooks';

export interface SearchInputWrapperProps {
  icon: string;
  active: boolean;
  children: any;
  onClickAway: any;
  theme?: Theme;
  chevron?: boolean;
  containerRef?: any;
  onMouseDown?: any;
  onFocus?: any;
  tabIndex?: any;
  title?: any;
  error?: string;
}

const chevronStyles: any = (theme: Theme) => ({
  color: theme.custom.colors.group4.light,
  position: 'absolute',
  right: 0,
  marginRight: 20,
  fontSize: '1rem',
  [mq.ie()]: {
    top: '50%',
    marginTop: '-0.5rem'
  }
});

const getChevron = (active: boolean) => (active ? 'mhi-caret-up' : 'mhi-caret-down');

const SearchInputWrapperComponent = ({
  icon,
  theme,
  chevron,
  active,
  containerRef,
  onClickAway,
  onMouseDown,
  onFocus,
  tabIndex,
  title,
  error,
  children
}: SearchInputWrapperProps) => {
  const { condensed } = useContext(SearchBarContext) || { condensed: true };
  const className: string = classNames({ active, error }, 'search-input-wrapper');
  const t: any = useI18NextContext();
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div>
        <Container
          className={className}
          ref={containerRef}
          onMouseDown={onMouseDown}
          onFocus={onFocus}
          tabIndex={tabIndex}
          condensed={condensed}
        >
          <Icon name={icon} css={iconStyles(theme)} className={SEARCH_HOVER} />
          <InputContainer>
            {!condensed && <SearchContainerTitle title={title} />}
            {children}
          </InputContainer>
          {chevron && <Icon name={getChevron(active)} css={chevronStyles(theme)} className={SEARCH_HOVER} />}
        </Container>
        {error && <ErrorLabel condensed={condensed}>{t(error)}</ErrorLabel>}
      </div>
    </ClickAwayListener>
  );
};

export const SearchInputWrapper = withTheme(SearchInputWrapperComponent);
