import { ValidationErrorModal } from './ValidationErrorModal';
import { useSelector, useDispatch } from 'react-redux';
import { getModalData } from '@state/modal/modalSelectors';
import { closeModal } from '@state/modal/modalOperations';

export const ValidationErrorModalContainer = () => {
  const dispatch = useDispatch();
  const onDismiss = () => {
    dispatch(closeModal());
  };
  const modalErrors = useSelector(getModalData);
  return <ValidationErrorModal modalErrors={modalErrors} onDismiss={onDismiss} />;
};
