import { GeographyResult } from '@model/search';
import React, { useState } from 'react';
import { AlgoliaIndex, AlgoliaSearch } from '@components/common/algolia-search';
import { AutoComplete, GroupedDestination } from './auto-complete/AutoComplete';
import {
  DestinationsListInputContainer,
  DestinationsListButton,
  DestinationsListButtonLabel
} from '../../common/styles/Styles';
import { SearchContainerTitle } from '@components/search/tabs/common/search-container/title/SearchContainerTitle';
import { Name } from '@model/iceberg';
import { SearchIcon } from '@components/search/tabs/common/icon/SearchIcon';
import { SearchContainer } from '@components/search/tabs/common/container/SearchContainer';
import { Icons } from '@model/common/icons';
import { useI18NextContext } from '@components/hooks';
import { Icon } from '@components/common/generic-icon';
import {
  DestinationsListDropdown,
  SearchableDestinationItem
} from '../../common/destinations-list/DestinationsListDropdown';
import { TestId } from '@components/test-ids';

/* ***************** *
 *       Types       *
 * ***************** */

export interface DestinationsProps {
  condensed: boolean;
  active: boolean;
  filters: string;
  destination: Name;
  searchRef: any;
  onChange: (value: GeographyResult) => void;
  onClear: () => void;
  onFocus: () => void;
  onBlur: () => void;
  searchableDestinations: Array<GeographyResult>;
  airports: Array<string>;
  error?: boolean;
}

export function getErrorMessage(error: boolean = false) {
  if (error) return 'search-form__destinations--error';
  return '';
}

export const Destinations = ({
  condensed,
  filters,
  destination,
  active,
  searchRef,
  onChange,
  onClear,
  onBlur,
  onFocus,
  searchableDestinations,
  airports,
  error
}: DestinationsProps) => {
  const t: any = useI18NextContext();

  /* *** STATE *** */
  const [searchableDestination, setSearchableDestination] = useState<string>('');
  const [showSearchableDestinations, setShowSearchableDestinations] = useState<boolean>(false);

  /* *** LOGIC  *** */
  const handleSearchableDestinations = (destination: GeographyResult, isDisabled: boolean) => {
    if (isDisabled) return null;
    onFocus();
    setShowSearchableDestinations(false);
    onChange(destination);
  };

  const handleOptionsChange = (options: Array<GroupedDestination>) => {
    if (options[0]?.options[0]?.name?.display === searchableDestination) {
      setTimeout(() => onChange(options[0].options[0]), 100); // the timeout fixes the IE11
      searchRef.current.blur();
    }
  };

  const handleOnBlur = () => {
    setShowSearchableDestinations(false);
    setSearchableDestination('');
    onBlur();
  };

  const handleOnInputChange: (value: string, refine: any) => void = (value: string, refine: any) => {
    setShowSearchableDestinations(false);
    refine(value);
  };

  const handleShowSearchableDestinations = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setShowSearchableDestinations(!showSearchableDestinations);
  };

  const handleRenderDestination = (destination: any) => {
    const hasAirports = !!airports.length;
    const isDisabled = hasAirports && !airports.some((airport: string) => destination._tags.includes(airport));
    return (
      <SearchableDestinationItem
        data-testid={TestId.searchDestinationsListDropdown.item}
        key={destination.name.display}
        onClick={() => handleSearchableDestinations(destination, isDisabled)}
        isDisabled={isDisabled}
      >
        {destination.name.display}
      </SearchableDestinationItem>
    );
  };

  return (
    <>
      <SearchContainer onClick={onFocus} active={active} condensed={condensed} error={getErrorMessage(error)}>
        <SearchIcon name={Icons.SEARCH} />
        <DestinationsListInputContainer>
          {!condensed && <SearchContainerTitle title={'search__destination--title'} />}
          <AlgoliaSearch index={AlgoliaIndex.GEOGRAPHY} filters={filters}>
            <AutoComplete
              value={destination}
              onChange={onChange}
              onClear={onClear}
              onBlur={handleOnBlur}
              onFocus={onFocus}
              active={active}
              searchRef={searchRef}
              searchableDestination={searchableDestination}
              onInputChange={handleOnInputChange}
              onOptionsChange={handleOptionsChange}
            />
          </AlgoliaSearch>
        </DestinationsListInputContainer>
        <DestinationsListButton
          onClick={handleShowSearchableDestinations}
          isActive={showSearchableDestinations}
          condensed={condensed}
        >
          <Icon name={Icons.MAP_PIN_OUTLINE} />
          <DestinationsListButtonLabel>{t('common__label--list')}</DestinationsListButtonLabel>
        </DestinationsListButton>
      </SearchContainer>
      <DestinationsListDropdown
        searchableDestination={searchableDestination}
        onClickAway={handleShowSearchableDestinations as any}
        show={showSearchableDestinations}
        condensed={condensed}
        destinations={searchableDestinations.sort((a: GeographyResult, b: GeographyResult) =>
          a.name.display.localeCompare(b.name.display)
        )}
        renderDestination={handleRenderDestination}
      />
    </>
  );
};
