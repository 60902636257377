import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, ModalType } from '@state/modal/modalOperations';
import { APISFormError } from './APISFormError';
import { BaseModal } from '@components/common/modal';
import { getModalType, getModalData } from '@state/modal/modalSelectors';
import { APISFormValues } from '@model/forms/apis-form';
import { MyBookingGuest } from '@model/mmb';

export const APISFormErrorContainer = () => {
  const dispatch = useDispatch();
  const modalType: ModalType = useSelector(getModalType);
  const { values, guests }: { values: APISFormValues; guests: Array<MyBookingGuest> } = useSelector(getModalData);
  const handleOnDismiss = () => dispatch(closeModal());

  return (
    <BaseModal open={modalType === ModalType.APIS_FORM_ERROR} onDismiss={handleOnDismiss} hideCloseButton>
      <APISFormError formValues={values} guests={guests} onClick={handleOnDismiss} />
    </BaseModal>
  );
};
