import { BLANK_WINDOW } from '@util/blank-window';
import React from 'react';
import styled from '@emotion/styled';
import { mixins } from '@styles/mixins';
import { ThemeProps } from '@theme/base';

export const Container = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.default as any),
  marginTop: theme.custom.spacing.xSmall,
  color: theme.custom.colors.group4.base
}));

export const Link = styled.a(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.default as any),
  ...mixins(theme).link
}));

export interface GoogleNoticeProps {
  className?: string;
}

export const GoogleNotice = ({ className }: GoogleNoticeProps) => (
  <Container className={className}>
    This site is protected by reCAPTCHA and the Google{' '}
    <Link href="https://policies.google.com/privacy" target={BLANK_WINDOW} rel="noopener">
      Privacy Policy{' '}
    </Link>
    and{' '}
    <Link href="https://policies.google.com/terms" target={BLANK_WINDOW} rel="noopener">
      Terms of Service{' '}
    </Link>
    apply.
  </Container>
);
