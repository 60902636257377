import { OnInit } from '@model/bootstrap/on-init';
import axios from 'axios';
import Cookies, { Cookie } from '@model/common/cookie/cookie';

export class WithAuth implements OnInit {
  initialize(): void {
    axios.interceptors.request.use(
      function (config) {
        const authorization: string | undefined = new Cookies().get(Cookie.TRADE_AUTHORIZATION);

        if (authorization) {
          config.headers.Authorization = `Bearer ${authorization}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }
}
