import { withTheme } from '@emotion/react';
import React from 'react';
import Tooltip from 'react-tooltip-lite';
import { Colors, Theme } from '@theme/base';
import { TestId } from '@components/test-ids';
import { ZIndex } from '@styles/z-index';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';

export interface ToolTipProps {
  content: string | JSX.Element;
  direction?: string;
  children: JSX.Element;
  theme: Theme;
  distance?: number;
  onToggle?: () => void;
  zIndex?: number;
  tipContentHover?: boolean;
  isOpen?: boolean;
}

const StyledToolTip = styled(Tooltip)(({ theme }: ThemeProps) => ({
  ['.react-tooltip-lite']: {
    borderRadius: 2,
    backgroundColor: theme.custom.colors.black
  },
  ['.react-tooltip-lite-arrow']: {
    color: theme.custom.colors.black
  }
}));

export const ToolTipComponent = (props: ToolTipProps) => {
  const { theme, onToggle, zIndex, content } = props;
  const colors: Colors = theme.custom.colors;
  if (!content) return props.children;
  return (
    <StyledToolTip
      data-testid={TestId.tooltip}
      color={colors.white}
      distance={2}
      zIndex={zIndex!}
      onToggle={onToggle!}
      {...props}
    />
  );
};

ToolTipComponent.defaultProps = {
  onToggle: () => {},
  zIndex: ZIndex.TOOLTIP
};

export const ToolTip = withTheme(ToolTipComponent);
