import { Icon } from '../../generic-icon';
import { OpeningTimesContainer } from '../../opening-times/OpeningTimesContainer';
import React, { useState } from 'react';
import Markdown from 'react-markdown';
import styled from '@emotion/styled';
import { Theme, ThemeProps } from '@theme/base';
import { NavLabel } from '../NavLabel';
import { DesktopNavItem } from '../DesktopNavItem';
import { withTheme } from '@emotion/react';
import { LinkComponent } from '@components/common/description/TextMarkdown';

export interface PhoneNumberProps {
  telephoneNumber: string;
  theme?: Theme;
}

const phoneNumberIconStyles: (theme: Theme) => any = (theme: Theme) => ({
  color: theme.custom.colors.white,
  fontSize: '1.5rem',
  paddingRight: theme.custom.spacing.xSmall
});

const Container: any = styled(DesktopNavItem)(({ theme }: ThemeProps) => ({
  color: theme.custom.colors.white,
  ['> span']: {
    ...(theme.custom.typography.h5 as any),
    paddingLeft: theme.custom.spacing.xLarge,
    paddingTop: 10
  },
  ['&:hover i:first-of-type, &:hover > span:first-of-type']: {
    color: `${theme.custom.colors.group9.base} !important`
  }
}));

const PhoneNumberLabel = styled(NavLabel)({
  position: 'relative'
});

const Number = styled.div(({ theme }: ThemeProps) => ({
  display: 'inline-block',
  span: {
    padding: 0
  },
  ['p']: {
    ...(theme.custom.typography.h5 as any),
    margin: 0
  }
}));

export const PhoneNumberComponent = ({ theme, telephoneNumber }: PhoneNumberProps) => {
  const [showOpeningTimes, setShowOpeningTimes] = useState<boolean>(false);

  return (
    <Container onMouseEnter={() => setShowOpeningTimes(true)} onMouseLeave={() => setShowOpeningTimes(false)}>
      <PhoneNumberLabel isClickable>
        <Icon name={'mhi-telephone'} css={phoneNumberIconStyles(theme!)} />
        <Number>
          <Markdown children={telephoneNumber} components={LinkComponent} />
        </Number>
        <OpeningTimesContainer show={showOpeningTimes} />
      </PhoneNumberLabel>
    </Container>
  );
};

export const PhoneNumber = withTheme(PhoneNumberComponent);
