import styled from '@emotion/styled';
import React from 'react';
import { TestId } from '@components/test-ids';
import { countrySpriteMapping } from '@util/country-flags';

/* ***************** *
 *       Types       *
 * ***************** */
export interface CountryFlagProps {
  testId?: string;
  countryCode: string;
  className?: string;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const FlagIcon: any = styled.div(({ countryCode }: { countryCode: string }) => {
  const spritePosition = countrySpriteMapping[countryCode];

  return {
    width: 16,
    height: 16,
    backgroundImage: spritePosition
      ? 'url(/assets/images/sprites/flag-icons-sprite.png)'
      : 'url(/assets/images/generic_flag.png)',
    backgroundPosition: `0 ${spritePosition || 0}px`
  };
});

/* ***************** *
 *    CountryFlag    *
 * ***************** */

export const CountryFlag = ({ testId, countryCode, className }: CountryFlagProps) => (
  <FlagIcon className={className} countryCode={countryCode} data-testid={testId || TestId.countryFlag.main} />
);
