import * as ReactImageGallery from 'react-image-gallery';
import React from 'react';
import { Theme } from '@theme/base';
import { GalleryImage } from '@model/common/gallery-image';

export interface ImageGalleryProps {
  theme?: Theme;
  testId?: string;
  galleryImages: Array<GalleryImage> | [];
  showGalleryDots?: boolean;
}

export const ImageGallery = ({ galleryImages, showGalleryDots }: ImageGalleryProps) => {
  return (
    <ReactImageGallery.default
      items={galleryImages}
      showFullscreenButton={false}
      showThumbnails={false}
      showBullets={showGalleryDots}
      showPlayButton={false}
    />
  );
};

ImageGallery.defaultProps = {
  showGalleryDots: false
};
