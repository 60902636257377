import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModalData } from '@state/modal/modalSelectors';
import { closeModal } from '@state/modal/modalOperations';
import { ErrorModal } from '@components/common/modal/alert/error/ErrorModal';
import { useI18NextContext } from '@components/hooks';

export const GenericErrorModalContainer = () => {
  const t: any = useI18NextContext();
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const handleOnClick = () => dispatch(closeModal());

  const renderBody = () => {
    return <span>{t('error-modal__generic--text')}</span>;
  };
  return (
    <ErrorModal
      onClick={handleOnClick}
      iconName="mhi-error"
      titleKey="common__label--something-wrong"
      renderBody={renderBody}
      buttonLabelKey="common__label--close"
      code={modalData?.code}
    />
  );
};
