import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';
import { boxShadowMixins } from '@styles/mixins';
import { ZIndex } from '@styles/z-index';
import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import { SelectedOptions, SetOptions } from './NavBar';
import { useToggleShow } from '@components/hooks';
import { ContentTypes } from '@model/navigation';
import { DesktopNavigationContentContainer } from './DesktopNavigationContentContainer';

/* ***************** *
 *       Types       *
 * ***************** */
export interface DesktopNavigationProps {
  theme?: Theme;
  testId?: string;
  show: boolean;
  navContent: ContentTypes;
  selectedOptions: SelectedOptions;
  setOptions: SetOptions;
  onToggleDesktopNav?: () => void;
}

interface ContainerProps {
  showNav: boolean;
  children?: any;
}

const TRANSITION_DURATION: number = 300;

/* ***************** *
 *       Styles      *
 * ***************** */
const Container: (props: ContainerProps) => any = styled.div((props: ContainerProps) => ({
  flex: 'none',
  width: '100%',
  transition: `opacity ${TRANSITION_DURATION / 1000}s linear`,
  opacity: props.showNav ? 1 : 0,
  height: 0,
  zIndex: ZIndex.NAVIGATION,
  pointerEvents: props.showNav ? 'all' : 'none'
}));

const ArrowPointerContainer = styled.div(({ theme }: ThemeProps) => ({
  cursor: 'pointer',
  height: 14,
  marginRight: -theme.custom.spacing.large,
  position: 'relative',
  width: `calc(100% + ${theme.custom.spacing.large}px)`
}));

interface NavContentOverlayProps extends ThemeProps {
  showNav: boolean;
}
const NavContentOverlay: any = styled.div(({ theme, showNav }: NavContentOverlayProps) => ({
  boxShadow: boxShadowMixins(theme.custom.colors.group10.base).default,
  border: `1px solid ${theme.custom.colors.group10.light}`,
  position: 'absolute',
  textAlign: 'center',
  left: 0,
  right: 0,
  top: 81,
  transition: `transform ${TRANSITION_DURATION / 1000}s ease-in-out`,
  minHeight: 500,
  backgroundColor: theme.custom.colors.white,

  // due to small rendering differences between brawsers
  // IE11 and FireFox require slightly different value
  [mq.ie()]: {
    transition: `opacity ${TRANSITION_DURATION / 1000}s linear, transform ${TRANSITION_DURATION / 1000}s ease-in-out`,
    opacity: showNav ? 1 : 0,
    zIndex: ZIndex.NAVIGATION,
    top: 81
  },

  [mq.safari]: {
    top: 82
  },

  [mq.moz]: {
    top: 81.5
  }
}));

const NavContentContainer = styled.div(({ theme }: ThemeProps) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'left',
  padding: theme.custom.spacing.medium,
  paddingTop: 0,
  paddingBottom: 0,
  height: '100%',
  color: theme.custom.colors.group4.base,
  maxWidth: theme.custom.contentSizes.maxContentWidth
}));

const ArrowBackground = styled.div(({ theme }: ThemeProps) => ({
  position: 'absolute',
  borderBottom: `14px solid ${theme.custom.colors.group10.light}`,
  borderLeft: '14px solid transparent',
  borderRight: '14px solid transparent',
  height: 0,
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  width: 0,
  zIndex: ZIndex.NAVIGATION
}));

const ArrowBorder = styled.div(({ theme }: ThemeProps) => ({
  position: 'absolute',
  borderBottom: `14px solid ${theme.custom.colors.white}`,
  borderLeft: '14px solid transparent',
  borderRight: '14px solid transparent',
  height: 0,
  top: 1,
  left: '50%',
  transform: 'translateX(-50%)',
  width: 0,
  zIndex: ZIndex.NAVIGATION
}));

/* *********************** *
 *    DesktopNavigation    *
 * *********************** */
export const DesktopNavigationComponent = (props: DesktopNavigationProps) => {
  const { navContent, selectedOptions, setOptions, show, onToggleDesktopNav } = props;

  const { shouldShow } = useToggleShow(show);

  /* *** RENDERERS *** */
  return (
    <Container showNav={shouldShow} data-testid={props.testId || TestId.desktopNavigation.main}>
      <>
        <ArrowPointerContainer data-testid={TestId.desktopNavigation.navItemArrow}>
          <ArrowBackground />
          <ArrowBorder />
        </ArrowPointerContainer>
        <NavContentOverlay showNav={shouldShow} data-testid={TestId.desktopNavigation.navOverlay}>
          <NavContentContainer data-testid={TestId.desktopNavigation.navContentContainer}>
            <DesktopNavigationContentContainer
              selectedOptions={selectedOptions}
              setOptions={setOptions}
              navContent={navContent}
              onToggleDesktopNav={onToggleDesktopNav}
            />
          </NavContentContainer>
        </NavContentOverlay>
      </>
    </Container>
  );
};

export const DesktopNavigation = withTheme(DesktopNavigationComponent);
