import { EndPoint, getURL, Request } from '@model/service';
import uri from 'urijs';

export interface CustomResponse<T> {
  data: T;
}

interface CustomApiRequests {
  isLiveIVector: () => Promise<boolean>;
}

export class CustomApi implements CustomApiRequests {
  private api: Request = new Request();

  public async isLiveIVector(): Promise<boolean> {
    const response = await this.api.get(uri(getURL()).path(EndPoint.UP).href());
    const {
      connect: { endpoint }
    } = response;
    return /sunspot.ivectorconnect.co.uk/.test(endpoint);
  }
}
