import { useI18NextContext } from '@components/hooks';
import React from 'react';
import { Title } from '../styles';

export interface SearchContainerTitleProps {
  title: any;
}

const getTitle = (title: any, t: any) =>
  typeof title === 'function' ? <Title>{title()}</Title> : <Title>{t(title)}</Title>;

export const SearchContainerTitle = (props: SearchContainerTitleProps) => {
  const { title } = props;
  const t: any = useI18NextContext();
  return getTitle(title, t);
};
