import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeViewRoomsModal, ModalType } from '@state/modal/modalOperations';
import { getModalType, getModalData } from '@state/modal/modalSelectors';
import { BaseModal } from '@components/common/modal';
import { ViewRoomTypesModal } from './ViewRoomTypesModal';

export const ViewRoomTypesModalContainer = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(getModalType);
  const { onNavigateToFlights, onNavigateToRooms, hotelOnly } = useSelector(getModalData);

  const handleOnDismiss = () => {
    dispatch(closeViewRoomsModal());
  };

  const navigateToFlights = () => {
    dispatch(closeViewRoomsModal());
    onNavigateToFlights();
  };
  const navigateToRooms = () => {
    dispatch(closeViewRoomsModal());
    onNavigateToRooms();
  };

  return (
    <BaseModal open={modalType === ModalType.VIEW_ROOM_TYPES_MODAL} onDismiss={handleOnDismiss}>
      <ViewRoomTypesModal
        navigateToFlights={navigateToFlights}
        navigateToRooms={navigateToRooms}
        onDismiss={() => dispatch(closeViewRoomsModal())}
        hotelOnly={hotelOnly}
      />
    </BaseModal>
  );
};
