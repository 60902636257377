import { useSelector } from 'react-redux';
import { ModalType } from '@state/modal/modalOperations';
import React from 'react';
import { BaseModal } from '@components/common/modal';
import { getModalType, getModalData } from '@state/modal/modalSelectors';
import { EarlyFlightsModal } from './EarlyFlightsModal';

export const EarlyFlightsModalContainer = () => {
  const modalType = useSelector(getModalType);
  const modalData = useSelector(getModalData);

  return (
    <BaseModal open={modalType === ModalType.EARLY_FLIGHTS_MODAL} onDismiss={modalData.handleOnDismiss} hideCloseButton>
      <EarlyFlightsModal modalData={modalData} />
    </BaseModal>
  );
};
