import { TripTypes } from '@model/contentful';
import Link from 'next/link';
import { PlainLinkStyles } from '@styles/common';
import { Page } from '@model/common';

export interface TripTypesLinkProps {
  tripTypeId: TripTypes;
  children?: JSX.Element;
}

export const TripTypesLink = ({ tripTypeId, children }: TripTypesLinkProps) => {
  return (
    <Link
      prefetch={false}
      href={`${Page.TRIP_TYPES}/[tripType]`}
      as={`${Page.TRIP_TYPES}/${tripTypeId}`}
      passHref
      style={PlainLinkStyles}
    >
      {children}
    </Link>
  );
};
