import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';
import { ThemeProps } from '@theme/base';

export const DesktopNavItem: any = styled.li(({ theme }: ThemeProps) => ({
  display: 'none',

  [mq.medium]: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 40,
    alignItems: 'center',
    marginRight: theme.custom.spacing.large,

    // hover color needs to be enforced when mouse is over the nav item (not the icon)
    ['&:hover > i:first-of-type, &:hover > span:first-of-type']: {
      color: `${theme.custom.colors.group9.base} !important`
    },
    [':last-child']: {
      marginRight: 0
    }
  }
}));
