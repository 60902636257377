import { TestId } from '@components/test-ids';
import styled from '@emotion/styled';
import MUICheckbox from '@mui/material/Checkbox';
import React, { CSSProperties } from 'react';
import { CheckboxIcon } from '@styles/material-ui/CheckboxIcon';
import { ThemeProps } from '@theme/base';

export interface CheckboxProps {
  label?: string | JSX.Element;
  value: any;
  checked: boolean;
  hover: boolean;
  available: boolean;
  disabled?: boolean;
  onToggle: (value: any, event: any) => void;
  testId?: string;
  checkboxStyles?: any;
  onBlur?: () => void;
  id?: string;
  className?: string;
}

interface FilterCheckboxProps extends ThemeProps {
  styles?: CSSProperties;
}

const FilterCheckbox: any = styled(MUICheckbox)(({ theme, styles }: FilterCheckboxProps) => ({
  padding: theme.custom.spacing.xSmall,
  ['&:hover']: {
    backgroundColor: 'transparent'
  },
  ['.MuiSvgIcon-root']: {
    fontSize: theme.custom.fontSize.large
  },
  ['&.Mui-checked']: {
    color: theme.custom.colors.group5.base,

    ['&:hover']: {
      backgroundColor: 'transparent'
    }
  },
  ...styles
}));

export const Checkbox = (props: CheckboxProps) => {
  const { checked, onToggle, label, hover, testId, value, checkboxStyles, onBlur, id, available, disabled, className } =
    props;
  const { on, off } = TestId.airportSearch.checkbox;
  const dataTestId: string = testId ? testId : checked ? on : off;
  return (
    <FilterCheckbox
      className={className}
      data-testid={dataTestId}
      checked={checked}
      onChange={(event: any) => onToggle(value, event)}
      value={label}
      styles={checkboxStyles}
      onBlur={onBlur}
      checkedIcon={<CheckboxIcon hover={hover} checked={checked} available={available} disabled={!!disabled} />}
      id={id}
      icon={<CheckboxIcon hover={hover} checked={checked} available={available} disabled={!!disabled} />}
    />
  );
};
