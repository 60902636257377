import { useI18NextContext } from '@components/hooks';
import { ButtonVariant, ButtonColor, ButtonSize } from '@components/material-ui';
import {
  ModalContainer,
  Container,
  Heading,
  CloseIcon,
  ModalContent,
  ModalContentText,
  ButtonsContainer,
  CTAButton
} from '@styles/modal';

/* ***************** *
 *       Types       *
 * ***************** */
export interface ViewRoomTypesModalProps {
  navigateToFlights: () => void;
  navigateToRooms: () => void;
  onDismiss: () => void;
  hotelOnly: boolean;
}

export const ViewRoomTypesModal = ({
  onDismiss,
  navigateToFlights,
  navigateToRooms,
  hotelOnly
}: ViewRoomTypesModalProps) => {
  const t: any = useI18NextContext();

  return (
    <ModalContainer>
      <Container>
        <Heading>
          {t('view-room-types-modal--heading')}
          <CloseIcon name={'mhi-clear'} onClick={onDismiss} />
        </Heading>
        <ModalContent>
          <ModalContentText>{t('view-room-types-modal--content')}</ModalContentText>
        </ModalContent>
        <ButtonsContainer>
          <CTAButton
            variant={ButtonVariant.CONTAINED}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.REGULAR}
            label={t(hotelOnly ? 'view-room-types-modal--cta-review' : 'view-room-types-modal--cta-flights')}
            onClick={navigateToFlights}
          />
          <CTAButton
            variant={ButtonVariant.OUTLINED}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.REGULAR}
            label={t('view-room-types-modal--cta-rooms')}
            onClick={navigateToRooms}
          />
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
};
