import { FormSubmitSuccessModal } from '@components/modal/form-submit-status/success/FormSubmitSuccess';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '@state/modal/modalOperations';
import { getModalData } from '@state/modal/modalSelectors';

export const FormSubmitSuccessModalContainer = () => {
  const modalData = useSelector(getModalData);
  const { closeButtonLabel, titleLabel, bodyLabel } = modalData;
  const dispatch = useDispatch();
  const handleOnClick = () => dispatch(closeModal());
  return (
    <FormSubmitSuccessModal
      onClick={handleOnClick}
      closeButtonLabel={closeButtonLabel}
      titleLabel={titleLabel}
      bodyLabel={bodyLabel}
    />
  );
};
