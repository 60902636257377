import { MobileNavigationProps, MobileNavigationComponent } from './MobileNavigation';
import { getAllCMSDestinationsSelector, getCMSContactDetails, getCMSHereToHelp, getCMSOffers } from '@state/cms';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { getIsAgent } from '@state/agent/agentSelectors';
import { performLogoutAgent } from '@state/agent/agentOperations';

export interface MobileNavigationContainerProps {
  testId?: string;
  open: boolean;
  onDismiss: () => void;
}

export const MobileNavigationContainer = (ownProps: MobileNavigationContainerProps) => {
  const dispatch = useDispatch();
  const props: MobileNavigationProps = {
    theme: useTheme(),
    cmsDestinations: useSelector(getAllCMSDestinationsSelector),
    contactDetails: useSelector(getCMSContactDetails),
    infoTiles: useSelector(getCMSHereToHelp).infoTiles,
    offerTypes: useSelector(getCMSOffers).components,
    isAgent: useSelector(getIsAgent),
    onLogout: () => dispatch(performLogoutAgent()),
    ...ownProps
  };

  return <MobileNavigationComponent {...props} />;
};
