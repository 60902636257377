import { GlobalAppState } from '@model/state';
import { createSelector } from 'reselect';
import { DateOfBirth, GuestFormValues } from '@model/forms';
import { GuestType } from '@model/iceberg/booking/basket/guest/guest';
import moment from 'moment';
import { MAX_CHILD_AGE, MAX_INFANT_AGE } from '@model/common/guest-age';
import { getBasket } from '@state/basket';
import { Basket } from '@model/iceberg';
import { Child } from '@model/guests/child';

export const getGuests = (state: GlobalAppState) => state.guests;

export const getHasUnmatchedChildAge = createSelector(
  getGuests,
  getBasket,
  (guests: Array<GuestFormValues>, basket: Basket) => {
    const endDate: string | undefined = basket.hotel?.checkOutDate;
    return guests.some((guest: GuestFormValues) => !getGuestDOBMatchesType(guest, endDate));
  }
);

export const getIsChildAgeValidOnHoliday = createSelector(
  getGuests,
  getBasket,
  (guests: Array<GuestFormValues>, basket: Basket) => {
    const endDate: string | undefined = basket.hotel?.checkOutDate;
    return isChildValidAgeOnHoliday(guests, endDate);
  }
);

export const isChildValidAgeOnHoliday = (guests: Array<GuestFormValues>, endDate?: string) => {
  return guests
    .filter((guest: GuestFormValues) => guest.type === GuestType.CHILD)
    .every((guest: GuestFormValues) => {
      const { dayOfBirth, monthOfBirth, yearOfBirth } = guest.dob;
      const child: Child = new Child(dayOfBirth, monthOfBirth, yearOfBirth);
      return !child.isOverAgeOnDate(endDate);
    });
};

export const getGuestDOBMatchesType = (guest: GuestFormValues, endDate?: string) => {
  const type: GuestType = guest.type;
  const { yearOfBirth, monthOfBirth, dayOfBirth }: DateOfBirth = guest.dob;
  if (!yearOfBirth || yearOfBirth.length < 4 || !monthOfBirth || !dayOfBirth) return false;
  const birthday: moment.Moment = moment(`${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`);
  const years: number = moment(endDate).diff(birthday, 'years');

  switch (type) {
    case GuestType.ADULT:
      return years >= MAX_CHILD_AGE;
    case GuestType.CHILD:
      return years >= MAX_INFANT_AGE && years < MAX_CHILD_AGE;
    case GuestType.INFANT: {
      const days: number = moment().diff(birthday, 'days');
      return years < MAX_INFANT_AGE && days > 0;
    }
    default:
      return false;
  }
};
