import { Name } from '@model/iceberg/geography';
import { GeographyResult } from '@model/search';
import { isEmpty } from 'lodash';
import React, { FC, useEffect } from 'react';
import { connectAutoComplete, connectStateResults } from 'react-instantsearch-core';
import { Typeahead } from '../../../../../common/typeahead/Typeahead';
import { getOptions } from './destination-sorter';
import { Group } from '@components/common/typeahead/components/group/Group';
import { MenuList } from '@components/common/typeahead/components/menu-list/MenuList';
import { NoOptionsMessage } from '@components/common/typeahead/components/no-options-message/NoOptionsMessage';
import { FITOptions } from './option/Option';
import { useAsync } from 'react-use';
import { AlgoliaApi, AlgoliaIndex } from '@model/iceberg/service/algolia/AlgoliaApi';

export interface AutoCompleteProps {
  value: Name;
  currentRefinement: string;
  onChange: (value: GeographyResult) => void;
  onClear: () => void;
  refine: (value: string) => void;
  hits: Array<any>;
  searching: boolean;
  searchRef: any;
  onBlur: any;
  active: any;
  onFocus: any;
  searchableDestination: string;
  onInputChange: (value: string, refine: any) => void;
  onOptionsChange: (options: Array<GroupedDestination>) => void;
}

export interface GroupedDestination {
  label: string;
  options: Array<GeographyResult>;
}

export const filterOptions = (hits: Array<GeographyResult>, input: string) => {
  if (input) {
    const optionsWithTypos = hits.filter((option) => !!option._rankingInfo.nbTypos).length;
    const optionsWithoutTypos = hits.filter((option) => !option._rankingInfo.nbTypos).length;
    if (optionsWithTypos && optionsWithoutTypos) {
      return hits.filter((hit) => !hit._rankingInfo.nbTypos);
    }
    return hits;
  }
  return [];
};

const getLabel: (destination?: Name) => string = (name?: Name) => (name && name.display) || '';

export const AutoCompleteComponent: FC<AutoCompleteProps> = (props: AutoCompleteProps) => {
  const {
    hits,
    onChange,
    value,
    searching,
    refine,
    currentRefinement,
    onClear,
    searchRef,
    active,
    onBlur,
    onFocus,
    searchableDestination,
    onInputChange,
    onOptionsChange
  } = props;
  const filteredHits = filterOptions(hits, currentRefinement);
  const options: Array<GroupedDestination> = getOptions(filteredHits);
  const searchValue = value && isEmpty(value.path) ? undefined : value;
  const handleOnChange = (geographyResult: GeographyResult) =>
    !geographyResult ? onClear() : onChange(geographyResult);

  /* *** HOOKS *** */
  useEffect(() => {
    refine(searchableDestination);
  }, [searchableDestination]);

  useEffect(() => {
    onOptionsChange(options);
  }, [options]);

  // When the typeahead is rehydrated from server state
  // request the algolia entry and put this into the store
  // this ensure that the departureAirports are available
  useAsync(async () => {
    if (value.display) {
      if (!currentRefinement) {
        const result = await new AlgoliaApi().search({
          index: AlgoliaIndex.GEOGRAPHY,
          facetFilters: [`name.path:${value.path}`]
        });
        if (result.hits.length === 1) {
          handleOnChange(result.hits[0] as any);
        }
      }
    }
  }, []);

  return (
    <Typeahead
      id={'destinations-typeahead'}
      searchRef={searchRef}
      active={active}
      placeholder={'search__destination--placeholder'}
      value={searchValue}
      inputValue={currentRefinement}
      onChange={handleOnChange}
      onInputChange={(value: string) => onInputChange(value, refine)}
      options={options}
      renderOption={FITOptions}
      renderNoOptionsMessage={NoOptionsMessage}
      renderMenuList={MenuList}
      renderGroup={Group}
      getOptionLabel={(destination?: Name) => getLabel(destination)}
      getValueLabel={(destination?: Name) => getLabel(destination)}
      loading={searching}
      filterOption={() => true}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

export const AutoComplete = connectAutoComplete(connectStateResults(AutoCompleteComponent));
