import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import {
  ModalContainer,
  Container,
  Heading,
  ModalContentText,
  CloseIcon,
  ModalContent,
  ButtonsContainer,
  CTAButton
} from '@styles/modal';
import { useI18NextContext } from '@components/hooks';
import { ButtonVariant, ButtonColor, ButtonSize } from '@components/material-ui';

/* ***************** *
 *       Types       *
 * ***************** */
export interface TransferLuggageModalProps {
  hasLuggage: boolean;
  hasTransfer: boolean;
  onContinue: () => void;
  onDismiss: () => void;
}

/* ****************** *
 *       Styles       *
 * ****************** */
const ModalContentHeading: any = styled.h6(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  fontSize: '1.6rem !important',
  margin: 0
}));

export const TransferLuggageModal = ({ hasLuggage, hasTransfer, onDismiss, onContinue }: TransferLuggageModalProps) => {
  const t: any = useI18NextContext();

  let dismissLabel: string = 'transfer-luggage-modal--cta-dismiss';

  if (hasLuggage && !hasTransfer) {
    dismissLabel = 'transfer-luggage-modal--cta-dismiss-transfer';
  } else if (!hasLuggage && hasTransfer) {
    dismissLabel = 'transfer-luggage-modal--cta-dismiss-luggage';
  }

  return (
    <ModalContainer>
      <Container>
        <Heading>
          {t('transfer-luggage-modal--heading')}
          <CloseIcon name={'mhi-clear'} onClick={onDismiss} />
        </Heading>
        <ModalContent>
          {!hasLuggage && (
            <>
              <ModalContentHeading>{t('transfer-luggage-modal--no-luggage-heading')}</ModalContentHeading>
              <ModalContentText>{t('transfer-luggage-modal--no-luggage')}</ModalContentText>
            </>
          )}
          {!hasTransfer && (
            <>
              <ModalContentHeading>{t('transfer-luggage-modal--no-transfer-heading')}</ModalContentHeading>
              <ModalContentText>{t('transfer-luggage-modal--no-transfer')}</ModalContentText>
            </>
          )}
        </ModalContent>
        <ButtonsContainer>
          <CTAButton
            variant={ButtonVariant.CONTAINED}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.REGULAR}
            label={t('transfer-luggage-modal--cta-continue')}
            onClick={onContinue}
          />
          <CTAButton
            variant={ButtonVariant.OUTLINED}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.REGULAR}
            label={t(dismissLabel)}
            onClick={onDismiss}
          />
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
};
