import { useDispatch, useSelector } from 'react-redux';
import { closeModal, ModalType } from '@state/modal/modalOperations';
import React from 'react';
import { BaseModal } from '@components/common/modal';
import { getModalType } from '@state/modal/modalSelectors';
import { TransferLuggageModal } from './TransfersLuggageModal';
import { Basket } from '@model/iceberg';
import { getBasket, navigate } from '@state/basket';
import { Page } from '@model/common';
import { getLuggage, getTransfers } from '@state/extras';
import { Transfers } from '@model/iceberg/common/transfers';

export const TransferLuggageModalContainer = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(getModalType);
  const handleOnDismiss = () => dispatch(closeModal());
  const luggageOptions = useSelector(getLuggage);
  const transferOptions = useSelector(getTransfers);

  const basket: Basket = useSelector(getBasket);

  const vehicle = basket.transfer && !Array.isArray(basket.transfer) ? basket.transfer.vehicle : null;
  const hasTransfer = transferOptions.length > 1 && !!basket.transfer && vehicle !== Transfers.NO_TRANSFER;
  const hasLuggage = !!luggageOptions.length && !!basket.luggage;

  const navigateToGuests = () => {
    dispatch(closeModal());
    dispatch(navigate(Page.GUESTS));
  };

  return (
    <BaseModal open={modalType === ModalType.TRANSFER_LUGGAGE_MODAL} onDismiss={handleOnDismiss} hideCloseButton>
      <TransferLuggageModal
        hasTransfer={hasTransfer}
        hasLuggage={hasLuggage}
        onContinue={navigateToGuests}
        onDismiss={() => dispatch(closeModal())}
      />
    </BaseModal>
  );
};
