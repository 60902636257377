import React from 'react';
import { TravelDisclaimerFormError } from './TravelDisclaimerFormError';
import { useDispatch, useSelector } from 'react-redux';
import { getModalData, getModalType } from '../../../../state/modal/modalSelectors';
import { closeModal, ModalType } from '../../../../state/modal/modalOperations';
import { BaseModal } from '../../../common/modal';
import { FormikValues } from 'formik';
import { useI18NextContext } from '@components/hooks';
import { Validation } from '@model/forms/validation/validation';

export interface TravelDisclaimerFormErrors {
  values: FormikValues;
}

export enum TravelDisclaimerFormErrorFields {
  NAME = 'form__field-label--full-name',
  EMAIL = 'form__field-label--email-address',
  TELEPHONE = 'form__field-label--phone',
  BOOKING_ID = 'form__field-label--booking-id',
  ADDRESS = 'form__field-label--address-line-1',
  TOWN = 'form__field-label--town',
  COUNTY = 'form__field-label--county',
  POSTCODE = 'form__field-label--postcode'
}

export function getFormErrors(values: FormikValues, t: any): Array<string> {
  const errors: Array<string> = [];
  Object.entries(values).forEach(([key, value]) => {
    if (key === 'bookingReference') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(TravelDisclaimerFormErrorFields.BOOKING_ID) }));
      } else if (!Validation.isBookingReferenceValid(values.bookingReference)) {
        errors.push(t('modal-error--field-error-label', { field: t(TravelDisclaimerFormErrorFields.BOOKING_ID) }));
      }
    }
    if (key === 'name') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(TravelDisclaimerFormErrorFields.NAME) }));
      }
    }

    if (key === 'email') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(TravelDisclaimerFormErrorFields.EMAIL) }));
      } else if (!Validation.isEmailValid(values.email)) {
        errors.push(t('modal-error--field-error-label', { field: t(TravelDisclaimerFormErrorFields.EMAIL) }));
      }
    }

    if (key === 'telephone') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(TravelDisclaimerFormErrorFields.TELEPHONE) }));
      } else if (!Validation.isTelephoneValid(value)) {
        errors.push(t('modal-error--field-error-label', { field: t(TravelDisclaimerFormErrorFields.TELEPHONE) }));
      }
    }

    if (key === 'address') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(TravelDisclaimerFormErrorFields.ADDRESS) }));
      }
    }

    if (key === 'townCity') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(TravelDisclaimerFormErrorFields.TOWN) }));
      }
    }

    if (key === 'county') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(TravelDisclaimerFormErrorFields.COUNTY) }));
      }
    }

    if (key === 'postcode') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(TravelDisclaimerFormErrorFields.POSTCODE) }));
      }
    }
  });

  return errors;
}

export const TravelDisclaimerFormErrorContainer = () => {
  const dispatch = useDispatch();
  const t: any = useI18NextContext();
  const modalType = useSelector(getModalType);
  const modalData: TravelDisclaimerFormErrors = useSelector(getModalData) as TravelDisclaimerFormErrors;
  const handleOnDismiss = () => dispatch(closeModal());
  return (
    <BaseModal open={modalType === ModalType.TRAVEL_DISCLAIMER_FORM_ERROR} onDismiss={handleOnDismiss} hideCloseButton>
      <TravelDisclaimerFormError errors={getFormErrors(modalData.values, t)} onClick={handleOnDismiss} />
    </BaseModal>
  );
};
