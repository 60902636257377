import { Icon } from '@components/common/generic-icon';
import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import styled from '@emotion/styled';
import React from 'react';
import { mq } from '@styles/breakpoints';
import { ThemeProps } from '@theme/base';

export interface CalendarInfoProps {
  nights: number;
  canClearDates: boolean;
  onClearDates: () => void;
}

const Container = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  height: 30
});

const FirstCalendarContainer = styled.div({
  padding: 0,
  display: 'flex',
  justifyContent: 'space-between',
  [mq.small]: {
    padding: '0 35px'
  }
});

const ClearDatesLabel: any = styled.div((props: any) => ({
  ...(props.theme.custom.typography.labels.default as any),
  transition: `color ${props.theme.custom.transitions.default}`,
  color: props.disabled ? props.theme.custom.colors.group11.lighter : props.theme.custom.colors.group11.base,
  '&:hover': {
    color: props.disabled ? props.theme.custom.colors.group11.lighter : props.theme.custom.colors.group11.dark
  }
}));

const NoNightsLabel = styled.p(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  margin: 0,
  ...(theme.custom.typography.labels.default as any),
  [mq.small]: {
    marginRight: 35
  }
}));

export const CalendarInfo = (props: CalendarInfoProps) => {
  const { canClearDates, nights, onClearDates } = props;
  const t: any = useI18NextContext();
  const handleOnClearDates = () => {
    if (canClearDates) {
      onClearDates();
    }
  };
  return (
    <Container
      data-testid={TestId.dates.info}
      onClick={(event) => event.stopPropagation()}
      onMouseDown={(event) => event.stopPropagation()}
    >
      <FirstCalendarContainer>
        <ClearDatesLabel
          disabled={!canClearDates}
          onClick={handleOnClearDates}
          data-testid={TestId.dates.clearDatesLabel}
        >
          {t('search__dates--clear')}
        </ClearDatesLabel>
      </FirstCalendarContainer>
      <NoNightsLabel data-testid={TestId.dates.noNightsLabel}>
        <Icon name={'mhi-nights'} css={{ fontSize: '1.6rem', marginRight: 10 }} />
        {nights} {t('search__dates--nights')}
      </NoNightsLabel>
    </Container>
  );
};
