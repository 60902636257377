import { LabelBase } from '@components/search/tabs/common/search-container/styles';
import styled from '@emotion/styled';
import { mixins } from '@styles/mixins';
import { ZIndex } from '@styles/z-index';
import { Theme } from '@theme/base/theme';
import { mq } from '@styles/breakpoints';

export const Container: any = styled(LabelBase)((props: any) => ({
  width: '100%',
  '.DateRangePicker_picker': {
    zIndex: ZIndex.SEARCH_DROPDOWN,
    top: '44px !important',
    ...mixins(props.theme).dropdownBorder,
    padding: 1,
    // Override React-Dates
    left: `-52px !important`
  },
  '.DateRangePickerInput': {
    border: 0
  },
  '.DateInput': {
    padding: 0,
    width: 85,
    textAlign: 'left',

    [mq.medium]: {
      width: 80
    }
  },
  '.DateInput_input': {
    cursor: 'pointer',
    padding: 0,
    borderBottom: 'none',
    width: '100%',
    textAlign: 'left',
    ...props.theme.custom.typography.labels.default,
    fontWeight: 'bold',
    '::placeholder': {
      color: props.theme.custom.colors.group4.light
    },
    ':hover': {
      color: props.theme.custom.colors.group7.base,
      '::placeholder': {
        color: props.theme.custom.colors.group7.base
      }
    }
  },
  '.DateInput_input__focused': {
    border: 'none',
    color: props.theme.custom.colors.group5.base,
    '::placeholder': {
      color: props.theme.custom.colors.group5.base
    },
    ':hover': {
      color: props.theme.custom.colors.group5.base,
      '::placeholder': {
        color: props.theme.custom.colors.group5.base
      }
    }
  },
  '.DateRangePickerInput_arrow': {
    margin: 6
  },
  '.CalendarMonth_caption': {
    strong: {
      ...props.theme.custom.typography.labels.default,
      fontWeight: 500
    }
  },
  '.CalendarDay': {
    ...props.theme.custom.typography.labels.default,
    outline: 'none',
    fontWeight: 500
  },
  '.CalendarDay__default': {
    ...props.theme.custom.typography.labels.default,
    border: 'none',
    [':hover']: {
      // backgroundColor: props.theme.custom.colors.group5.lighter
    }
  },
  '.CalendarDay__blocked_out_of_range': {
    ...props.theme.custom.typography.labels.default,
    color: props.theme.custom.colors.group4.lighter,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  '.CalendarDay__selected': {},
  '.CalendarDay__selected:only-child': {},
  '.CalendarDay__selected_span': {
    backgroundColor: props.theme.custom.colors.group5.lighter
  },
  '.CalendarDay__today': {
    borderBottom: '3px solid',
    borderColor: props.theme.custom.colors.group11.base
  },
  '.CalendarDay__hovered_span': {
    backgroundColor: props.theme.custom.colors.group5.lighter
  },
  '.CalendarDay__hovered_span:hover': {
    backgroundColor: props.theme.custom.colors.group5.lighter,
    borderRadius: 0
  },
  '.DayPickerNavigation': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    top: 30,
    padding: '0 37px'
  },
  '.DateInput_fang': {
    display: 'none'
  },
  '.DayPicker__withBorder': {
    boxShadow: 'none'
  },
  '.CalendarDay__selected_start': {
    backgroundColor: props.theme.custom.colors.group5.base,
    color: props.theme.custom.colors.white,
    ':hover,:focus': {
      backgroundColor: props.theme.custom.colors.group5.base
    }
  },
  'td.CalendarDay__selected_end': {
    backgroundColor: props.theme.custom.colors.group5.base,
    color: props.theme.custom.colors.white,
    ':hover,:focus': {
      backgroundColor: props.theme.custom.colors.group5.base
    }
  }
}));

export const iconStyles = (theme: Theme) => ({
  fontSize: '1.4rem',
  color: 'black',
  padding: 5,
  ':hover': {
    color: theme.custom.colors.group7.base
  }
});
