import { AlertModal } from '@components/common/modal/alert/AlertModal';
import React from 'react';
import { Icons } from '@model/common/icons';
import { Button, ButtonColor, ButtonVariant } from '@components/material-ui';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';

export interface UnmatchedChildAgeModalProps {
  onDismiss: () => void;
  onNavigate: () => void;
}

const Dismiss = styled(Button)(({ theme }: ThemeProps) => ({
  width: '100%',
  marginRight: theme.custom.spacing.medium
}));

const Navigate = styled(Button)({
  width: '100%'
});

const ButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%'
});

export const UnmatchedChildAgeModal = ({ onDismiss, onNavigate }: UnmatchedChildAgeModalProps) => {
  return (
    <AlertModal
      title={'unmatched-age-modal__title--label'}
      icon={Icons.FASTEST_TIME}
      body={'unmatched-age-modal__body--label'}
      renderBottom={
        <ButtonContainer>
          <Dismiss
            onClick={onDismiss}
            label={'unmatched-age-modal__dismiss-button--label'}
            color={ButtonColor.SECONDARY}
            variant={ButtonVariant.OUTLINED}
          />
          <Navigate
            onClick={onNavigate}
            label={'unmatched-age-modal__home-button--label'}
            variant={ButtonVariant.CONTAINED}
            color={ButtonColor.SECONDARY}
          />
        </ButtonContainer>
      }
    />
  );
};
