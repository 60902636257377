import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { mq } from '@styles/breakpoints';
import { ModalContainer, CentredContainer, Heading as BaseHeading, ButtonsContainer } from '@styles/modal';
import { Icon } from '@components/common/generic-icon';
import { Icons } from '@model/common/icons';
import { ButtonColor, Button, ButtonVariant, ButtonSize } from '@components/material-ui';
import { useI18NextContext } from '@components/hooks';
import { RTapNumber } from '@components/common/r-tap-number';

/* ***************** *
 *       Types       *
 * ***************** */
export interface ConfirmationModalProps {
  onDismiss: () => void;
  onReturnHome: () => void;
}

/* ****************** *
 *       Styles       *
 * ****************** */
const Container: any = styled(CentredContainer)(({ theme }: ThemeProps) => ({
  [mq.small]: {
    maxWidth: 350
  },

  ['i']: {
    fontSize: '10rem',
    color: theme.custom.colors.group4.base,
    paddingTop: 10
  }
}));

const Heading: any = styled(BaseHeading)(({ theme }: ThemeProps) => ({
  margin: `${theme.custom.spacing.medium}px 0`,
  padding: 0
}));

const ModalContent: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  marginBottom: theme.custom.spacing.large
}));

const CTAButton = styled(Button)(({ theme }: ThemeProps) => ({
  ['&:first-of-type']: {
    marginBottom: theme.custom.spacing.medium
  },

  [mq.small]: {
    width: '47%',
    ['&:first-of-type']: {
      marginBottom: 0
    }
  }
}));

export const ConfirmationModal = ({ onDismiss, onReturnHome }: ConfirmationModalProps) => {
  const t: any = useI18NextContext();

  return (
    <ModalContainer>
      <Container>
        <Icon name={Icons.THUMBS_UP} />
        <Heading>{t('confirmation__modal--heading')}</Heading>
        <ModalContent>
          {t('confirmation__modal--body')} <RTapNumber telephoneNumber={t('common__brand_telephone')} />
        </ModalContent>
        <ButtonsContainer>
          <CTAButton
            variant={ButtonVariant.OUTLINED}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.SMALL}
            label={t('confirmation__modal--cta-stay-on-page')}
            onClick={onDismiss}
          />
          <CTAButton
            variant={ButtonVariant.CONTAINED}
            color={ButtonColor.SECONDARY}
            label={t('confirmation__modal--cta-return-to-home')}
            size={ButtonSize.SMALL}
            onClick={onReturnHome}
          />
        </ButtonsContainer>
      </Container>
    </ModalContainer>
  );
};
