import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDealFinderResultsData,
  getDealFinderHasResults
} from '@state/deal-finder-results/dealFinderResultsSelectors';
import { getToursSearchData } from '@state/search-results/tours/toursSelectors';
import { TokenExpires } from '@model/iceberg';
import {
  ExpirationCountdown,
  getStartTime
} from '@components/token-expiration/expiration-countdown/ExpirationCountdown';
import { tokenExpired } from '@state/deal-finder-results/dealFinderResultsOperations';

export interface ExpirationCountdownContainerProps {
  className?: string;
  isTour?: boolean;
}

export const DealFinderExpirationCountdownContainer = (props: ExpirationCountdownContainerProps) => {
  const dispatch = useDispatch();
  const { isTour } = props;
  const results = useSelector(getDealFinderResultsData);
  const tourResults = useSelector(getToursSearchData);
  const { expires } = isTour ? tourResults : results;
  const isExpired: boolean = new TokenExpires(expires).isExpired();
  const isVisible: boolean = !isTour && useSelector(getDealFinderHasResults);
  const handleOnExpired = () => dispatch(tokenExpired());
  useEffect(() => {
    if (expires && isVisible) {
      if (getStartTime(expires) <= 0) {
        handleOnExpired();
      }
    }
  }, []);
  if (!isVisible) return null;
  return <ExpirationCountdown {...props} expires={expires} isExpired={isExpired} onExpired={handleOnExpired} />;
};
