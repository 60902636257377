import { Icon } from '@components/common/generic-icon';
import { iconStyles, SEARCH_HOVER } from '@components/search/tabs/common/search-container';
import React from 'react';
import { Theme } from '@theme/base';
import { withTheme } from '@emotion/react';

export interface SearchIconProps {
  name: string;
  theme: Theme;
}

const SearchIconComponent = ({ name, theme }: SearchIconProps) => (
  <Icon name={name} css={iconStyles(theme)} className={SEARCH_HOVER} />
);

export const SearchIcon = withTheme(SearchIconComponent);
