import { useI18NextContext } from '@components/hooks';
import { TabIndex } from '@model/common';
import { withTheme } from '@emotion/react';
import React from 'react';
import Select from 'react-select';
import { Theme } from '@theme/base';
import { MenuList } from './components/menu-list/MenuList';
import { Group } from './components/group/Group';
import { NoOptionsMessage } from './components/no-options-message/NoOptionsMessage';
import { Input } from './input/Input';
import { styles } from './styles';

export interface TypeaheadProps {
  id: string;
  placeholder: string;
  active: boolean;
  value: any;
  options: Array<any>;
  onChange: (value: any) => void;
  getOptionLabel: (data: any) => string;
  getValueLabel: (data: any) => string;
  theme: Theme;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  filterOption?: (candidate: any, input: string) => boolean;
  renderOption?: any;
  renderNoOptionsMessage?: any;
  renderGroup?: any;
  renderMenuList?: any;
  disabled?: boolean;
  searchRef?: any;
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  loading?: boolean;
}

export const TypeaheadComponent = (props: TypeaheadProps) => {
  const {
    id,
    value,
    disabled,
    onChange,
    options,
    placeholder,
    renderOption,
    getOptionLabel,
    getValueLabel,
    onInputChange,
    theme,
    active,
    loading,
    renderNoOptionsMessage,
    inputValue,
    renderGroup,
    renderMenuList,
    filterOption,
    onFocus,
    onBlur,
    searchRef
  } = props;
  const t: any = useI18NextContext();
  const showNoResults: boolean = !!inputValue && inputValue.length >= 3 && !options.length;
  const isMenuOpen: boolean = (active && !!inputValue && !!options.length) || showNoResults;
  const handleOnInputChange = (event: any) => {
    const value = event === null ? '' : event;
    if (onInputChange) {
      onInputChange(value);
    }
  };

  const handleOnChange = (event: any) => {
    const value = event === null ? '' : event;
    onChange(value);
  };

  return (
    <Select
      inputId={id}
      aria-label={'typeahead'}
      instanceId={'typeahead'}
      isDisabled={disabled}
      inputValue={inputValue}
      value={value}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      options={options}
      ref={searchRef}
      styles={styles(theme)}
      isLoading={loading}
      getOptionLabel={getOptionLabel}
      getOptionValue={getValueLabel}
      placeholder={t(placeholder)}
      isClearable={true}
      menuIsOpen={isMenuOpen}
      loadingMessage={() => ''}
      formatGroupLabel={() => ''}
      filterOption={filterOption}
      theme={(selectTheme: any) => ({ ...theme, ...selectTheme }) as any}
      tabSelectsValue={true}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={TabIndex.ZERO.toString()}
      components={{
        DropdownIndicator: null,
        LoadingIndicator: null,
        Input,
        NoOptionsMessage: renderNoOptionsMessage || NoOptionsMessage,
        MenuList: renderMenuList || MenuList,
        Group: renderGroup || Group,
        ...(renderOption && { Option: renderOption })
      }}
    />
  );
};

export const Typeahead = withTheme(TypeaheadComponent);
