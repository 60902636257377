import { useDispatch, useSelector } from 'react-redux';
import { closeModal, ModalType } from '@state/modal/modalOperations';
import React from 'react';
import { BaseModal } from '@components/common/modal';
import { getModalType, getModalData } from '@state/modal/modalSelectors';
import { ConfirmationModal } from './ConfirmationModal';
import { NextRouter, useRouter } from 'next/router';
import { Page } from '@model/common';

export const ConfirmationModalContainer = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(getModalType);
  const modalData = useSelector(getModalData);
  const handleOnDismiss = modalData.onDismiss ? modalData.onDismiss : () => dispatch(closeModal());

  const router: NextRouter = useRouter();
  const handleReturnHome = () => {
    dispatch(closeModal());
    router.push(Page.HOME);
  };

  return (
    <BaseModal open={modalType === ModalType.CONFIRMATION_MODAL} onDismiss={handleOnDismiss} hideCloseButton>
      <ConfirmationModal onDismiss={handleOnDismiss} onReturnHome={handleReturnHome} />
    </BaseModal>
  );
};
