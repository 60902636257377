import styled from '@emotion/styled';
import MUISlider from '@mui/material/Slider';
import { boxShadowMixins } from '@styles/mixins';
import { ThemeProps } from '@theme/base';

export const Slider = styled(MUISlider)(({ theme }: ThemeProps) => ({
  ['.MuiSlider-rail']: {
    height: 8,
    borderRadius: 8,
    opacity: 1,
    backgroundColor: theme.custom.colors.group10.light,
    boxShadow: boxShadowMixins(theme.custom.colors.group10.base).inset.default
  },

  ['.MuiSlider-track']: {
    height: 8,
    backgroundColor: theme.custom.colors.group1.base
  },

  ['.MuiSlider-thumb']: {
    width: theme.custom.spacing.medium,
    height: theme.custom.spacing.medium,
    backgroundColor: theme.custom.colors.white,
    boxShadow: boxShadowMixins(theme.custom.colors.group10.dark).glow,
    transition: `background-color ${theme.custom.transitions.default}, box-shadow ${theme.custom.transitions.fastSmooth}`,

    ['&:hover']: {
      backgroundColor: theme.custom.colors.group10.light,
      boxShadow: '0px 0px 0px 8px rgba(63, 81, 181, 0.16)'
    },

    ['&.MuiSlider-active']: {
      boxShadow: '0px 0px 0px 14px rgba(63, 81, 181, 0.16)'
    },

    ['&::after']: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0
    },

    ['.MuiSlider-valueLabel']: {
      ['& > span']: {
        transform: 'none',
        marginTop: theme.custom.spacing.xSmall,
        marginLeft: -4,
        width: 48,
        height: 32,
        padding: theme.custom.spacing.xSmall,
        backgroundColor: 'transparent',
        color: 'transparent',
        boxShadow: 'none',
        borderRadius: 0,
        backgroundSize: '48px 32px',
        backgroundPosition: '0 2px',
        ['& > span']: {
          display: 'block',
          width: 48,
          textAlign: 'center',
          color: theme.custom.colors.group4.base,
          transform: 'none',
          ...(theme.custom.typography.labels.attribute as any),

          ['&::after']: {
            content: '":00"'
          }
        }
      }
    },

    ['&:last-of-type']: {
      ['.MuiSlider-valueLabel']: {
        ['& > span']: {
          ['& > span']: {
            ['&::after']: {
              content: '":59"'
            }
          }
        }
      }
    }
  }
}));
