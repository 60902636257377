import React, { ReactNode, FC } from 'react';
import { AlertModal } from '@components/common/modal/alert/AlertModal';
import { useI18NextContext } from '@components/hooks';
import { HelpLink } from '@components/common/next-link';
import { Page } from '@model/common';
import styled from '@emotion/styled';
import { Button, ButtonColor } from '@components/material-ui';
import { ThemeProps } from '@theme/base';

export interface ErrorModalProps {
  onClick: () => void;
  titleKey?: string;
  iconName?: string;
  buttonLabelKey?: string;
  renderBody?: () => ReactNode;
  code?: string;
}

const BodyLabel = styled.div(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.medium
}));

const SearchButton: any = styled(Button)({
  width: '100%'
});

export const ErrorModal: FC<ErrorModalProps> = ({
  onClick,
  renderBody,
  titleKey,
  iconName,
  buttonLabelKey,
  code
}: ErrorModalProps) => {
  const t: any = useI18NextContext();
  return (
    <AlertModal
      code={code}
      body={
        renderBody ? (
          renderBody()
        ) : (
          <div>
            <BodyLabel>{t('error-modal__body--label')}</BodyLabel>
            <HelpLink path={`${Page.HELP}/contact-us`}>{t('error-modal__contact-us--label')}</HelpLink>
          </div>
        )
      }
      title={titleKey || 'error-modal__title--label'}
      icon={iconName || 'mhi-basket-error'}
      renderBottom={
        <SearchButton
          label={t(buttonLabelKey || 'confirmation__modal--cta-return-to-home')}
          onClick={onClick}
          color={ButtonColor.SECONDARY}
        />
      }
    />
  );
};
