import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme, CssThemeProps, ErrorThemeProps } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { ErrorLabel, FieldLabel, FieldSubLabel } from '@styles/forms';
import { Localisation } from '@model/common';
import { getTranslation } from '@util/common';
import { Collapse, TextareaAutosize } from '@mui/material';
import { boxShadowMixins } from '@styles/mixins';

/* ***************** *
 *       Types       *
 * ***************** */
export interface FormikTextAreaProps {
  theme?: Theme;
  testId?: string;
  error: string;
  label?: string | Localisation;
  subLabel?: string;
  name: string;
  onChange: (event: any) => void;
  onBlur: (event: any) => void;
  value: string;
  css?: any;
  placeholder?: string;
  rows?: number;
}

/* ***************** *
 *       Styles      *
 * ***************** */
interface ContainerProps extends CssThemeProps, ErrorThemeProps {}
const Container: any = styled.div(({ theme, css, isError }: ContainerProps) => ({
  ['textarea']: {
    ...(theme.custom.typography.paragraph as any),
    transition: `border-color ${theme.custom.transitions.default}`,
    width: '100%',
    border: `2px solid ${isError ? theme.custom.states.error.dark : theme.custom.colors.group10.light}`,
    boxShadow: boxShadowMixins(theme.custom.colors.group10.lighter).light,
    padding: theme.custom.spacing.medium,
    resize: 'none',

    ['&:hover']: {
      borderColor: theme.custom.colors.group7.base
    }
  },

  ...css
}));

/* ****************************** *
 *    FormikTextAreaComponent    *
 * ****************************** */

export const FormikTextAreaComponent = (props: FormikTextAreaProps) => {
  const { error, label, name, onChange, onBlur, value, css, subLabel, placeholder, rows } = props;
  const t: any = useI18NextContext();

  return (
    <Container data-testid={props.testId || TestId.formikTextArea.main} css={css} isError={!!error}>
      <Collapse in={!!label}>
        <FieldLabel isError={!!error}>
          {getTranslation(t, label)}
          {subLabel && <FieldSubLabel>{getTranslation(t, subLabel)}</FieldSubLabel>}
        </FieldLabel>
      </Collapse>
      <TextareaAutosize
        id={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        placeholder={placeholder}
        minRows={rows}
      />
      <Collapse in={!!error}>
        <ErrorLabel>{t(error)}</ErrorLabel>
      </Collapse>
    </Container>
  );
};

export const FormikTextArea = withTheme(FormikTextAreaComponent);
