import { useDispatch } from 'react-redux';
import { UnmatchedChildAgeModal } from './UnmatchedChildAgeModal';
import React from 'react';
import { closeModal } from '@state/modal/modalOperations';
import { useRouter } from 'next/router';
import { Page } from '@model/common';

export const UnmatchedChildAgeModalContainer = () => {
  const dispatch = useDispatch();
  const onDismiss = () => dispatch(closeModal());
  const router = useRouter();
  const onNavigate = () => router.push(Page.HOME);
  return <UnmatchedChildAgeModal onDismiss={onDismiss} onNavigate={onNavigate} />;
};
