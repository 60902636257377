import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { TestId } from '@components/test-ids';
import { Theme } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { withIsServer } from '@components/hoc';
import { FieldLabel, ErrorLabel, FieldSubLabel, DatePickerField } from '@styles/forms';
import { Localisation } from '@model/common';
import { getTranslation } from '@util/common';
import { DatePicker, FocusedInput } from '@components/search/tabs/fit/dates/date-picker/DatePicker';
import { DateRange } from '@model/common';
import { datePickerToEvent } from '@util/forms';
import { ClickAwayListener, Collapse } from '@mui/material';
import { Icon } from '../generic-icon';

/* ***************** *
 *       Types       *
 * ***************** */
export interface FormikDatePickerFieldProps {
  theme?: Theme;
  testId?: string;
  error: string;
  label?: string | Localisation;
  subLabel?: string;
  name: string;
  onChange: (event: any) => void;
  value: DateRange;
  css?: any;
  onSetTouched: (field: string, isTouched: boolean) => void;
  isServer: boolean;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const Container: any = styled.div(({ css }: any) => ({
  ...css
}));

const DatePickerWrapper = styled.div({
  display: 'flex',
  alignItems: 'center'
});

/* ************************************ *
 *    FormikDatePickerFieldComponent    *
 * ************************************ */

export const FormikDatePickerFieldComponent = (props: FormikDatePickerFieldProps) => {
  const { error, label, name, onChange, value, css, subLabel, onSetTouched, isServer } = props;
  const t: any = useI18NextContext();

  /* *** LOGIC *** */
  const getNextInput = (focus: string | null, value: DateRange) => {
    const { startDate, endDate } = value;
    if (active) {
      if (!focus) {
        if (!endDate && !!startDate) {
          return FocusedInput.END_DATE;
        }
        if (!startDate && !!endDate) {
          return FocusedInput.START_DATE;
        }
        return FocusedInput.START_DATE;
      }
    }
    return null;
  };

  const handleOnChange = ({ startDate, endDate }: DateRange) => {
    onChange(datePickerToEvent({ name, startDate, endDate }));
  };

  const onGetNextFocus = () => {
    const nextFocus: FocusedInput | null = getNextInput(focused as FocusedInput, value);
    setFocused(nextFocus as FocusedInput);
  };

  const handleOnFocusChange = (value: any) => {
    if (value) {
      setFocused(value);
      return;
    }
    onGetNextFocus();
  };

  /* *** HOOKS *** */
  const [focused, setFocused] = useState<FocusedInput | null>();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(!!focused);
  }, [focused]);

  useEffect(() => {
    const handleTab = (event: any) => {
      if (event.keyCode === 9 && focused === FocusedInput.END_DATE) {
        onSetTouched(name, true);
        setFocused(null);
        setActive(false);
      }
    };
    if (!isServer) {
      document.addEventListener('keydown', handleTab);
    }

    return () => document.removeEventListener('keydown', handleTab);
  }, [focused]);

  return (
    <Container data-testid={props.testId || TestId.formikDatePickerField.main} css={css}>
      <Collapse in={!!label}>
        <FieldLabel isError={!!error}>
          {getTranslation(t, label)}
          {subLabel && <FieldSubLabel>{getTranslation(t, subLabel)}</FieldSubLabel>}
        </FieldLabel>
      </Collapse>
      <ClickAwayListener
        onClickAway={() => {
          if (active && focused) {
            onSetTouched(name, true);
          }
          setFocused(null);
        }}
      >
        <div>
          <DatePickerField
            isError={!!error}
            isActive={active}
            onClick={() => !focused && setFocused(value.startDate ? FocusedInput.END_DATE : FocusedInput.START_DATE)}
          >
            <DatePickerWrapper>
              <Icon name={'mhi-calendar'} />
              <DatePicker
                dates={value}
                onChange={handleOnChange}
                onBlur={() => {}}
                onFocusChange={handleOnFocusChange}
                focusedInput={focused!}
              />
            </DatePickerWrapper>
            <Icon name={active ? 'mhi-caret-up' : 'mhi-caret-down'} className={'dropdown-chevron'} />
          </DatePickerField>
        </div>
      </ClickAwayListener>
      <Collapse in={!!error}>
        <ErrorLabel>{t(error)}</ErrorLabel>
      </Collapse>
    </Container>
  );
};

export const FormikDatePickerField = withTheme(withIsServer(FormikDatePickerFieldComponent));
