import React from 'react';
import { PromoBannerComponent } from '@components/common/banner/promo/PromoBannerComponent';
import { PromoBannerData } from '@model/promo-banner';

export interface PromoBannerContainerProps {
  path: string;
  promoBanners: Array<PromoBannerData>;
  tripType: string | null;
}

export const PromoBannerContainer = ({ promoBanners, path, tripType }: PromoBannerContainerProps) => {
  return <PromoBannerComponent currentPath={path} promoBanners={promoBanners} tripType={tripType} />;
};
