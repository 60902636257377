import { FormError } from '@components/modal/form-error/FormError';
import React from 'react';
import { PaymentFormValues, PaymentMethod } from '@model/forms';
import cardValidator from 'card-validator';
import moment from 'moment';
import { amountStringToFloat } from '@util/currency';
import { useI18NextContext } from '@components/hooks';
import styled from '@emotion/styled';
import { Validation } from '@model/forms/validation/validation';
import { ThemeProps } from '@theme/base';

export interface GetPaymentFormErrorsConfig {
  form: PaymentFormValues;
  isAgent: boolean;
  requiresPayment: boolean;
  t: any;
}

export function getPaymentFormErrors(config: GetPaymentFormErrorsConfig): Array<string> {
  const { form, isAgent, requiresPayment, t } = config;
  const labels: Array<string> = [];

  if (form.paymentMethod === PaymentMethod.CUSTOM_AMOUNT) {
    if (!form.amount) {
      labels.push(t('payment__modal--custom-amount-missing'));
    } else {
      const amountAsFloat = amountStringToFloat(form.amount);
      if (isNaN(amountAsFloat) || amountAsFloat <= 0) {
        labels.push(t('payment__modal--custom-amount-incorrect'));
      } else {
        if (form.totalOutstandingAmount) {
          const customAmountInPence = amountAsFloat * 100;
          if (customAmountInPence > form.totalOutstandingAmount) {
            labels.push(t('payment__modal--custom-amount-too-big'));
          }
        }
      }
    }
  }

  if (isAgent) {
    if (!form.tradeEmail) {
      labels.push(t('form__field-error--required-email'));
    } else if (!Validation.isEmailValid(form.tradeEmail)) {
      labels.push(t('form__field-error--email'));
    }
  }

  if (!isAgent || requiresPayment) {
    if (!form.address || !form.postCode || !form.townCity || !form.county) {
      labels.push(t('payment__modal--billing-address'));
    }

    if (!form.cardNumber) {
      labels.push(t('payment__modal--card-number'));
    }

    if (!cardValidator.number(form.cardNumber).isPotentiallyValid) {
      labels.push(t('payment__modal--card-number--invalid'));
    }

    if (!form.cardExpiryDate) {
      labels.push(t('payment__modal--expiry-date'));
    } else {
      const expiry = moment(form.cardExpiryDate, 'MM/YY');
      const today = moment();
      if (expiry.isBefore(today) || !expiry.isValid()) {
        labels.push(t('payment__modal--expiry-date-invalid'));
      }
    }

    if (!form.cvv) {
      labels.push(t('payment__modal--security-code'));
    }

    if (!form.nameOnCard) {
      labels.push(t('payment__modal--name-on-card'));
    }
  }

  if (!form.termsAccepted) {
    labels.push(t('payment__modal--terms-accepted'));
  }

  return labels;
}

const ListItem: any = styled.li(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any)
}));

export interface PaymentPageFormErrorProps {
  isAgent: boolean;
  requiresPayment: boolean;
  form: PaymentFormValues;
  onClick: () => void;
}

export const PaymentPageFormError = ({ isAgent, requiresPayment, onClick, form }: PaymentPageFormErrorProps) => {
  const t: any = useI18NextContext();
  const errors = getPaymentFormErrors({ form, isAgent, requiresPayment, t });
  const renderErrors = () =>
    errors.map((error: string) => (
      <ul key={error}>
        <ListItem>{error}</ListItem>
      </ul>
    ));
  return (
    <FormError
      onClick={onClick}
      renderErrors={renderErrors}
      title={'payment__modal--title-label'}
      body={'payment__modal--body-label'}
      buttonLabel={'guests__modal--button-label'}
    />
  );
};
