import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Page } from '@model/common';
import { closeModal } from '@state/modal/modalOperations';
import { getBookingPageFromUrl } from '@model/common/pages/get-booking-page-from-url';
import { DealFinderState } from '@model/state';
import { getDealFinderState } from '@state/deal-finder/dealFinderSelectors';
import { performSearch } from '@state/deal-finder-results/dealFinderResultsOperations';
import { ExpirationModal } from '@components/token-expiration';
import { performNavigate } from '@state/app';
import { getSearchQuery } from '@util/deal-finder';

export const DealFinderExpirationModalContainer = () => {
  const dealFinder: DealFinderState = useSelector(getDealFinderState);
  const dispatch = useDispatch();
  const handleOnClick = () => {
    dispatch(performSearch(dealFinder));
    dispatch(performNavigate({ pathname: Page.SEARCH, query: { ...getSearchQuery(dealFinder) } }));
    dispatch(closeModal());
  };
  const page: Page | undefined = getBookingPageFromUrl();
  if (!page) return null;

  return <ExpirationModal onClick={handleOnClick} />;
};
