import Link, { LinkProps } from 'next/link';
import { DestinationLinkBuilder } from '@model/iceberg/geography';
import React from 'react';
import { TripTypes } from '@model/contentful';
import { TestId } from '@components/test-ids';
import { OfferType } from '@model/offers/offers';
import { Page } from '@model/common';
import { PlainLinkStyles } from '@styles/common';

export interface DestinationLinkProps {
  path: string;
  children?: JSX.Element | string;
  tripType?: TripTypes;
  offerType?: OfferType;
  urlParams?: string;
}

export const DestinationLink = ({ path, children, tripType, offerType, urlParams }: DestinationLinkProps) => {
  const linkBuilder: DestinationLinkBuilder = new DestinationLinkBuilder();
  const link: LinkProps = linkBuilder.getDestinationLink(
    path,
    tripType,
    offerType
      ? (`${Page.OFFERS}/${offerType}${urlParams ? `?${urlParams}` : ''}` as any)
      : urlParams
        ? `?${urlParams}`
        : ''
  );
  return (
    <Link prefetch={false} {...link} passHref style={PlainLinkStyles} data-testid={TestId.destinationLink}>
      {children}
    </Link>
  );
};

DestinationLink.defaultProps = {
  prefetch: true
};
