import { PlaceType } from '@model/iceberg/geography';
import { GeographyResult } from '@model/search';
import { groupBy } from 'lodash';
import { GroupedDestination } from './AutoComplete';

const sortGroups = (a: any, b: any) => {
  const destinationWeight: any = {
    [PlaceType.CONTINENT]: 0,
    [PlaceType.COUNTRY]: 1,
    [PlaceType.REGION]: 2,
    [PlaceType.RESORT]: 3,
    [PlaceType.HOTEL]: 4
  };
  return destinationWeight[a.label] - destinationWeight[b.label];
};

export const getOptions: (hits: Array<GeographyResult>) => Array<GroupedDestination> = (
  hits: Array<GeographyResult>
) => {
  const grouped = groupBy(hits, (data) => data.type);
  return Object.keys(grouped)
    .map((label: string) => ({ label, options: grouped[label] }))
    .sort(sortGroups);
};
