import React from 'react';
import { useSelector } from 'react-redux';
import { getModalData } from '@state/modal/modalSelectors';
import { ErrorModal } from '@components/common/modal/alert/error/ErrorModal';
import { useRouter } from 'next/router';
import { Page } from '@model/common';
import { useI18NextContext } from '@components/hooks';

export const SearchErrorModalContainer = () => {
  const router = useRouter();
  const t: any = useI18NextContext();
  const modalData = useSelector(getModalData);
  const handleOnClick = () => router.push(Page.HOME);
  const renderBody = () => {
    return <span>{t('error-modal-search-body')}</span>;
  };
  return (
    <ErrorModal
      onClick={handleOnClick}
      iconName="mhi-search-error"
      titleKey="common__label--something-wrong"
      renderBody={renderBody}
      buttonLabelKey="token__expiration-message--button"
      code={modalData?.code}
    />
  );
};
