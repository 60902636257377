import styled from '@emotion/styled';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme } from '@theme/base';
import { DestinationsNavContent } from './DestinationsNavContent';
import { SelectedOptions, SetOptions } from './NavBar';
import { HereToHelpNavContent } from './HereToHelpNavContent';
import { ContentTypes } from '@model/navigation';
import { CMSDestinations } from '@model/contentful/destination';
import { CMSHereToHelp } from '@model/contentful';
import { OffersNavContent } from './OffersNavContent';
import { CMSOffers } from '@model/contentful/offers';

/* ***************** *
 *       Types       *
 * ***************** */
export interface DesktopNavigationContentProps {
  theme?: Theme;
  testId?: string;
  navContent: ContentTypes;
  selectedOptions: SelectedOptions;
  setOptions: SetOptions;
  onToggleDesktopNav?: () => void;
  cmsDestinations: CMSDestinations;
  cmsHereToHelp: CMSHereToHelp;
  cmsOffers: CMSOffers;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const DesktopContentContainer = styled.div({
  display: 'flex',
  minHeight: '100%',
  a: {
    textDecoration: 'none'
  }
});

/* ****************************** *
 *    DesktopNavigationContent    *
 * ****************************** */

export const DesktopNavigationContentComponent = (props: DesktopNavigationContentProps) => {
  const { navContent, selectedOptions, setOptions, onToggleDesktopNav, cmsDestinations, cmsHereToHelp, cmsOffers } =
    props;

  /* *** RENDERERS *** */
  const renderContent = () => {
    switch (navContent) {
      case ContentTypes.OFFERS:
        return (
          <OffersNavContent
            title={cmsOffers.title}
            offers={cmsOffers.components}
            onToggleDesktopNav={onToggleDesktopNav}
          />
        );
      case ContentTypes.DESTINATIONS:
        return (
          <DestinationsNavContent
            cmsDestinations={cmsDestinations}
            selectedOptions={selectedOptions}
            setOptions={setOptions}
            onToggleDesktopNav={onToggleDesktopNav}
          />
        );
      case ContentTypes.HERE_TO_HELP:
        return (
          <HereToHelpNavContent
            title={cmsHereToHelp.title}
            infoTiles={cmsHereToHelp.infoTiles}
            onToggleDesktopNav={onToggleDesktopNav}
          />
        );
      default:
        return null;
    }
  };

  return (
    <DesktopContentContainer data-testid={props.testId || TestId.desktopNavigationContent.main}>
      {renderContent()}
    </DesktopContentContainer>
  );
};
