import Link from 'next/link';
import React from 'react';
import { Page } from '@model/common/pages';
import { PlainLinkStyles } from '@styles/common';

export interface HelpCentreLinkProps {
  path: string;
  children?: any;
}

export const HelpCentreLink = ({ path, children }: HelpCentreLinkProps) => {
  return (
    <Link prefetch={false} href={`${Page.HELP_CENTRE}/[category]`} as={path} passHref style={PlainLinkStyles}>
      {children}
    </Link>
  );
};
