import { FormError } from '@components/modal/form-error/FormError';
import React from 'react';
import styled from '@emotion/styled';
import { useI18NextContext } from '@components/hooks';
import { ThemeProps } from '@theme/base';

const ListItem: any = styled.li(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any)
}));

export interface TravelDisclaimerFormErrorProps {
  errors: Array<string>;
  onClick: () => void;
}

export const TravelDisclaimerFormError = ({ errors, onClick }: TravelDisclaimerFormErrorProps) => {
  const t: any = useI18NextContext();
  const body: string = t('travel-disclaimer-error-modal__body--label');
  const renderErrors = () => (
    <ul>
      {errors.map((error: string) => {
        return <ListItem key={error}>{error}</ListItem>;
      })}
    </ul>
  );
  return (
    <FormError
      onClick={onClick}
      renderErrors={renderErrors}
      title={'travel-disclaimer-error-modal__title--label'}
      body={body}
      buttonLabel={'travel-disclaimer-error-modal__button--label'}
    />
  );
};
