import { OnInit } from '@model/bootstrap/on-init';
import axios from 'axios';
import Cookies, { Cookie } from '@model/common/cookie/cookie';

export class XdebugBootstrap implements OnInit {
  initialize(): void {
    axios.interceptors.request.use(
      function (config) {
        const xdebug: string | undefined = new Cookies().get(Cookie.XDEBUG);

        if (xdebug) {
          config.headers['XDEBUG_SESSION'] = xdebug;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }
}
