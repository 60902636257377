import { ModalType } from '@state/modal/modalOperations';
import { useSelector } from 'react-redux';
import { getModalType } from '@state/modal/modalSelectors';
import React from 'react';
import { RootModal } from '@components/modal/root/RootModal';
import { withIsServer } from '@components/hoc';

interface RootModalContainerProps {
  isServer: boolean;
}

export const RootModalContainer = withIsServer(({ isServer }: RootModalContainerProps) => {
  const modalType: ModalType = useSelector(getModalType);
  if (isServer) {
    return null;
  }
  return <RootModal modalType={modalType} />;
});
