import React, { FC } from 'react';
import { AlertModal } from '@components/common/modal/alert/AlertModal';
import styled from '@emotion/styled';
import { useI18NextContext } from '@components/hooks';
import { Button, ButtonColor } from '@components/material-ui';
import { Icons } from '@model/common/icons';
import { ThemeProps } from '@theme/base';

export interface ErrorModalProps {
  onClick: () => void;
}

const BodyLabel = styled.div(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.medium
}));

const StyledButton: any = styled(Button)({
  width: '100%'
});

export const SearchFormGuestsModal: FC<ErrorModalProps> = ({ onClick }: ErrorModalProps) => {
  const t: any = useI18NextContext();
  return (
    <AlertModal
      body={
        <div>
          <BodyLabel>{t('modal__search-form-guests--body')}</BodyLabel>
        </div>
      }
      title={'Search Error'}
      icon={Icons.SEARCH_ERROR}
      renderBottom={
        <StyledButton label={t('modal__search-form-guests--button')} onClick={onClick} color={ButtonColor.SECONDARY} />
      }
    />
  );
};
