import { mq } from '@styles/breakpoints';
import styled from '@emotion/styled';
import { DealFinderExpirationCountdownContainer } from '@components/deal-finder/results/token/DealFinderExpirationCountdownContainer';
import { ThemeProps } from '@theme/base';

export const MobileExpirationCountdown = styled(DealFinderExpirationCountdownContainer)(({ theme }: ThemeProps) => ({
  display: 'flex',
  marginTop: theme.custom.spacing.xLarge,
  marginBottom: theme.custom.spacing.xLarge,
  [mq.medium]: {
    display: 'none'
  }
}));
