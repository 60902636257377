import React from 'react';
import { MyBookingLoginFormError } from './MyBookingLoginFormError';
import { useDispatch, useSelector } from 'react-redux';
import { getModalData, getModalType } from '../../../../state/modal/modalSelectors';
import { closeModal, ModalType } from '../../../../state/modal/modalOperations';
import { BaseModal } from '../../../common/modal';
import { FormikValues } from 'formik';
import { useI18NextContext } from '@components/hooks';
import { Validation } from '@model/forms/validation/validation';

export interface MyBookingLoginFormErrors {
  values: FormikValues;
}

export enum MyBookingLoginFormErrorFields {
  BOOKING_ID = 'form__field-label--booking-id',
  LAST_NAME = 'form__field-label--lead-surname',
  DEPARTURE_DATE = 'form__field-label--departure-date'
}

export function getFormErrors(values: FormikValues, t: any): Array<string> {
  const errors: Array<string> = [];
  Object.entries(values).forEach(([key, value]) => {
    if (key === 'bookingId') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(MyBookingLoginFormErrorFields.BOOKING_ID) }));
      } else if (!Validation.isBookingReferenceValid(values.bookingId)) {
        errors.push(t('modal-error--field-error-label', { field: t(MyBookingLoginFormErrorFields.BOOKING_ID) }));
      }
    }
    if (key === 'lastName') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(MyBookingLoginFormErrorFields.LAST_NAME) }));
      }
    }

    if (key === 'departureDate') {
      if (!value) {
        errors.push(t('modal-error--field-missing-label', { field: t(MyBookingLoginFormErrorFields.DEPARTURE_DATE) }));
      } else if (
        !Validation.isDateFormatValid(values.departureDate) ||
        !Validation.isDepartureDateValid(values.departureDate)
      ) {
        errors.push(t('modal-error--field-error-label', { field: t(MyBookingLoginFormErrorFields.DEPARTURE_DATE) }));
      }
    }
  });
  return errors;
}

export const MyBookingLoginFormErrorContainer = () => {
  const dispatch = useDispatch();
  const t: any = useI18NextContext();
  const modalType = useSelector(getModalType);
  const modalData: MyBookingLoginFormErrors = useSelector(getModalData) as MyBookingLoginFormErrors;
  const handleOnDismiss = () => dispatch(closeModal());
  return (
    <BaseModal open={modalType === ModalType.MMB_LOGIN_FORM_ERROR} onDismiss={handleOnDismiss} hideCloseButton>
      <MyBookingLoginFormError errors={getFormErrors(modalData.values, t)} onClick={handleOnDismiss} />
    </BaseModal>
  );
};
