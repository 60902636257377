import React, { FC } from 'react';
import { Icon } from '../../generic-icon/Icon';
import styled from '@emotion/styled';
import MUIModal from '@mui/material/Modal';
import { Slide, Zoom } from '@mui/material';
import { keyframes } from '@emotion/css';
import { withTheme } from '@emotion/react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import MUIDialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';

/* ***************** *
 *       Types       *
 * ***************** */
export interface BaseModalProps {
  theme?: Theme;
  testId?: string;
  open: boolean;
  hideCloseButton?: boolean;
  onDismiss?: () => void;
  children?: any;
  noBackdropDismiss?: boolean;
  simpleBackdrop?: boolean;
  bgColor?: string;
  isScrollBodyModal?: boolean;
  bdFilter?: string;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const backdropAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

interface ModalBackdropProps extends ThemeProps {
  bgColor?: string;
  bdFilter?: string;
}
const ModalBackdrop: any = styled.div(({ theme, bgColor, bdFilter }: ModalBackdropProps) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: bgColor || theme.custom.colors.overlay.darker,
  animation: `${backdropAnimation} 1s`,
  backdropFilter: bdFilter || ''
}));

const ModalCloseButton = styled.button(({ theme }: ThemeProps) => ({
  position: 'absolute',
  top: theme.custom.spacing.medium,
  right: theme.custom.spacing.medium,
  display: 'flex',
  justifyContent: 'flex-end',
  border: 'none',
  width: 40,
  height: 40,
  marginRight: 2,
  backgroundColor: 'transparent',
  cursor: 'pointer',
  zIndex: 1,

  ['@media (min-width: 1024px)']: {
    padding: 0
  }
}));

const modalCloseIcon = (theme: Theme) => ({
  fontSize: '2.5rem',
  color: theme.custom.colors.white,
  marginLeft: theme.custom.spacing.medium,
  alignSelf: 'flex-start'
});

const MUIModalStyled: any = styled(MUIModal)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const MUIDialogStyled: any = styled(MUIDialog)({
  alignItems: 'center',
  ['.MuiDialog-paperScrollBody']: {
    maxWidth: 'none'
  }
});

const Children = styled.div({
  width: '100%',
  height: '100%'
});

/* *********************** *
 *    BaseModalComponent    *
 * *********************** */

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction={'up'} timeout={{ enter: 500, exit: 0 }} ref={ref} {...props} />;
});

export const BaseModalComponent: FC<BaseModalProps> = (props: BaseModalProps) => {
  const {
    open,
    hideCloseButton,
    onDismiss,
    noBackdropDismiss,
    children,
    theme,
    testId,
    bgColor,
    isScrollBodyModal,
    bdFilter
  } = props;

  const commonProps = {
    open: open,
    onClose: () => {
      if (!noBackdropDismiss && typeof onDismiss === 'function') {
        onDismiss();
      }
    },
    BackdropComponent: ModalBackdrop,
    BackdropProps: bgColor && { bgColor, bdFilter },
    'data-testid': testId || TestId.modal.main,
    children: (
      <>
        {!hideCloseButton && (
          <ModalCloseButton onClick={onDismiss} data-testid={TestId.modal.closeButton}>
            <Icon name={'mhi-clear'} css={modalCloseIcon(theme!)} />
          </ModalCloseButton>
        )}
      </>
    )
  };

  return isScrollBodyModal ? (
    <MUIDialogStyled TransitionComponent={Transition} {...commonProps} scroll={'body'}>
      {children}
    </MUIDialogStyled>
  ) : (
    <MUIModalStyled {...commonProps}>
      <Zoom in={open} timeout={{ enter: 500, exit: 0 }}>
        <Children>{children}</Children>
      </Zoom>
    </MUIModalStyled>
  );
};

export const BaseModal = withTheme(BaseModalComponent);
