import styled from '@emotion/styled';
import React from 'react';
import { Checkbox } from './Checkbox';
import { TestId } from '@components/test-ids';
import { ThemeProps, SelectableThemeProps } from '@theme/base';

const CheckBoxRow: any = styled.div({
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer'
});

export const FilterRowTextLabel: any = styled.span(({ theme, isSelected }: SelectableThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  transition: `color ${theme.custom.transitions.default}`,
  ...(isSelected && { color: theme.custom.colors.group1.base }),
  fontWeight: 500
}));

export const FilterRowLabelWrapper = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',

  ['&:hover']: {
    [FilterRowTextLabel]: {
      color: theme.custom.colors.group5.base
    }
  }
}));

const Title: any = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.default as any),
  fontWeight: 'normal',
  margin: 0
}));

export interface CheckboxRowProps {
  id: string;
  value: any;
  hover: boolean;
  label: string | JSX.Element;
  checked: boolean;
  onHover: (id: string) => void;
  onToggle: (value: any, event: any) => void;
  title?: string;
  onBlur?: () => void;
  checkboxStyle?: Object;
  testId?: string;
}

export const CheckboxRow = (props: CheckboxRowProps) => {
  const { testId, onHover, id, value, onToggle, checked, hover, label, title, onBlur, checkboxStyle } = props;

  const styledCheckbox = {
    paddingLeft: 0,
    padding: 12,
    ...checkboxStyle
  };

  return (
    <>
      {title && <Title>{title}</Title>}
      <CheckBoxRow
        data-testid={testId || TestId.checkboxRow}
        key={id}
        onMouseOver={() => onHover(id)}
        onMouseOut={() => onHover('')}
        onClick={(event: any) => onToggle(value, event)}
      >
        <Checkbox
          checkboxStyles={styledCheckbox}
          value={value}
          checked={checked}
          hover={hover}
          onToggle={onToggle}
          label={label}
          onBlur={onBlur}
          available={true}
        />
        <FilterRowLabelWrapper>
          <FilterRowTextLabel>{label}</FilterRowTextLabel>
        </FilterRowLabelWrapper>
      </CheckBoxRow>
    </>
  );
};
