import { mq } from '@styles/breakpoints';
import styled from '@emotion/styled';
import { DealFinderExpirationCountdownContainer } from '@components/deal-finder/results/token/DealFinderExpirationCountdownContainer';

export const DesktopExpirationCountdown = styled(DealFinderExpirationCountdownContainer)({
  display: 'none',
  [mq.medium]: {
    display: 'flex'
  }
});
