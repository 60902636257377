import { BaseModal } from '@components/common/modal';
import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';
import { ModalContainer, Container as BaseContainer, Heading, CloseIcon, Paragraph, ModalButton } from '@styles/modal';
import { useI18NextContext } from '@components/hooks';
import { ThemeProps } from '@theme/base';
import { ButtonVariant, ButtonColor, ButtonSize } from '@components/material-ui';
import { TestId } from '@components/test-ids';

/* ********* *
 *   Types   *
 * ********* */
export interface ValidationErrorModalProps {
  modalErrors: Array<string>;
  onDismiss: () => void;
}

/* ********** *
 *   Styles   *
 * ********* */

const Container: any = styled(BaseContainer)(({ theme }: ThemeProps) => ({
  [mq.small]: {
    width: 580,
    padding: theme.custom.spacing.xLarge
  }
}));

const ErrorsList: any = styled.ul(({ theme }: ThemeProps) => ({
  margin: 0,
  paddingLeft: theme.custom.spacing.medium
}));

const ErrorsListItem: any = styled.li(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  marginBottom: theme.custom.spacing.xSmall,
  paddingLeft: theme.custom.spacing.xSmall
}));

export const ValidationErrorModal = ({ onDismiss, modalErrors }: ValidationErrorModalProps) => {
  const t = useI18NextContext();
  return (
    <BaseModal open={true} noBackdropDismiss hideCloseButton testId={TestId.modal.validationErrorModal}>
      <ModalContainer>
        <Container>
          <Heading>
            {t('validation-error__modal--heading')}
            <CloseIcon name={'mhi-clear'} onClick={onDismiss} />
          </Heading>
          <Paragraph>{t('validation-error__modal--text')}</Paragraph>
          <ErrorsList>
            {Array.isArray(modalErrors) &&
              modalErrors.map((error: string) => <ErrorsListItem key={error}>{t(error)}</ErrorsListItem>)}
          </ErrorsList>
          <ModalButton
            variant={ButtonVariant.CONTAINED}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={onDismiss}
            label={t('validation-error-form__button-label--amend-form')}
          />
        </Container>
      </ModalContainer>
    </BaseModal>
  );
};
