import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme } from '@theme/base';
import Link from 'next/link';
import { useI18NextContext } from '@components/hooks';
import { Page } from '@model/common/pages';
import { HelpLink } from '@components/common/next-link';
import { Entry, InfoTile } from '@model/contentful';
import { isPageBlacklisted } from '@model/config/brands';
import { NavContainer, NavHeading, NavItem, LinksListContainer } from './styles';
import { AppVariant } from '@model/config/brands';

/* ***************** *
 *       Types       *
 * ***************** */
export interface HereToHelpNavContentProps {
  theme?: Theme;
  testId?: string;
  title: string;
  infoTiles: Array<Entry<InfoTile>>;
  onToggleDesktopNav?: () => void;
}

/* *********************************** *
 *    HereToHelpNavContentComponent    *
 * *********************************** */

export const HereToHelpNavContentComponent = (props: HereToHelpNavContentProps) => {
  const { onToggleDesktopNav, title, infoTiles } = props;
  const t: any = useI18NextContext();
  const isWhiteLabel = AppVariant.isWhitelabel();

  const renderLinks = () =>
    infoTiles
      .filter((infoTile: any) => !!infoTile.fields && !isPageBlacklisted(infoTile.fields.pageId))
      .map((infoTile: any) => (
        <NavItem onClick={onToggleDesktopNav} key={infoTile.fields.pageId}>
          <HelpLink path={Page.HELP.concat('/').concat(infoTile.fields.pageId)}>{infoTile.fields.title}</HelpLink>
        </NavItem>
      ));
  return (
    <NavContainer data-testid={props.testId || TestId.hereToHelpNavContent.main}>
      <NavHeading>
        {title}{' '}
        <span>
          (
          <Link prefetch={false} href={Page.HELP}>
            {t('nav__label--view-all')}
          </Link>
          )
        </span>
      </NavHeading>
      <LinksListContainer displayInColumns={infoTiles.length > 4}>
        {renderLinks()}
        {!isWhiteLabel && (
          <NavItem onClick={onToggleDesktopNav}>
            <Link href={Page.AGENTS}>{t('agent__link--title')}</Link>
          </NavItem>
        )}
      </LinksListContainer>
    </NavContainer>
  );
};

HereToHelpNavContentComponent.defaultProps = {
  infoTiles: []
};

export const HereToHelpNavContent = withTheme(HereToHelpNavContentComponent);
