import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { mixins, boxShadowMixins } from '@styles/mixins';
import { mq } from '@styles/breakpoints';

export const InputContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%'
}));

interface DestinationsListInputContainerProps extends ThemeProps {
  hasListButton: boolean;
}
export const DestinationsListInputContainer: any = styled(InputContainer)(
  ({ theme }: DestinationsListInputContainerProps) => ({
    ['[class*="placeholder"]']: {
      display: 'block',
      width: '100%',
      ...(mixins(theme).truncate as any)
    },
    marginRight: 55
  })
);

interface DestinationsListButtonProps extends ThemeProps {
  isActive: boolean;
  condensed: boolean;
}
export const DestinationsListButton: any = styled.div(
  ({ theme, isActive, condensed }: DestinationsListButtonProps) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    width: 50,
    height: '100%',
    backgroundColor: theme.custom.colors.group10.lighter,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    borderLeft: `1px solid ${theme.custom.colors.group10.base}`,
    transition: `box-shadow ${theme.custom.transitions.default}`,
    ...(isActive && {
      boxShadow: boxShadowMixins(theme.custom.colors.group10.base).inset.default
    }),

    ['i']: {
      display: 'none',

      [mq.large]: {
        display: 'block',
        color: theme.custom.colors.group4.base,
        fontSize: condensed ? 15 : 20
      }
    }
  })
);

export const DestinationsListButtonLabel: any = styled.span(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.attribute as any),
  color: theme.custom.colors.group4.base
}));

interface ErrorLabelProps extends ThemeProps {
  condensed?: boolean;
}
export const ErrorLabel: any = styled.div(({ theme, condensed }: ErrorLabelProps) => ({
  color: condensed ? theme.custom.states.error.dark : theme.custom.colors.white,
  marginTop: theme.custom.spacing.xSmall,
  ...(theme.custom.typography.labels.default as any)
}));
