import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { FieldLabel, ErrorLabel } from '@styles/forms';
import { Dropdown, DropdownOption } from '../dropdown/Dropdown';
import { Collapse } from '@mui/material';

/* ***************** *
 *       Types       *
 * ***************** */
export interface FormikDropdownFieldProps {
  theme?: Theme;
  testId?: string;
  error: string;
  label?: string;
  name: string;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
  value: string;
  options: Array<DropdownOption>;
  css?: any;
  dropdownCss?: any;
  disabled?: boolean;
  icon?: string;
  placeholder?: string;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const Container: any = styled.div(({ css }: any) => ({
  ...css
}));

/* ********************************** *
 *    FormikDropdownFieldComponent    *
 * ********************************** */

export const FormikDropdownFieldComponent = (props: FormikDropdownFieldProps) => {
  const { error, label, name, onChange, onBlur, value, options, css, dropdownCss, disabled, icon, placeholder } = props;
  const t: any = useI18NextContext();

  return (
    <Container data-testid={props.testId || TestId.formikDropdownField.main} css={css}>
      <Collapse in={!!label}>
        <FieldLabel isError={!!error}>{t(label)}</FieldLabel>
      </Collapse>
      <Dropdown
        placeholder={placeholder}
        css={dropdownCss}
        name={name}
        options={options}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        isError={!!error}
        disabled={disabled}
        icon={icon}
      />
      <Collapse in={!!error}>
        <ErrorLabel>{t(error)}</ErrorLabel>
      </Collapse>
    </Container>
  );
};

FormikDropdownFieldComponent.defaultProps = {
  error: ''
};

export const FormikDropdownField = withTheme(FormikDropdownFieldComponent);
