import { Snackbar } from '@components/common/snackbar';
import { useCountDown, useI18NextContext } from '@components/hooks';
import { State } from '@model/common';
import moment, { ISO_8601 } from 'moment';
import React, { useEffect } from 'react';

export interface ExpirationCountdownProps {
  className?: string;
  expires: string;
  isExpired: boolean;
  onExpired: () => void;
}

export const getStartTime = (expires: string) => {
  return moment.utc(expires, ISO_8601).local().diff(moment()).valueOf();
};

export const ExpirationCountdown = ({ className, expires, isExpired, onExpired }: ExpirationCountdownProps) => {
  const t = useI18NextContext();
  const [timeLeft, start] = useCountDown(getStartTime(expires));

  useEffect(() => {
    if (expires && moment(expires).isValid()) {
      const startTime: number = getStartTime(expires);
      if (startTime > 0) {
        start(getStartTime(expires));
      }
    }
  }, []);
  useEffect(() => {
    if (timeLeft <= 0) {
      onExpired();
    }
  }, [timeLeft]);

  const time: string = moment.duration(timeLeft).format('mm:ss');
  const text: string = isExpired ? 'token__expiration-message--panel-expired' : 'token__expiration-message--panel';
  return (
    <Snackbar
      className={className}
      state={isExpired ? State.ERROR : State.WARNING}
      text={t(text, { time })}
      icon={'mhi-fastest-time'}
    />
  );
};
