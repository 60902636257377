import { OnInit } from '@model/bootstrap/on-init';
import axios from 'axios';
import Cookies, { Cookie } from '@model/common/cookie/cookie';
import Router from 'next/router';

export class Sfsk implements OnInit {
  sfsk: string = '';
  initialize(): void {
    if (!this.sfsk) {
      const queryMatch = /sf_sk=(\w+)/.exec(Router.asPath);
      const querySfsk = queryMatch && queryMatch[1];
      const sfsk: string | undefined = querySfsk || new Cookies().get(Cookie.SFSK);
      if (sfsk) {
        this.set(sfsk);
      }
    }
  }
  set(sfsk: string): void {
    this.sfsk = sfsk;
    axios.interceptors.request.use(
      function (config) {
        config.headers['X-SFSK'] = sfsk;
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }
}
