import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Router from 'next/router';
import { Divider } from '@mui/material';
import styled from '@emotion/styled';
import { merge } from 'lodash';
import { DealFinderState } from '@model/state';
import { INITIAL_DEAL_FINDER_STATE } from '@state/deal-finder/dealFinderOperations';
import { setGuests } from '@state/guests/guestOperations';
import { getGuests } from '@state/guests/guestSelectors';
import { performNavigate } from '@state/app';
import { GuestFormValues } from '@model/forms';
import { Page } from '@model/common';
import { getSearchQuery } from '@util/deal-finder';
import { SearchType } from '@model/iceberg/deal-finder/deal-finder';
import { ThemeProps } from '@theme/base';

export interface SearchListProps {
  onClose: () => void;
}

const searches: Array<SearchItem> = [
  {
    title: 'Short Haul - Malta',
    payload: {
      ...INITIAL_DEAL_FINDER_STATE,
      destinations: [
        {
          path: 'europe/malta-and-gozo/malta',
          display: 'Malta',
          airports: [
            'ABZ',
            'BFS',
            'BHD',
            'BHX',
            'BOH',
            'BRS',
            'CWL',
            'EDI',
            'EMA',
            'EXT',
            'GLA',
            'LBA',
            'LHR',
            'LPL',
            'LTN',
            'MAN',
            'NCL',
            'NWI',
            'PIK',
            'SEN',
            'STN',
            'LGW'
          ]
        }
      ],
      month: '2022-08-01',
      airports: ['LHR', 'LGW'],
      errors: {
        airports: false,
        dates: false,
        occupancy: false,
        destinations: false
      }
    }
  },
  {
    title: 'Short Haul - Cyprus',
    payload: {
      ...INITIAL_DEAL_FINDER_STATE,
      destinations: [
        {
          path: 'europe/cyprus',
          display: 'Cyprus',
          airports: [
            'BFS',
            'BFS',
            'BHD',
            'BHD',
            'BHX',
            'BHX',
            'BOH',
            'BRS',
            'BRS',
            'CWL',
            'CWL',
            'DSA',
            'DSA',
            'EDI',
            'EDI',
            'EMA',
            'EMA',
            'EXT',
            'EXT',
            'GLA',
            'GLA',
            'LBA',
            'LBA',
            'LHR',
            'LPL',
            'LPL',
            'LTN',
            'LTN',
            'MAN',
            'MAN',
            'NCL',
            'NCL',
            'NWI',
            'STN',
            'STN',
            'LGW',
            'LGW'
          ]
        }
      ],
      month: '2022-09-10',
      airports: ['LHR', 'LGW'],
      errors: {
        airports: false,
        dates: false,
        occupancy: false,
        destinations: false
      }
    }
  },
  {
    title: 'Long Haul - Maldives',
    payload: {
      ...INITIAL_DEAL_FINDER_STATE,
      destinations: [
        {
          path: 'indian-ocean/mauritius',
          display: 'Mauritius',
          airports: [
            'ABZ',
            'BFS',
            'BHD',
            'BHX',
            'BRS',
            'CWL',
            'EDI',
            'EMA',
            'EXT',
            'GLA',
            'LBA',
            'LHR',
            'MAN',
            'NCL',
            'NQY',
            'NWI',
            'SOU',
            'STN',
            'LGW'
          ]
        }
      ],
      month: '2021-08',
      airports: ['LHR', 'LGW', 'STN'],
      duration: 14,
      errors: {
        airports: false,
        dates: false,
        occupancy: false,
        destinations: false
      }
    }
  }
];

const productSearches: Array<SearchItem> = [
  {
    title: 'Bella Vista Hotel',
    payload: {
      ...INITIAL_DEAL_FINDER_STATE,
      destinations: [
        {
          path: 'europe/malta-and-gozo/malta/qawra/bella-vista-hotel',
          display: 'Bella Vista Hotel',
          airports: [
            'ABZ',
            'BFS',
            'BHD',
            'BHX',
            'BOH',
            'BRS',
            'CWL',
            'EDI',
            'EMA',
            'EXT',
            'GLA',
            'LBA',
            'LHR',
            'LPL',
            'LTN',
            'MAN',
            'NCL',
            'NWI',
            'PIK',
            'SEN',
            'STN',
            'LGW'
          ]
        }
      ],
      month: '2021-08',
      airports: ['LHR', 'LGW', 'STN'],
      duration: 14,
      searchType: SearchType.MAIN,
      hotelLandingSearch: true,
      errors: {
        airports: false,
        dates: false,
        occupancy: false,
        destinations: false
      }
    }
  }
];

const guestDetails: Array<GuestItem> = [
  {
    title: 'The McTests',
    payload: [
      {
        // type: 'Adult',
        email: 'test@mercury.com',
        contactByEmail: true,
        telephone: '0123456789',
        firstNames: 'Testy',
        lastName: 'McTest',
        title: 'Mr',
        lead: true,
        dob: {
          yearOfBirth: '1970',
          monthOfBirth: '01',
          dayOfBirth: '01'
        }
      },
      {
        // type: 'Adult',
        firstNames: 'Testia',
        lastName: 'McTest',
        title: 'Mrs',
        lead: false,
        dob: {
          yearOfBirth: '1970',
          monthOfBirth: '01',
          dayOfBirth: '01'
        }
      }
    ]
  }
];
interface SearchItem {
  title: string;
  payload: DealFinderState;
}

interface GuestItem {
  title: string;
  payload: Array<Partial<GuestFormValues>>;
}

const Title = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h6 as any),
  fontWeight: 'bold',
  textAlign: 'center'
}));

export const SearchList = ({ onClose }: SearchListProps) => {
  const dispatch = useDispatch();
  const guests: Array<GuestFormValues> = useSelector(getGuests);

  const handleSearch = (payload: DealFinderState) => {
    const query = {
      ...getSearchQuery(payload)
    };
    dispatch(performNavigate({ pathname: Page.SEARCH, query }));
    onClose();
  };
  const handleProductSearch = (payload: DealFinderState) => {
    const query = {
      ...getSearchQuery(payload)
    };
    dispatch(performNavigate({ pathname: Page.SEARCH, query }));
    onClose();
  };

  const handleGuests = (payload: any) => {
    const updated = merge(guests, payload);
    const searchToken = Router.query?.searchToken;
    if (searchToken && !Array.isArray(searchToken)) {
      dispatch(setGuests(searchToken, updated));
    }
    onClose();
  };
  return (
    <>
      <Title>Search</Title>
      <List>
        {searches.map((search: SearchItem) => (
          <ListItem key={search.title} button onClick={() => handleSearch(search.payload)}>
            <ListItemText primary={search.title} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <Title>Product Search</Title>
      <List>
        {productSearches.map((search: SearchItem) => (
          <ListItem key={search.title} button onClick={() => handleProductSearch(search.payload)}>
            <ListItemText primary={search.title} />
          </ListItem>
        ))}
      </List>
      <Title>Guests</Title>
      <List>
        {guestDetails.map((guestDetails: any) => (
          <ListItem key={guestDetails.title} button onClick={() => handleGuests(guestDetails.payload)}>
            <ListItemText primary={guestDetails.title} />
          </ListItem>
        ))}
      </List>
    </>
  );
};
