import { Icon } from '@components/common/generic-icon';
import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';
import { ModalContainer, CentredContainer as BaseContainer, ModalContentText as Text } from '@styles/modal';
import React from 'react';
import { BaseModal } from '@components/common/modal';
import { withTheme } from '@emotion/react';
import { ThemeProps } from '@theme/base';

export interface AlertModalProps {
  icon: string;
  title: string;
  body: any;
  renderBottom: React.ReactNode;
  iconColor?: string;
  code?: string;
}

const Container: any = styled(BaseContainer)({
  [mq.small]: {
    width: 400
  }
});

interface ModalIconProps extends ThemeProps {
  color?: string;
}
const ModalIcon: any = styled(Icon)(({ theme, color }: ModalIconProps) => ({
  fontSize: '10rem',
  color: color ? color : theme.custom.colors.group10.darker,
  marginBottom: theme.custom.spacing.large
}));

const Title: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h4 as any),
  marginBottom: theme.custom.spacing.large,
  textAlign: 'center'
}));

const Code: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.small as any),
  marginBottom: theme.custom.spacing.large,
  textTransform: 'uppercase'
}));

export const AlertModalComponent = ({ icon, body, title, code, iconColor, renderBottom }: AlertModalProps) => {
  const t: any = useI18NextContext();
  return (
    <BaseModal open={true} noBackdropDismiss hideCloseButton>
      <ModalContainer data-testid={TestId.modal.alert.container}>
        <Container>
          <ModalIcon name={icon} color={iconColor} />
          <Title data-testid={TestId.modal.alert.title}>{t(title)}</Title>
          {code && <Code>{t('error-modal__code--label', { code })}</Code>}
          <Text data-testid={TestId.modal.alert.text}>{t(body)}</Text>
          {renderBottom}
        </Container>
      </ModalContainer>
    </BaseModal>
  );
};

export const AlertModal = withTheme(AlertModalComponent);
