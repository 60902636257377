import React from 'react';
import { AlertModal } from '@components/common/modal/alert/AlertModal';
import { useI18NextContext } from '@components/hooks';
import { Icons } from '@model/common/icons';
import { Button, ButtonColor } from '@components/material-ui';
import styled from '@emotion/styled';

export interface ExpirationModalProps {
  onClick: () => void;
}

const StyledButton: any = styled(Button)({
  width: '100%'
});

export const ExpirationModal = ({ onClick }: ExpirationModalProps) => {
  const t: any = useI18NextContext();
  return (
    <AlertModal
      icon={Icons.FASTEST_TIME}
      title={'token__expiration-modal--title'}
      body={<div>{t('token__expiration-modal--text')}</div>}
      renderBottom={
        <StyledButton label={t('token__expiration-message--button')} onClick={onClick} color={ButtonColor.SECONDARY} />
      }
    />
  );
};
