import React from 'react';
import dynamic from 'next/dynamic';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, ModalType } from '@state/modal/modalOperations';
import { BaseModal } from '@components/common/modal';
import { getModalType, getModalData } from '@state/modal/modalSelectors';
import { getMainPhoneNumber } from '@state/cms';

const DynamicPrivateTourEnquiryModal = dynamic(
  () => import('./PrivateTourEnquiryModal').then((e) => e.PrivateTourEnquiryModal),
  {
    ssr: false
  }
);

export const PrivateTourEnquiryModalModalContainer = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(getModalType);
  const modalData = useSelector(getModalData);
  const handleOnDismiss = () => dispatch(closeModal());
  const mainPhoneNumber: string | undefined = useSelector(getMainPhoneNumber);

  return (
    <BaseModal
      open={modalType === ModalType.PRIVATE_TOUR_ENQUIRY}
      onDismiss={handleOnDismiss}
      hideCloseButton
      isScrollBodyModal
    >
      <DynamicPrivateTourEnquiryModal
        onDismiss={handleOnDismiss}
        modalData={modalData}
        mainPhoneNumber={mainPhoneNumber}
      />
    </BaseModal>
  );
};
