import styled from '@emotion/styled';
import { mixins } from './mixins';
import { mq } from './breakpoints';
import { Grid } from '@mui/material';
import { PrimarySpinner } from '@components/common/spinner';
import { ThemeProps, ErrorThemeProps, CssThemeProps } from '@theme/base';

export const FormRowGrid = styled(Grid)({
  ['&.MuiGrid-container']: {
    marginTop: 0
  }
});

export const FieldLabel: any = styled.label(({ theme, isError }: ErrorThemeProps) => ({
  display: 'block',
  marginBottom: 10,
  fontWeight: 'bold',
  ...(theme.custom.typography.labels.default as any),
  ...(isError && {
    color: theme.custom.states.error.dark
  }),
  ...mixins(theme).truncate
}));

export const FieldSubLabel = styled.span(({ theme }: ThemeProps) => ({
  paddingLeft: theme.custom.spacing.xSmall * 2,
  ...(theme.custom.typography.labels.attribute as any),
  color: theme.custom.colors.group10.dark
}));

export const TextInput: any = styled.input(({ theme, isError }: ErrorThemeProps) => ({
  ...mixins(theme).formFieldBorder,
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 500,
  transition: `border-color ${theme.custom.transitions.default}`,
  width: '100%',
  height: 40,
  padding: 10,
  ...(isError && {
    borderColor: theme.custom.states.error.dark
  }),
  ['&:focus, &:hover']: {
    borderColor: theme.custom.colors.group7.base
  }
}));

interface ActiveProps extends ErrorThemeProps {
  isActive: boolean;
}
export const DatePickerField: any = styled.div(({ theme, isError, isActive }: ActiveProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  ...mixins(theme).formFieldBorder,
  ...(theme.custom.typography.paragraph as any),
  transition: `border-color ${theme.custom.transitions.default}`,
  cursor: 'pointer',
  width: '100%',
  height: 40,
  ...(isError && {
    borderColor: theme.custom.states.error.dark
  }),

  ...(isActive && {
    borderColor: theme.custom.colors.group7.base
  }),

  ['&:hover']: {
    borderColor: theme.custom.colors.group7.base,

    ['i']: {
      color: theme.custom.colors.group7.base
    }
  },

  ['i']: {
    marginLeft: theme.custom.spacing.medium,
    fontSize: '2rem',
    color: isActive ? theme.custom.colors.group7.base : theme.custom.colors.group10.base,

    ['& + div']: {
      paddingLeft: theme.custom.spacing.medium
    },

    ['&.dropdown-chevron']: {
      marginRight: theme.custom.spacing.medium,
      fontSize: '1rem'
    }
  }
}));

export const ErrorLabel = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.attribute as any),
  color: theme.custom.states.error.dark,
  marginTop: 3
}));

export const titleDropdownStyles = {
  maxWidth: 100,

  [mq.small]: {
    maxWidth: 'initial'
  }
};

export const DateDayMonthField = styled(TextInput)({
  width: 50
});

export const DateYearField = styled(TextInput)({
  width: 80
});

export const DateFieldsWrapper = styled.div({
  display: 'flex'
});

export const DateField = styled.div(({ theme }: ThemeProps) => ({
  marginRight: theme.custom.spacing.large
}));

export const DateFieldLabel: any = styled.div(({ theme, isError }: ErrorThemeProps) => ({
  ...(theme.custom.typography.labels.attribute as any),
  color: isError ? theme.custom.states.error.dark : theme.custom.colors.group4.light
}));

export const FormSectionSeparator = styled.div(({ theme }: ThemeProps) => ({
  height: theme.custom.spacing.xxLarge,
  borderBottom: `1px dashed ${theme.custom.colors.group10.base}`,
  marginBottom: theme.custom.spacing.xxLarge
}));

export const FromSectionTitle = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  marginTop: 0
}));

interface FormSearchFieldContainer extends ErrorThemeProps, CssThemeProps {}
export const FormSearchFieldContainer: any = styled.div(({ theme, css, isError }: FormSearchFieldContainer) => ({
  ['.search-input-wrapper']: {
    ...mixins(theme).formFieldBorder,
    transition: `border-color ${theme.custom.transitions.default}`,

    ['input, h4, [class*="placeholder"], [class*="singleValue"]']: {
      ...(theme.custom.typography.labels.default as any),
      fontWeight: 500
    },

    ['&.active, &:hover']: {
      borderColor: theme.custom.colors.group7.base
    },

    ...(isError && {
      borderColor: theme.custom.states.error.dark
    })
  },

  ...css
}));

export const FormContentContainer: any = styled.div();

export const FormSpinner = styled(PrimarySpinner)({
  position: 'absolute',
  top: '50%',
  marginTop: -50,
  left: '50%',
  marginLeft: -50
});

export const FormSubmitConfirmation: any = styled.div(({ theme }: ThemeProps) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  width: '100%',
  top: theme.custom.spacing.xLarge,
  left: 0
}));

export const isValidFormNumber = (value: string) => {
  const reg = new RegExp('^\\d+$');
  return value === '' || value.match(reg);
};
