import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import { TRANSITION_DURATION, useToggleShow } from '@components/hooks';
import { OpeningTimesTable } from './opening-times-table/OpeningTimesTable';
import { css } from '@emotion/react';
import { mq } from '@styles/breakpoints';
import { ZIndex } from '@styles/z-index';
import { OpeningTimes as OpenTimesCMS, ContactDetails, Entry } from '@model/contentful';

/* ***************** *
 *       Types       *
 * ***************** */
export interface OpeningTimesProps {
  theme?: Theme;
  testId?: string;
  show: boolean;
  openingTimes: Entry<OpenTimesCMS>;
  contactDetails: Entry<ContactDetails>;
}

/* ***************** *
 *       Styles      *
 * ***************** */
interface ContainerProps extends ThemeProps {
  show?: boolean;
}
const Container: any = styled.div(({ theme, show }: ContainerProps) => ({
  position: 'absolute',
  right: 0,
  marginLeft: -theme.custom.spacing.xxLarge,
  transition: `opacity ${TRANSITION_DURATION / 1000}s linear`,
  opacity: show ? 1 : 0,
  zIndex: ZIndex.NAVIGATION
}));

const InnerContainer: any = styled.div(({ show }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  transition: `transform ${TRANSITION_DURATION / 1000}s ease-in-out`,
  transform: `translateY(${show ? 0 : 30}px)`,
  zIndex: ZIndex.NAVIGATION
}));

const OpeningTimesWrapper: any = styled.div(({ theme }: ThemeProps) => ({
  borderRadius: 4,
  padding: theme.custom.spacing.medium,
  backgroundColor: theme.custom.colors.overlay.darker,
  width: 300,

  [mq.ie()]: {
    marginTop: -1
  }
}));

const openingTimesStyles = (theme: Theme) =>
  css({
    [mq.small]: {
      ...(theme.custom.typography.paragraph as any),
      marginTop: theme.custom.spacing.medium,
      display: 'flex',
      justifyContent: 'space-between'
    }
  } as any);

const ArrowPointer: any = styled.img({
  display: 'block',
  alignSelf: 'center',
  opacity: 0.8,
  marginLeft: 135,
  height: 14
});

const Disclaimer = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.attribute as any),
  color: theme.custom.colors.white,
  fontWeight: 'normal',
  whiteSpace: 'break-spaces'
}));

/* *************************** *
 *    OpeningTimesComponent    *
 * *************************** */

export const OpeningTimesComponent = ({ theme, show, testId, openingTimes, contactDetails }: OpeningTimesProps) => {
  const { openingTimesDays, openingTimesHours, openingTimesTitle } = openingTimes.fields;
  const { callCostDesclaimer } = contactDetails.fields;
  const days = openingTimesDays.split(',');
  const hours = openingTimesHours.split(',');

  const { shouldRender, shouldShow } = useToggleShow(show);

  return (
    <Container data-testid={testId || TestId.navBar.openingTimes} show={shouldShow}>
      {shouldRender && (
        <InnerContainer show={shouldShow}>
          <ArrowPointer src={'/assets/images/img_desktop_nav_arrow.svg'} alt={'Navigation Arrow'} />
          <OpeningTimesWrapper>
            <OpeningTimesTable
              title={openingTimesTitle}
              days={days}
              hours={hours}
              contentCss={openingTimesStyles(theme!)}
            />
            <Disclaimer>{callCostDesclaimer}</Disclaimer>
          </OpeningTimesWrapper>
        </InnerContainer>
      )}
    </Container>
  );
};

export const OpeningTimes = withTheme(OpeningTimesComponent);
