import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { components } from 'react-select';

const GroupWrapper: any = styled.div({
  '> div': {
    paddingTop: 0,
    paddingBottom: 0
  }
});

export const GroupComponent = (props: any) => {
  const { Group } = components;
  return (
    <GroupWrapper>
      <Group {...props} />
    </GroupWrapper>
  );
};

export const Group = withTheme(GroupComponent);
