import styled from '@emotion/styled';
import React, { useState } from 'react';
import { isNull } from 'lodash';
import { TestId } from '@components/test-ids';
import { ThemeProps } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { ZIndex } from '@styles/z-index';
import { Button, ButtonVariant, ButtonColor, ButtonSize } from '@components/material-ui';
import { getHTML } from '@util/common';
import { mq } from '@styles/breakpoints';
import { useDispatch } from 'react-redux';
import { DataLayerEvent, sendDataLayer } from '../../../middleware/data-layer-middleware';
import { Uuid } from '@model/bootstrap/uuid-bootstrap';
import { withIsServer } from '@components/hoc';
import { EnvConfig } from '@model/config/env-config';
import { Environment } from '@model/config/environment';
import Cookies, { Cookie } from '@model/common/cookie/cookie';
import moment from 'moment';

/* ***************** *
 *       Types       *
 * ***************** */
export interface CookiesBannerProps {
  testId?: string;
  cookiesAccepted: boolean | null;
  isBookingPage: boolean;
  isServer: boolean;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const Container: any = styled.div(({ theme }: ThemeProps) => ({
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.custom.spacing.large,
  backgroundColor: theme.custom.colors.group2.darker,
  zIndex: ZIndex.COOKIES_BANNER
}));

const BannerWrapper: any = styled.div(({ theme }: ThemeProps) => ({
  width: '100%',
  maxWidth: theme.custom.contentSizes.maxContentWidth,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',

  [mq.small]: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

const BannerContentWrapper: any = styled.div(({ theme }: ThemeProps) => ({
  color: theme.custom.colors.white
}));

const BannerButtonWrapper: any = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  marginTop: theme.custom.spacing.small,
  columnGap: theme.custom.spacing.small,
  width: '100%',
  [mq.small]: {
    flexDirection: 'row',
    width: 'auto'
  }
}));

const BannerCTA: any = styled(Button)(({ theme }: ThemeProps) => ({
  flexGrow: 1,
  minWidth: theme.custom.spacing.xxxLarge,
  [mq.small]: {
    flexGrow: 0
  }
}));

const RejectBannerCTA: any = styled(BannerCTA)({
  opacity: 0.7
});

const BannerHeading: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  margin: 0,
  marginBottom: theme.custom.spacing.xSmall
}));

const BannerText: any = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  textAlign: 'left',

  [mq.small]: {
    maxWidth: '70%'
  },

  ['a']: {
    color: theme.custom.colors.white,
    textDecoration: 'underline',
    transition: `color ${theme.custom.transitions.default}`,

    ['&:hover']: {
      color: theme.custom.colors.group9.base
    }
  }
}));

/* ******************* *
 *    CookiesBanner    *
 * ******************* */

export const CookiesBanner = withIsServer(
  ({ testId, cookiesAccepted, isBookingPage, isServer }: CookiesBannerProps) => {
    const t: any = useI18NextContext();
    const dispatch = useDispatch();
    const [hideBanner, setHideBanner] = useState(false);

    /* *** LOGIC *** */
    const sendCookiesAcceptedDataLayer = (userAccept: number) => {
      const uuid = new Uuid().getUuid();
      dispatch(sendDataLayer({ userAccept, uuid }, DataLayerEvent.COOKIES_ACCEPTED));
      new Cookies().set({
        id: Cookie.COOKIES_ACCEPTED,
        value: `${userAccept}`,
        options: {
          path: '/',
          expires: moment().add(2, 'years').toDate(),
          sameSite: 'strict',
          secure: true
        }
      });

      setHideBanner(true);
    };
    const handleAcceptCookies = () => {
      sendCookiesAcceptedDataLayer(1);
    };
    const handleRejectCookies = () => {
      sendCookiesAcceptedDataLayer(0);
    };
    const handleTextClick = (e) => {
      const target = e.target;
      if (target.nodeName === 'A' && target.getAttribute('name')) {
        handleAcceptCookies();
      }
    };
    if (
      hideBanner ||
      !isNull(cookiesAccepted) ||
      isBookingPage ||
      isServer ||
      EnvConfig.get().APP_ENV === Environment.LOCAL
    ) {
      return null;
    }

    return (
      <Container data-testid={testId || TestId.cookiesBanner.main}>
        <BannerWrapper>
          <BannerContentWrapper>
            <BannerHeading>{t('cookies__banner--heading')}</BannerHeading>
            <BannerText dangerouslySetInnerHTML={getHTML(t('cookies__banner--content'))} onClick={handleTextClick} />
          </BannerContentWrapper>
          <BannerButtonWrapper>
            <RejectBannerCTA
              variant={ButtonVariant.OUTLINED}
              color={ButtonColor.QUATERNARY}
              size={ButtonSize.REGULAR}
              label={'common__label--reject'}
              onClick={handleRejectCookies}
            />
            <BannerCTA
              variant={ButtonVariant.OUTLINED}
              color={ButtonColor.QUATERNARY}
              size={ButtonSize.REGULAR}
              label={'common__label--accept'}
              onClick={handleAcceptCookies}
            />
          </BannerButtonWrapper>
        </BannerWrapper>
      </Container>
    );
  }
);
