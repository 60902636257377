import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Theme } from '@theme/base';
import { mq } from '@styles/breakpoints';
import { ModalContainer, Container as BaseContainer, Heading, Paragraph, ModalButton } from '@styles/modal';
import { useI18NextContext } from '@components/hooks';
import { ButtonVariant, ButtonColor, ButtonSize } from '@components/material-ui';
import { closeModal } from '@state/modal/modalOperations';
import { resetBookingError } from '@state/mmb/mmbOperations';
import { getHostForBrand } from '@model/config/brands';
import { Page } from '@model/common';

export interface MyBookingDomainErrorProps {
  tenantName: string;
}

const Container: any = styled(BaseContainer)(({ theme }: { theme: Theme }) => ({
  [mq.small]: {
    width: 580,
    padding: theme.custom.spacing.xLarge
  }
}));

export const MyBookingDomainError = ({ tenantName }: MyBookingDomainErrorProps) => {
  const dispatch = useDispatch();
  const t: any = useI18NextContext();
  const domainUrl = `${getHostForBrand(tenantName)}${Page.MY_BOOKING}`;

  const onDismiss = () => {
    dispatch(closeModal());
    dispatch(resetBookingError());
    window.location.href = domainUrl;
  };

  return (
    <ModalContainer>
      <Container>
        <Heading>{t('mmb-login-form-modal__title--label')}</Heading>
        <Paragraph>{t('mmb-login-form-modal__redirect-text', { url: domainUrl })}</Paragraph>
        <ModalButton
          variant={ButtonVariant.CONTAINED}
          color={ButtonColor.SECONDARY}
          size={ButtonSize.SMALL}
          onClick={onDismiss}
          label={t('mmb-login-form-modal__redirect-button--label')}
        />
      </Container>
    </ModalContainer>
  );
};
