import { ContentTypes } from '@model/navigation';
import { SelectedOptions, SetOptions } from './NavBar';
import { DesktopNavigationContentComponent, DesktopNavigationContentProps } from './DesktopNavigationContent';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { getAllCMSDestinationsSelector, getCMSHereToHelp, getCMSOffers } from '@state/cms';

export interface DesktopNavigationContentContainerProps {
  testId?: string;
  navContent: ContentTypes;
  selectedOptions: SelectedOptions;
  setOptions: SetOptions;
  onToggleDesktopNav?: () => void;
}

export const DesktopNavigationContentContainer = (ownProps: DesktopNavigationContentContainerProps) => {
  const props: DesktopNavigationContentProps = {
    theme: useTheme(),
    cmsDestinations: useSelector(getAllCMSDestinationsSelector),
    cmsHereToHelp: useSelector(getCMSHereToHelp),
    cmsOffers: useSelector(getCMSOffers),
    ...ownProps
  };

  return <DesktopNavigationContentComponent {...props} />;
};
