import React, { useState } from 'react';
import moment from 'moment';
import { isNumber } from 'lodash';
import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';
import { ThemeProps } from '@theme/base';
import {
  ModalContainer,
  Container,
  Heading,
  ModalContentText,
  CloseIcon,
  ModalContent,
  ButtonsContainer,
  CTAButton
} from '@styles/modal';
import { Amount, Currency, DAY_LONG_MONTH_FORMAT, TIME_FORMAT_LONG, Icons } from '@model/common';
import { EarlyFlightType } from '@model/flights/early-flight';
import { ButtonVariant, ButtonColor, ButtonSize } from '@components/material-ui';
import { useI18NextContext } from '@components/hooks';
import { Icon } from '@components/common/generic-icon';
import { Checkbox, CheckboxLabel, CheckboxResponse } from '@components/common/checkbox';
import { TestId } from '@components/test-ids';

export interface EarlyFlightsModalData {
  type: EarlyFlightType;
  arrival: string;
  checkIn: string;
  price?: number;
  perPerson?: number;
  currency?: Currency;
  searchToken: string;
  roomTokens: Array<string>;
  onDismiss: (cancelNavigation?: boolean) => void;
  onContinue: (searchToken: string, roomTokens: Array<string>) => void;
}

export interface EarlyFlightsModalProps {
  modalData: EarlyFlightsModalData;
}

export const WideContainer: any = styled(Container)({
  [mq.small]: {
    maxWidth: 640
  }
});

const ArrowIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '1.6rem',
  color: theme.custom.colors.white
}));

interface LabelProps extends ThemeProps {
  isError?: boolean;
}
const StyledLabel = styled(CheckboxLabel)(({ theme, isError }: LabelProps) => ({
  ...(isError && {
    color: theme.custom.states.error.dark
  })
}));

export const EarlyFlightsModal = ({ modalData }: EarlyFlightsModalProps) => {
  const t: any = useI18NextContext();
  const [readConfirmed, setReadConfirmed] = useState(false);
  const [readConfirmError, setReadConfirmError] = useState(false);

  const { type, arrival, checkIn, price, perPerson, currency, searchToken, roomTokens, onDismiss, onContinue } =
    modalData;

  const isAddNight = type === EarlyFlightType.ADD_NIGHT;
  const isRemoveNight = type === EarlyFlightType.SUB_NIGHT;
  const isUnavailable = type === EarlyFlightType.UNAVAILABLE;

  const formatPrice = (amount?: number) =>
    isNumber(amount) ? `${amount < 0 ? '' : '+'}${new Amount(Math.round(amount || 0), currency?.code).format()}` : '';

  const totalPrice = formatPrice(price);
  const perPersonPrice = formatPrice(perPerson);
  const arrivalMoment = moment.parseZone(arrival);
  const arrivalTime = arrivalMoment.format(TIME_FORMAT_LONG);
  const arrivalDate = arrivalMoment.format(DAY_LONG_MONTH_FORMAT);
  const checkInMoment = moment(checkIn);
  const checkInDate = checkInMoment.format(DAY_LONG_MONTH_FORMAT);
  const extraNight = checkInMoment.subtract(isRemoveNight ? 0 : 1, 'days').format(DAY_LONG_MONTH_FORMAT);

  const headerKey = isUnavailable ? 'early-flights__modal--unavailable-header' : 'early-flights__modal--header';
  const questionKey = isAddNight
    ? 'early-flights__modal--add-question'
    : isRemoveNight
      ? 'early-flights__modal--remove-question'
      : 'early-flights__modal--unavailable-question';
  const continueButtonKey = isAddNight
    ? 'early-flights__modal--add-button'
    : isRemoveNight
      ? 'early-flights__modal--remove-button'
      : 'common__label--continue';
  const skipButtonKey = isUnavailable
    ? 'review__flights--change-flights-button-label'
    : 'early-flights__modal--skip-button';

  const handleChange: (checkboxData: CheckboxResponse) => any = (checkboxData: CheckboxResponse) => {
    setReadConfirmed(checkboxData.checked);
    if (checkboxData.checked && readConfirmError) {
      setReadConfirmError(false);
    }
  };
  const handleContinueUnavailable = () => {
    if (!readConfirmed) {
      setReadConfirmError(true);
    } else {
      onDismiss();
    }
  };

  return (
    <ModalContainer data-testid={TestId.modal.earlyFlightsModal}>
      <WideContainer>
        <Heading>
          {t(headerKey)}
          <CloseIcon name={'mhi-clear'} onClick={onDismiss} />
        </Heading>
        <ModalContent>
          <ModalContentText>
            {t('early-flights__modal--intro', { arrivalTime, arrivalDate, checkInDate })}
          </ModalContentText>
          <ModalContentText>
            {t(questionKey, {
              extraNight,
              totalPrice,
              perPersonPrice
            })}
            {isUnavailable && <strong>{t('early-flights__modal--unavailable-warning')}</strong>}
          </ModalContentText>
          {isUnavailable && (
            <ModalContentText>
              <Checkbox
                value={'readConfirmed'}
                labelComponent={
                  <StyledLabel isError={readConfirmError}>{t('early-flights__modal--unavailable-confirm')}</StyledLabel>
                }
                checked={readConfirmed}
                onChange={handleChange}
              />
            </ModalContentText>
          )}
        </ModalContent>
        <ButtonsContainer>
          <CTAButton
            variant={ButtonVariant.CONTAINED}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.REGULAR}
            label={t(continueButtonKey, { extraNight, perPersonPrice })}
            onClick={isUnavailable ? handleContinueUnavailable : () => onContinue(searchToken, roomTokens)}
            endIcon={isUnavailable ? <ArrowIcon name={Icons.ARROW_RIGHT} /> : undefined}
          />
          <CTAButton
            variant={ButtonVariant.OUTLINED}
            color={ButtonColor.SECONDARY}
            size={ButtonSize.REGULAR}
            label={t(skipButtonKey)}
            onClick={() => onDismiss(isUnavailable)}
          />
        </ButtonsContainer>
      </WideContainer>
    </ModalContainer>
  );
};
