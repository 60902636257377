import styled from '@emotion/styled';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Tabs as MUITabs } from '@mui/material';
import { ThemeProps } from '@theme/base';

export const MHSelect: any = styled(Select)(({ theme }: ThemeProps) => ({
  ['&.MuiInputBase-root']: {
    width: '100%',

    ['&:hover']: {
      ['.MuiSelect-icon']: {
        color: theme.custom.colors.group7.base
      }
    }
  },

  ['.MuiSelect-select']: {
    display: 'flex',
    alignItems: 'center',
    ...(theme.custom.typography.paragraph as any),
    padding: `12px ${theme.custom.spacing.medium}px`,

    ['&:focus']: {
      backgroundColor: 'transparent'
    }
  },
  ['&.MuiInput-underline']: {
    ['&::before, &::after']: {
      borderBottom: 'none'
    }
  },
  ['&.MuiInput-underline:hover:not(.Mui-disabled)::before']: {
    borderBottom: 'none'
  },
  ['.MuiSelect-icon']: {
    fontSize: '2.4rem',
    color: theme.custom.colors.group10.base,
    transition: `color ${theme.custom.transitions.default}`
  },
  ['.MuiMenuItem-root']: {
    ...(theme.custom.typography.paragraph as any)
  },
  ['.MuiOutlinedInput-notchedOutline']: {
    border: `2px solid ${theme.custom.colors.group10.light}`,
    borderRadius: 0,
    transition: `border ${theme.custom.transitions.default}`
  },
  ['&:hover']: {
    ['.MuiOutlinedInput-notchedOutline']: {
      borderColor: theme.custom.colors.group7.base
    }
  }
}));

export const MHMenuItem: any = styled(MenuItem)(({ theme }: ThemeProps) => ({
  ['&.MuiMenuItem-root']: {
    ...(theme.custom.typography.paragraph as any),
    transition: `color ${theme.custom.transitions.default}`,
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    padding: `${theme.custom.spacing.xSmall}px ${theme.custom.spacing.medium}px`,

    ['&.Mui-selected']: {
      backgroundColor: 'transparent',
      color: theme.custom.colors.group5.base,
      display: 'flex',
      justifyContent: 'space-between',

      ['&:hover']: {
        color: theme.custom.colors.group5.base
      },

      ['&::after']: {
        content: '"\u2713"'
      }
    },

    ['&:hover']: {
      backgroundColor: 'transparent',
      color: theme.custom.colors.group7.base
    }
  }
}));

export const Tabs: any = styled(MUITabs)(({ theme }: ThemeProps) => ({
  ['.MuiTab-root']: {
    minHeight: theme.custom.contentSizes.tabNavHeight,
    color: theme.custom.colors.group4.base,
    opacity: 1,
    ...(theme.custom.typography.labels.hero as any),
    textTransform: 'none'
  },

  ['.Mui-selected']: {
    ['&.MuiTab-root']: {
      color: theme.custom.colors.group1.base
    }
  },

  ['.MuiTabs-indicator']: {
    backgroundColor: theme.custom.colors.group1.base,
    height: theme.custom.spacing.xSmall,
    borderRadius: 4
  }
}));
