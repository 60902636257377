import Link, { LinkProps } from 'next/link';
import { DestinationLinkBuilder } from '@model/iceberg/geography';
import React from 'react';
import { PlainLinkStyles } from '@styles/common';

export interface HotelsLinkProps {
  path: string;
  children?: JSX.Element;
}

export const HotelsLink = ({ path, children }: HotelsLinkProps) => {
  const linkBuilder: DestinationLinkBuilder = new DestinationLinkBuilder();
  const link: LinkProps = linkBuilder.getDestinationHotelsLink(path);
  return (
    <Link prefetch={false} {...link} passHref style={PlainLinkStyles}>
      {children}
    </Link>
  );
};
