import React from 'react';
import { MyBookingDomainError } from './MyBookingDomainError';
import { useDispatch, useSelector } from 'react-redux';
import { getModalData, getModalType } from '@state/modal/modalSelectors';
import { closeModal, ModalType } from '@state/modal/modalOperations';
import { resetBookingError } from '@state/mmb/mmbOperations';
import { BaseModal } from '@components/common/modal';

export interface MyBookingDomainError {
  tenantName: string;
}

export const MyBookingDomainErrorContainer = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(getModalType);
  const modalData: MyBookingDomainError = useSelector(getModalData) as MyBookingDomainError;

  const handleOnDismiss = () => {
    dispatch(closeModal());
    dispatch(resetBookingError());
  };

  return (
    <BaseModal open={modalType === ModalType.MMB_BOOKING_DOMAIN_ERROR} onDismiss={handleOnDismiss} hideCloseButton>
      <MyBookingDomainError tenantName={modalData.tenantName} />
    </BaseModal>
  );
};
