import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '@state/modal/modalOperations';
import { GuestFormValues } from '@model/forms';
import { getGuests } from '@state/guests/guestSelectors';
import { GuestPageFormError } from '@components/modal/form-error/guest-page-form-error/GuestPageFormError';
import { getIsAgent } from '@state/agent/agentSelectors';

export const GuestPageFormErrorContainer = () => {
  const dispatch = useDispatch();
  const guests: Array<GuestFormValues> = useSelector(getGuests);
  const isAgent: boolean = useSelector(getIsAgent);
  const handleOnClick = () => dispatch(closeModal());
  return <GuestPageFormError guests={guests} isAgent={isAgent} onClick={handleOnClick} />;
};
