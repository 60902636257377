import dynamic from 'next/dynamic';
import { ComponentTypes, CMSDestinations, CMSTripType, CMSDestination, TripTypes, Entry } from '@model/contentful';
import { sortByField } from './common';
import { SortDirection } from '@model/common';

const DynamicBenefits = dynamic(() => import('../components/benefits/row/BenefitsRow').then((e) => e.BenefitsRow), {
  ssr: false
});
const DynamicDestinationsGroup = dynamic(
  () => import('../components/destination/DestinationsGroup').then((e) => e.DestinationsGroup),
  {
    ssr: false
  }
);
const DynamicCMSFooter = dynamic(() => import('../components/footer/Footer').then((e) => e.Footer), {
  ssr: false
});
const DynamicExpandableListsGroup = dynamic(
  () => import('../components/expandable-list-group/ExpandableListsGroup').then((e) => e.ExpandableListsGroup),
  {
    ssr: false
  }
);
const DynamicBenefitsSidebar = dynamic(
  () => import('../components/benefits/sidebar/BenefitsSidebar').then((e) => e.BenefitsSidebar),
  { ssr: false }
);
const DynamicHelpTileGroup = dynamic(() => import('../components/help/HelpTileGroup').then((e) => e.HelpTileGroup), {
  ssr: false
});
const DynamicInfoRowGroup = dynamic(() => import('../components/help/InfoRowGroup').then((e) => e.InfoRowGroup), {
  ssr: false
});
const DynamicContactDetails = dynamic(() => import('../components/help/ContactDetails').then((e) => e.ContactDetails), {
  ssr: false
});
const DynamicTourProductGroup = dynamic(
  () => import('../components/product/tour-product-tile/TourProductGroup').then((e) => e.TourProductGroup),
  { ssr: false }
);
const DynamicArticle = dynamic(() => import('../components/common/article/Article').then((e) => e.Article), {
  ssr: false
});
const DynamicLinkCardContainer = dynamic(
  () => import('../components/common/link-card/LinkCardContainer').then((e) => e.LinkCardContainer),
  {
    ssr: false
  }
);
const DynamicCMSDynamicComponent = dynamic(
  () => import('../components/cms/CMSDynamicComponent').then((e) => e.CMSDynamicComponent),
  {
    ssr: false
  }
);
const DynamicTeamMemberComponent = dynamic(
  () => import('../components/agents/team-member/TeamMember').then((e) => e.TeamMemberComponent),
  {
    ssr: false
  }
);
const DynamicWindowPostersComponent = dynamic(
  () => import('../components/agents/window-posters/WindowPosters').then((e) => e.WindowPostersComponent),
  {
    ssr: false
  }
);
const DynamicCMSProductPackageGroup = dynamic(
  () => import('../components/product/product-package/CMSProductPackageGroup').then((e) => e.CMSProductPackageGroup),
  {
    ssr: false
  }
);
const DynamicCMSVideo = dynamic(() => import('../components/common/video/Video').then((e) => e.CMSVideo), {
  ssr: false
});
const DynamicCallToBook = dynamic(() => import('../components/call-to-book/CallToBook').then((e) => e.CallToBook), {
  ssr: false
});

const CMSComponents = new Map([
  [ComponentTypes.benefitGroupComponent, { component: DynamicBenefits, isEdgeToEdge: true }],
  [ComponentTypes.destinationGroupComponent, { component: DynamicDestinationsGroup, isEdgeToEdge: false }],
  [ComponentTypes.footer, { component: DynamicCMSFooter, isEdgeToEdge: true }],
  [ComponentTypes.expandableListGroup, { component: DynamicExpandableListsGroup, isEdgeToEdge: true }],
  [ComponentTypes.uspSidebar, { component: DynamicBenefitsSidebar, isEdgeToEdge: true }],
  [ComponentTypes.infoTileGroupComponent, { component: DynamicHelpTileGroup, isEdgeToEdge: false }],
  [ComponentTypes.infoRowGroup, { component: DynamicInfoRowGroup, isEdgeToEdge: true }],
  [ComponentTypes.contactDetails, { component: DynamicContactDetails, isEdgeToEdge: true }],
  [ComponentTypes.toursProductTileGroupComponent, { component: DynamicTourProductGroup, isEdgeToEdge: false }],
  [ComponentTypes.article, { component: DynamicArticle, isEdgeToEdge: true }],
  [ComponentTypes.linkCard, { component: DynamicLinkCardContainer, isEdgeToEdge: true }],
  [ComponentTypes.genericDynamicComponent, { component: DynamicCMSDynamicComponent, isDynamic: true }],
  [ComponentTypes.teamMember, { component: DynamicTeamMemberComponent, isEdgeToEdge: true }],
  [ComponentTypes.windowPosters, { component: DynamicWindowPostersComponent, isEdgeToEdge: true }],
  [ComponentTypes.productTileGroupComponent, { component: DynamicCMSProductPackageGroup, isEdgeToEdge: false }],
  [ComponentTypes.video, { component: DynamicCMSVideo, isEdgeToEdge: false }],
  [ComponentTypes.callToBook, { component: DynamicCallToBook, isEdgeToEdge: false }]
] as any);

export const mapCMSComponent: (CMSComponent: ComponentTypes) => any = (CMSComponent: ComponentTypes) =>
  CMSComponents.get(CMSComponent);

export const getTripTypesFromDestinations = ({
  cmsDestinations,
  ignoreTripType
}: {
  cmsDestinations: CMSDestinations;
  ignoreTripType?: TripTypes;
}) =>
  sortByField(
    cmsDestinations
      .reduce((acc: Array<CMSTripType>, cmsDestination: CMSDestination) => {
        const tripTypes: Array<CMSTripType> = cmsDestination.tripTypes.reduce(
          (acc: Array<CMSTripType>, item: Entry<CMSTripType>) => {
            return [...acc, item.fields];
          },
          []
        );
        return [...acc, ...tripTypes];
      }, [])
      .filter(
        (tripType: CMSTripType, index: number, self: Array<CMSTripType>) =>
          index ===
          self.findIndex(
            (t: CMSTripType) =>
              t.tripTypeId === tripType.tripTypeId &&
              (!ignoreTripType || (ignoreTripType && t.tripTypeId !== ignoreTripType))
          )
      ),
    'order',
    SortDirection.ASC
  );

export const getDestinationsByTripType: (
  cmsDestinations: CMSDestinations,
  tripTypeFilter: TripTypes
) => CMSDestinations = (cmsDestinations: CMSDestinations, tripTypeFilter: TripTypes) =>
  cmsDestinations.filter((dest: CMSDestination) => {
    const destinationTripTypes = dest.tripTypes.map((tripType: Entry<CMSTripType>) => tripType.fields.tripTypeId);
    return tripTypeFilter === TripTypes.ALL ? destinationTripTypes : destinationTripTypes.includes(tripTypeFilter);
  });
