import React from 'react';
import styled from '@emotion/styled';
import { ZIndex } from '@styles/z-index';
import { mixins } from '@styles/mixins';
import { mq } from '@styles/breakpoints';
import { ThemeProps } from '@theme/base';
import { useI18NextContext, useToggleShow } from '@components/hooks';
import Link from 'next/link';
import { Page } from '@model/common';
import { AgentPage, agentPageLabels, navAgentPages } from '@model/common/pages/agent-pages';
import { TestId } from '@components/test-ids';

const TRANSITION_DURATION: number = 300;

const NavContainer: (props: any) => any = styled.div(({ showNav }: { showNav: boolean }) => ({
  flex: 'none',
  width: '100%',
  transition: `opacity ${TRANSITION_DURATION / 1000}s linear`,
  opacity: showNav ? 1 : 0,
  height: 0,
  zIndex: ZIndex.AGENT_NAV,
  pointerEvents: showNav ? 'all' : 'none'
}));

const ArrowPointerContainer = styled.div({
  position: 'relative'
});

const ArrowBackground = styled.div(({ theme }: ThemeProps) => ({
  position: 'absolute',
  borderBottom: `14px solid ${theme.custom.colors.group10.light}`,
  borderLeft: '14px solid transparent',
  borderRight: '14px solid transparent',
  height: 0,
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  width: 0,
  zIndex: ZIndex.AGENT_NAV + 1
}));

const ArrowBorder = styled.div(({ theme }: ThemeProps) => ({
  position: 'absolute',
  borderBottom: `14px solid ${theme.custom.colors.white}`,
  borderLeft: '14px solid transparent',
  borderRight: '14px solid transparent',
  height: 0,
  top: 1,
  left: '50%',
  transform: 'translateX(-50%)',
  width: 0,
  zIndex: ZIndex.AGENT_NAV + 1
}));

interface NavContentOverlayProps extends ThemeProps {
  showNav: boolean;
}

const NavContentOverlay: any = styled.div(({ theme, showNav }: NavContentOverlayProps) => ({
  background: theme.custom.colors.white,
  border: `1px solid ${theme.custom.colors.group10.light}`,
  position: 'absolute',
  textAlign: 'center',
  left: 0,
  right: 0,
  top: 50,
  transform: `translateY(${showNav ? 0 : 30}px)`,
  transition: `transform ${TRANSITION_DURATION / 1000}s ease-in-out`,
  minHeight: 500,
  ...mixins(theme).defaultDropShadow.boxShadow,
  zIndex: ZIndex.AGENT_NAV,
  // due to small rendering differences between brawsers
  // IE11 and FireFox require slightly different value
  [mq.ie()]: {
    transition: `opacity ${TRANSITION_DURATION / 1000}s linear, transform ${TRANSITION_DURATION / 1000}s ease-in-out`,
    opacity: showNav ? 1 : 0,
    zIndex: ZIndex.AGENT_NAV,
    top: 50
  },

  [mq.safari]: {
    top: 51
  },

  [mq.moz]: {
    top: 50.5
  }
}));

const NavContentContainer = styled.div(({ theme }: ThemeProps) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  textAlign: 'left',
  padding: theme.custom.spacing.medium,
  paddingTop: 0,
  paddingBottom: 0,
  height: '100%',
  maxWidth: theme.custom.contentSizes.maxContentWidth
}));

const NavHeading: any = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  marginTop: 10,

  ['span']: {
    ...(theme.custom.typography.paragraph as any)
  },

  ['a, a:visited']: {
    color: theme.custom.colors.group4.base,
    transition: `color ${theme.custom.transitions.default}`,
    textDecoration: 'none',

    ['&:hover']: {
      color: theme.custom.colors.group1.base
    }
  }
}));

interface LinksListContainerProps extends ThemeProps {
  displayInColumns: boolean;
}
const LinksListContainer: any = styled.ul(({ theme, displayInColumns }: LinksListContainerProps) => ({
  flex: 1,
  padding: 0,
  ...(displayInColumns && { columnCount: 4 }),
  paddingTop: theme.custom.spacing.xLarge,
  paddingBottom: theme.custom.spacing.xLarge,
  listStyleType: 'none',

  [`@media (max-width: ${theme.custom.contentSizes.maxContentWidth}px)`]: {
    marginRight: theme.custom.spacing.medium
  }
}));

const NavItem = styled.li(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.medium,
  cursor: 'pointer',
  ':hover': {
    color: theme.custom.colors.group1.base
  },
  ['a, a:visited, a:active']: {
    color: theme.custom.colors.group4.base,
    display: 'flex',
    alignItems: 'flex-start',
    ...(theme.custom.typography.paragraph as any),
    cursor: 'pointer',
    textDecoration: 'none',

    ['&:hover']: {
      color: theme.custom.colors.group1.base
    }
  }
}));

const MenuContainer = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 55
});

export interface AgentToolsProps {
  showMenu: boolean;
}

export const AgentTools = ({ showMenu }: AgentToolsProps) => {
  const { shouldShow } = useToggleShow(showMenu);
  const t: any = useI18NextContext();
  return (
    <NavContainer showNav={shouldShow} data-testid={TestId.agents.tools}>
      <ArrowPointerContainer>
        <ArrowBackground />
        <ArrowBorder />
      </ArrowPointerContainer>
      <NavContentOverlay showNav={shouldShow}>
        <NavContentContainer>
          <MenuContainer>
            <NavHeading>Agents Tools</NavHeading>
            <LinksListContainer displayInColumns={true}>
              <NavItem>
                <Link href={Page.AGENTS}>{t('agent__page--travel-agents-home')}</Link>
              </NavItem>
              {navAgentPages.map((page: AgentPage) => (
                <NavItem key={page}>
                  <Link href={Page.AGENTS.concat(page)}>{t(agentPageLabels[page])}</Link>
                </NavItem>
              ))}
            </LinksListContainer>
          </MenuContainer>
        </NavContentContainer>
      </NavContentOverlay>
    </NavContainer>
  );
};
