import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme } from '@theme/base';
import Link from 'next/link';
import { useI18NextContext } from '@components/hooks';
import { Page } from '@model/common/pages';
import { CMSOffer } from '@model/contentful/offers';
import { Entry } from '@model/contentful';
import { OfferLink } from '@components/common/next-link/offer-link';
import { NavContainer, NavHeading, NavItem, LinksListContainer } from './styles';

/* ***************** *
 *       Types       *
 * ***************** */
export interface OffersNavContentProps {
  theme?: Theme;
  testId?: string;
  offers: Array<Entry<CMSOffer>>;
  title: string;
  onToggleDesktopNav?: () => void;
}

/* *********************************** *
 *    OffersNavContentComponent    *
 * *********************************** */

export const OffersNavContentComponent = (props: OffersNavContentProps) => {
  const { onToggleDesktopNav, title, offers } = props;

  if (!offers) {
    return null;
  }

  const t: any = useI18NextContext();
  const renderLinks = () =>
    offers.map((offerTypeEntry: any) => (
      <NavItem onClick={onToggleDesktopNav} key={offerTypeEntry.fields.pageId}>
        <OfferLink path={Page.OFFERS.concat('/').concat(offerTypeEntry.fields.pageId)}>
          {offerTypeEntry.fields.title}
        </OfferLink>
      </NavItem>
    ));
  return (
    <NavContainer data-testid={props.testId || TestId.offersNavContent.main}>
      <NavHeading>
        {title}{' '}
        <span>
          (
          <Link prefetch={false} href={Page.OFFERS}>
            {t('nav__label--view-all')}
          </Link>
          )
        </span>
      </NavHeading>
      <LinksListContainer displayInColumns={offers.length > 4}>{renderLinks()}</LinksListContainer>
    </NavContainer>
  );
};

export const OffersNavContent = withTheme(OffersNavContentComponent);
