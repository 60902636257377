import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import { Page, SortDirection } from '@model/common';
import { mq } from '@styles/breakpoints';
import { sortByField } from '@util/common';
import React, { Fragment, useEffect, useState } from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import { Icon } from '../common/generic-icon';
import { useI18NextContext } from '@components/hooks';
import { NavigationRow, SelectedOptions, SetOptions } from './NavBar';
import { DestinationLink, HelpLink } from '@components/common/next-link';
import { ContentTypes } from '@model/navigation';
import { getNavConfig } from '@config/navigation';
import { CMSDestination, CMSDestinations } from '@model/contentful/destination';
import { Entry, InfoTile, TripTypes } from '@model/contentful';
import { getDestinationsByTripType, getTripTypesFromDestinations } from '@util/contentful';
import { CountryFlag } from '@components/common/country-flag/CountryFlag';
import { LogoContainer } from '@components/common/logo';
import { RTAP_CLASSNAME, clickToCallWrapper } from '@components/common/r-tap-number';
import { CMSOffer } from '@model/contentful/offers';
import { OfferLink } from '@components/common/next-link/offer-link';
import Link from 'next/link';
import { AgentPage, agentPageLabels, navAgentPages } from '@model/common/pages/agent-pages';
import Markdown from 'react-markdown';
import { LinkComponent } from '@components/common/description/TextMarkdown';
import { isPageBlacklisted, AppVariant } from '@model/config/brands';
import { DesktopNavItem, NavLabel } from '@components/common/navigation';
import { DesktopNavigation } from './DesktopNavigation';
import optionsProps from './optionsProps';

/* *** TYPES *** */
export interface MobileNavigationProps {
  theme?: Theme;
  testId?: string;
  open: boolean;
  onDismiss: () => void;
  cmsDestinations: CMSDestinations;
  contactDetails: any;
  infoTiles: Array<Entry<InfoTile>>;
  offerTypes: Array<Entry<CMSOffer>>;
  isAgent: boolean;
  onLogout: () => void;
}

export interface NavDestination {
  navItemIndex: number;
  levelChange: boolean;
}

interface Content {
  label: string | null;
  mobileContent: ContentTypes | null;
  heading: ContentTypes | null;
  tripType?: TripTypes;
}

/* ***************** *
 *       Styles      *
 * ***************** */

const StyledDrawer = styled(Drawer)({
  [mq.large]: {
    display: 'none'
  },
  '.MuiPaper-root': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    backdropFilter: 'blur(10px)',

    [mq.small]: {
      width: 'auto',
      minWidth: 300,
      maxWidth: 400
    }
  }
});

const LogoStyled = styled(LogoContainer)({
  position: 'initial',
  width: '95%',
  margin: 0,

  ['& > div']: {
    margin: '0 10px'
  }
});

const NavDivider: any = styled(Divider)(({ theme }: ThemeProps) => ({
  '&.MuiDivider-root': {
    border: 'none',
    borderBottom: `1px solid ${theme.custom.colors.group4.light}`,
    width: '100%',
    backgroundColor: theme.custom.colors.group4.darker,
    margin: 0
  }
}));

const NavRowLabel: any = styled(ListItemText)(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any)
}));

const NavRow: any = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: theme.custom.spacing.large,
  [`& > i::before`]: {
    color: theme.custom.colors.group4.base
  },

  [`&:hover > ${NavRowLabel} > span, &:hover > i::before`]: {
    color: theme.custom.colors.group1.base
  },

  ['.MuiListItemText-primary']: {
    fontSize: '1.8rem'
  }
}));

const NavRowTop: any = styled(NavRow)(({ theme }: ThemeProps) => ({
  backgroundColor: theme.custom.colors.group2.dark,
  display: 'flex',
  alignItems: 'center',
  padding: 20,
  justifyContent: 'space-between',
  a: {
    i: {
      borderRadius: '50%',
      padding: theme.custom.spacing.small,
      ['&:hover']: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    }
  }
}));

const ContentContainer = styled.div(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.large,
  paddingTop: 0,
  flex: 1,
  overflowY: 'scroll',
  WebkitOverflowScrolling: 'touch'
}));

const NavElementsContainer = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  flexDirection: 'column',
  ['a, a:visited']: {
    color: theme.custom.colors.group4.base,
    textDecoration: 'none'
  }
}));

const HiddenNavElementsContainer = styled.div(() => ({
  display: 'none',
  flexDirection: 'column'
}));

const NavItemIcon = styled(Icon)({
  marginTop: 3
});

const NavItemWrapper = styled.div({
  cursor: 'pointer',
  display: 'flex',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  alignItems: 'center'
});

const ContentHeading = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  margin: 0,
  marginBottom: theme.custom.spacing.medium,
  fontSize: '1.6rem',

  [mq.small]: {
    fontSize: '1.6rem'
  }
}));

const CurrentNavLevelHeading = styled(ContentHeading)(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.large,
  fontSize: '1.6rem',
  margin: 0,

  [mq.small]: {
    fontSize: '1.6rem'
  }
}));

const NavListWrapper = styled.ul(({ theme }: ThemeProps) => ({
  padding: 0,
  marginBottom: theme.custom.spacing.xLarge,
  listStyle: 'none'
}));

const NavItemRow = styled.li(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  ...(theme.custom.typography.paragraph as any),
  marginBottom: theme.custom.spacing.medium,
  cursor: 'pointer',
  fontSize: '1.8rem',
  ['a, span']: {
    color: theme.custom.colors.group4.base,
    textDecoration: 'none',
    ['&:hover']: {
      color: theme.custom.colors.group1.base
    }
  },

  [mq.small]: {
    fontSize: '1.8rem'
  }
}));

const FlagIcon: any = styled(CountryFlag)(({ theme }: ThemeProps) => ({
  marginRight: theme.custom.spacing.xSmall
}));

const backArrowStyles: (theme: Theme) => any = (theme: Theme) => ({
  marginRight: theme.custom.spacing.medium,
  fontSize: '1rem'
});

const DestinationNameWrapper = styled.span({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between'
});

const NavigationLinkWrapper = styled.span(({ theme }: ThemeProps) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  color: theme.custom.colors.group4.base,

  ['&:hover']: {
    color: theme.custom.colors.group1.base
  }
}));

const PhoneNumber: any = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.custom.spacing.large,
  ['a']: {
    ...(theme.custom.typography.paragraph as any),
    fontWeight: 'bold',
    color: theme.custom.colors.group4.base,
    textDecoration: 'none'
  },
  p: {
    display: 'inline-block',
    margin: 0
  },
  ['i']: {
    color: theme.custom.colors.group4.base,
    fontSize: '1.3rem',
    marginRight: theme.custom.spacing.xSmall
  },
  ['&:hover']: {
    ['a, i']: {
      color: theme.custom.colors.group1.base
    }
  }
}));

const TopRowNumber: any = styled.span({
  position: 'relative'
});

const RTap: any = styled.span(({ theme }: ThemeProps) => ({
  position: 'absolute',
  left: 0,
  width: '100%',
  height: '100%',
  textIndent: '-9999px',
  ['a']: {
    color: theme.custom.colors.white
  }
}));

/* *********************** *
 *    MobileNavigation     *
 * *********************** */
export const MobileNavigationComponent = (props: MobileNavigationProps) => {
  const { open, theme, onDismiss, cmsDestinations, contactDetails, infoTiles, offerTypes, isAgent, onLogout } = props;
  const t: any = useI18NextContext();

  /* *** STATE *** */
  const [navHistory, setNavHistory] = useState([]);
  const [prevNavHistory, setPrevNavHistory] = useState([]);

  const isWhiteLabel = AppVariant.isWhitelabel();

  const selectedOptions: SelectedOptions = optionsProps().selectedOptions;
  const setOptions: SetOptions = optionsProps().setOptions;

  /* *** LOGIC *** */
  const navigate = (destination: NavDestination) => {
    const history: any = [...navHistory];
    if (destination.levelChange) {
      if (destination.navItemIndex !== -1) {
        history.push(destination.navItemIndex);
      } else {
        history.pop();
      }
      setPrevNavHistory(navHistory);
      setNavHistory(history);
    }
  };

  const sortDestinations = (cmsDestinations: CMSDestinations, tripTypeFilter: TripTypes) => {
    const filteredDestinations = getDestinationsByTripType(cmsDestinations, tripTypeFilter);

    return sortByField(filteredDestinations, 'title', SortDirection.ASC);
  };

  const isEven = (n: number) => n % 2 === 0;
  const isOdd = (n: number) => Math.abs(n % 2) === 1;

  const getNavObject = (historyIndexes?: Array<number>) => {
    const history = historyIndexes || navHistory;
    let navElement: any = getNavConfig({ isAgent, tripTypes: getTripTypesFromDestinations({ cmsDestinations }) });

    history.forEach((index: number) => {
      navElement = navElement[index].nodes || navElement[index];
    });

    return navElement;
  };

  const getBackRowTitle = (historyIndexes?: Array<number>) => {
    const navigationHistory = historyIndexes || navHistory;
    if (navigationHistory.length < 2) {
      return 'nav__main_menu';
    }

    const prevNavLevel: Array<number> = navigationHistory.slice(0, navigationHistory.length - 2);
    const prevNavIndex: any = navigationHistory.slice(-2).shift();

    return getNavObject(prevNavLevel)[prevNavIndex].label;
  };

  const getContent: (historyIndexes?: Array<number>) => Content = (historyIndexes?: Array<number>) => {
    const navigationHistory = historyIndexes || navHistory;
    if (navigationHistory.length < 1) {
      return {
        mobileContent: null,
        label: null,
        heading: null,
        tripType: null
      };
    }

    const prevNavLevel: Array<number> = [...navigationHistory];
    const prevNavIndex: any = prevNavLevel.pop();
    const navObject = getNavObject(prevNavLevel)[prevNavIndex];

    return {
      mobileContent: navObject.mobileContent,
      label: navObject.label,
      heading: navObject.heading || navObject.mobileContent,
      tripType: navObject.tripType
    };
  };

  /* *** HOOKS *** */
  useEffect(() => {
    if (open) {
      setNavHistory([]);
      setPrevNavHistory([]);
    }
  }, [open]);

  /* *** RENDERERS *** */
  const renderTopRow = () => (
    <NavRowTop data-testid={TestId.mobileNavigation.topRow}>
      <IconButton data-testid={TestId.mobileNavigation.closeButton} aria-label="Close" onClick={onDismiss} size="large">
        <Icon name="mhi-clear" css={{ fontSize: '2rem', color: theme!.custom.colors.white, zIndex: 1 }} />
      </IconButton>
      <LogoStyled />
      <TopRowNumber onClick={clickToCallWrapper}>
        <Icon name="mhi-telephone" css={{ fontSize: '2rem', color: theme!.custom.colors.white }} />
        <RTap className={RTAP_CLASSNAME} />
      </TopRowNumber>
    </NavRowTop>
  );

  const renderBackRow = (historyIndexes?: Array<number>) => {
    const navigationHistory = historyIndexes || navHistory;
    return (
      <Fragment>
        <NavRow
          data-testid={TestId.mobileNavigation.backRow}
          button
          onClick={() =>
            navigate({
              navItemIndex: -1,
              levelChange: true
            })
          }
        >
          <Icon name="mhi-chevron-left" css={backArrowStyles(theme!)} />
          <NavRowLabel
            data-testid={TestId.mobileNavigation.backRowLabel}
            primary={t(getBackRowTitle(navigationHistory))}
          />
        </NavRow>
        <NavDivider />
      </Fragment>
    );
  };

  const renderNavRows = (historyIndexes?: Array<number>) => {
    const navigationHistory = historyIndexes || navHistory;
    const rows: Array<NavigationRow> = getNavObject(navigationHistory);

    return (
      <Fragment>
        {!!navigationHistory.length && renderBackRow(navigationHistory)}
        {rows.length && [
          ...rows.map((row: NavigationRow, index: number) => (
            <Fragment key={index}>
              <NavRow
                data-testid={TestId.mobileNavigation.navigationRow}
                button
                onClick={() =>
                  navigate({
                    levelChange: (!!row.nodes && row.nodes.length > 0) || !!row.mobileContent,
                    navItemIndex: index
                  })
                }
              >
                <NavRowLabel data-testid={TestId.mobileNavigation.navigationRowLabel} primary={t(row.label)} />
                {((row.nodes && row.nodes.length) || row.mobileContent) && (
                  <Icon name="mhi-chevron-right" css={{ fontSize: '1rem', color: theme!.custom.colors.white }} />
                )}
              </NavRow>
              <NavDivider />
            </Fragment>
          )),
          !navigationHistory.length && (
            <Fragment key="help-centre">
              {!isWhiteLabel && (
                <Link href={Page.HELP_CENTRE} passHref>
                  <NavRow data-testid={TestId.mobileNavigation.navigationRow} button>
                    <NavRowLabel
                      data-testid={TestId.mobileNavigation.navigationRowLabel}
                      primary={t('common__label--help')}
                    />
                  </NavRow>
                  <NavDivider />
                </Link>
              )}
              <Link href={isAgent ? Page.AGENTS.concat(AgentPage.BOOKINGS) : Page.MY_BOOKING} passHref>
                <NavRow data-testid={TestId.mobileNavigation.navigationRow} button>
                  <NavRowLabel
                    data-testid={TestId.mobileNavigation.navigationRowLabel}
                    primary={t('nav__my-booking')}
                  />
                </NavRow>
                <NavDivider />
              </Link>
              <Link href={Page.HELP.concat(Page.CONTACT_US)} passHref>
                <NavRow button>
                  <NavRowLabel primary={t('nav__contact')} />
                </NavRow>
                <NavDivider />
              </Link>
            </Fragment>
          ),
          isAgent && (
            <Fragment key={Page.AGENTS.concat(AgentPage.BOOKINGS)}>
              <Link href={Page.AGENTS.concat(AgentPage.BOOKINGS)}>
                <NavRow button>
                  <NavRowLabel primary={t(agentPageLabels[AgentPage.BOOKINGS])} />
                </NavRow>
              </Link>
              <NavDivider />
            </Fragment>
          ),
          isAgent && (
            <Fragment key={Page.AGENTS.concat(AgentPage.LOGIN)}>
              <NavRow button onClick={onLogout}>
                <NavRowLabel primary={t('common__label--logout')} />
              </NavRow>
              <NavDivider />
            </Fragment>
          )
        ]}
      </Fragment>
    );
  };

  const renderDestinationsList = (tripType: TripTypes) => {
    const sortedDestinations = sortDestinations(cmsDestinations, tripType);

    return sortedDestinations.map((item: CMSDestination, index: number) => {
      return (
        <DestinationLink
          path={item.destinationPath.fields.path}
          tripType={tripType}
          key={`${item.destinationPath.fields.path}-${index}`}
        >
          <NavItemRow data-testid={TestId.mobileNavigation.destinationRow} onClick={onDismiss}>
            <FlagIcon countryCode={item.countryIsoCode} />
            <DestinationNameWrapper>{item.title}</DestinationNameWrapper>
          </NavItemRow>
        </DestinationLink>
      );
    });
  };

  const renderContent = (historyIndexes?: Array<number>) => {
    const navigationHistory = historyIndexes || navHistory;
    const contentData = getContent(navigationHistory);
    const contentType = contentData.mobileContent;
    const contentHeading = t(contentData.heading);

    if (!contentType) {
      return null;
    }

    let content;

    switch (contentType) {
      case ContentTypes.OFFERS:
        content = offerTypes.map((offerType: Entry<CMSOffer>) => (
          <NavListWrapper key={offerType.sys?.id}>
            <NavItemRow data-testid={TestId.mobileNavigation.destinationRow}>
              <OfferLink path={Page.OFFERS.concat('/').concat(offerType.fields.pageId)}>
                <NavigationLinkWrapper onClick={onDismiss}>{offerType.fields.title}</NavigationLinkWrapper>
              </OfferLink>
            </NavItemRow>
          </NavListWrapper>
        ));
        break;
      case ContentTypes.HERE_TO_HELP:
        content = infoTiles
          .filter((infoTile: any) => !!infoTile.fields && !isPageBlacklisted(infoTile.fields.pageId))
          .map((infoTile: any) => (
            <NavListWrapper key={infoTile.fields.pageId}>
              <NavItemRow data-testid={TestId.mobileNavigation.destinationRow}>
                <HelpLink path={Page.HELP.concat('/').concat(infoTile.fields.pageId)}>
                  <NavigationLinkWrapper onClick={onDismiss}>{infoTile.fields.title}</NavigationLinkWrapper>
                </HelpLink>
              </NavItemRow>
            </NavListWrapper>
          ));
        if (!isWhiteLabel) {
          content.push(
            <NavListWrapper key={'agent__link--title'}>
              <NavItemRow data-testid={TestId.mobileNavigation.destinationRow}>
                <Link href={Page.AGENTS}>{t('agent__link--title')}</Link>
              </NavItemRow>
            </NavListWrapper>
          );
        }
        break;
      case ContentTypes.AGENTS:
        content = (
          <NavListWrapper>
            <NavItemRow>
              <Link href={Page.AGENTS}>
                <NavigationLinkWrapper onClick={onDismiss}>
                  {t('agent__page--travel-agents-home')}
                </NavigationLinkWrapper>
              </Link>
            </NavItemRow>
            {navAgentPages.map((page: AgentPage) => (
              <NavItemRow key={page}>
                <Link href={Page.AGENTS.concat(page)}>
                  <NavigationLinkWrapper onClick={onDismiss}>{t(agentPageLabels[page])}</NavigationLinkWrapper>
                </Link>
              </NavItemRow>
            ))}
          </NavListWrapper>
        );
        break;
      default:
        content = <NavListWrapper>{renderDestinationsList(contentData.tripType!)}</NavListWrapper>;
        break;
    }

    return (
      <Fragment>
        <CurrentNavLevelHeading data-testid={TestId.mobileNavigation.currentNavLevelHeading}>
          {contentHeading}
        </CurrentNavLevelHeading>
        <ContentContainer data-testid={TestId.mobileNavigation.contentContainer}>{content}</ContentContainer>
      </Fragment>
    );
  };

  const renderNavItems = () => [
    ...getNavConfig().map(
      (item: NavigationRow, index: number) =>
        item.desktopContent && (
          <DesktopNavItem key={`${item.label}-${index}`} data-testid={TestId.navBar.desktopNavItem}>
            <NavItemWrapper>
              <NavLabel isClickable={true}>{t(item.label)}</NavLabel>
              <NavItemIcon name={'mhi-chevron-down'} />
            </NavItemWrapper>
            <DesktopNavigation
              navContent={item.desktopContent}
              show={false}
              selectedOptions={selectedOptions}
              setOptions={setOptions}
            />
          </DesktopNavItem>
        )
    ),
    ...(!isWhiteLabel
      ? [
          <DesktopNavItem key={'help'} data-testid={TestId.navBar.desktopNavItemHelp}>
            <NavLabel isClickable={true} noIcon>
              <Link href={Page.HELP_CENTRE} passHref>
                {t('common__label--help')}
              </Link>
            </NavLabel>
          </DesktopNavItem>
        ]
      : []),
    <DesktopNavItem key={'my-booking'} data-testid={TestId.navBar.desktopNavItem}>
      <NavLabel isClickable={true} noIcon>
        <Link href={isAgent ? Page.AGENTS.concat(AgentPage.BOOKINGS) : Page.MY_BOOKING} passHref>
          {t('nav__my-booking')}
        </Link>
      </NavLabel>
    </DesktopNavItem>,
    <DesktopNavItem key={'contact'}>
      <NavLabel isClickable={true} noIcon>
        <Link href={Page.HELP.concat(Page.CONTACT_US)} passHref>
          {t('nav__contact')}
        </Link>
      </NavLabel>
    </DesktopNavItem>
  ];

  const renderDrawers = () => {
    return [...Array(2)].map((_, index) => {
      const checkDrawerLevel = isEven(index) ? isEven : isOdd;
      const isCurrent = (isEven(index) && isEven(navHistory.length)) || (isOdd(index) && isOdd(navHistory.length));
      const navigationHistory = isCurrent ? navHistory : prevNavHistory;
      const telephoneNumber: string = contactDetails?.fields?.mainTelephoneNumber || '';
      const stringMatch: RegExpMatchArray | null = telephoneNumber.match(/([\d\s]+)/);
      const telNumber: string = stringMatch ? stringMatch[1] : telephoneNumber;

      return (
        <StyledDrawer
          data-testid={isEven(index) ? TestId.mobileNavigation.drawerEven : TestId.mobileNavigation.drawerOdd}
          key={index}
          open={open && checkDrawerLevel(navHistory.length)}
          onClose={onDismiss}
          transitionDuration={{ enter: 500, exit: 600 }}
          elevation={navHistory.length}
        >
          <NavElementsContainer>
            {renderTopRow()}
            <NavDivider />
            {renderNavRows(navigationHistory)}
          </NavElementsContainer>
          {renderContent(navigationHistory)}
          <HiddenNavElementsContainer>{renderNavItems()}</HiddenNavElementsContainer>
          <PhoneNumber data-testid={TestId.mobileNavigation.bottomNumber} onclick={clickToCallWrapper}>
            <a href={`tel:${telNumber.replace(/\s/g, '')}`}>
              <Icon name={'mhi-telephone'} /> <Markdown children={telephoneNumber} components={LinkComponent} />
            </a>
          </PhoneNumber>
        </StyledDrawer>
      );
    });
  };

  return <>{renderDrawers()}</>;
};

MobileNavigationComponent.defaultProps = {
  open: false
};
