import { OnInit, OnInitConfig } from '@model/bootstrap/on-init';
import Router from 'next/router';
import { locationChanged } from '@state/app';

export class RouterEventsBootstrap implements OnInit {
  initialize(config: OnInitConfig): void {
    Router.events.on('routeChangeComplete', (url: string) => {
      config.dispatch(locationChanged(url));
      window.scroll(0, 0);
    });
  }
}
