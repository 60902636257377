import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';

const Container: any = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const Message: any = styled.span((props: any) => ({
  ...props.theme.custom.typography.labels.default,
  padding: props.theme.custom.spacing.medium
}));

const InputValue: any = styled.span((props: any) => ({
  ...props.theme.custom.typography.labels.default,
  fontWeight: 'bold'
}));

const RetryMessage: any = styled.div((props: any) => ({
  marginTop: 12,
  ...props.theme.custom.typography.labels.default
}));

export interface NoOptionsMessageProps {
  message?: string;
}

const NoOptionsLabel = (props: NoOptionsMessageProps) => {
  const t: any = useI18NextContext();
  const { message = '' } = props;
  return (
    <>
      {t('search__no_options--message_pre')}
      <InputValue>{message}</InputValue>
      {t('search__no_options--message_post')}
    </>
  );
};

export interface NoOptionsComponentProps {
  selectProps?: {
    inputValue: string;
  };
  message?: string;
  retryMessage?: string;
}

export const NoOptionsComponent = (props: NoOptionsComponentProps) => {
  const { selectProps, message, retryMessage } = props;
  const { inputValue } = selectProps || {};
  const t: any = useI18NextContext();
  return (
    <Container data-testid={TestId.noOptionMessage}>
      <Message>
        {message || <NoOptionsLabel message={inputValue} />}
        <RetryMessage>{retryMessage || t('search__no_options--retry')}</RetryMessage>
      </Message>
    </Container>
  );
};

export const NoOptionsMessage = withTheme(NoOptionsComponent);
