import React, { FC } from 'react';
import { AlertModal } from '@components/common/modal/alert/AlertModal';
import styled from '@emotion/styled';
import { Icons } from '@model/common/icons';
import { Theme, ThemeProps } from '@theme/base';
import { useTheme } from '@emotion/react';
import { useI18NextContext } from '@components/hooks';
import { Button, ButtonColor } from '@components/material-ui';

export interface FormSubmitSuccessModalProps {
  onClick: () => void;
  closeButtonLabel: string;
  titleLabel: string;
  bodyLabel: string;
}

const BodyLabel = styled.div(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.medium
}));

const SearchButton: any = styled(Button)({
  width: '100%'
});

export const FormSubmitSuccessModal: FC<FormSubmitSuccessModalProps> = ({
  onClick,
  closeButtonLabel,
  titleLabel,
  bodyLabel
}: FormSubmitSuccessModalProps) => {
  const theme: Theme = useTheme();
  const t: any = useI18NextContext();
  return (
    <AlertModal
      title={t(titleLabel || 'success-modal--title')}
      icon={Icons.SUCCESS}
      iconColor={theme.custom.colors.group5.base}
      renderBottom={
        <SearchButton
          label={t(closeButtonLabel || 'common__label--close')}
          onClick={onClick}
          color={ButtonColor.SECONDARY}
        />
      }
      body={<BodyLabel>{t(bodyLabel || 'contact-us__success-modal--body')}</BodyLabel>}
    />
  );
};
