import styled from '@emotion/styled';
import React from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { Button, ButtonColor, ButtonSize, ButtonVariant } from '@components/material-ui';
import { useI18NextContext } from '@components/hooks';
import { mq } from '@styles/breakpoints';
import { validateEmailSignUpForm } from '@model/forms/validation/email-sign-up-form-validation';
import { Icon } from '@components/common/generic-icon';
import { ToolTip } from '@components/common/tooltip';
import { FormikTextField } from '@components/common/forms';
import { getErrors } from '@util/forms';
import { Grid } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Theme, ThemeProps } from '@theme/base';
import { Snackbar } from '@components/common/snackbar';
import { State } from '@model/common';
import { Icons } from '@model/common/icons';
import { TestId } from '@components/test-ids';
import { GoogleNotice } from '@components/newsletter-signup/GoogleNotice';
import { TEXT_FIELD_MAX_LENGTH } from '@model/forms';

const Container = styled.div();

export const EmailForm = styled(FormikTextField)({
  boxSizing: 'border-box',
  width: '100%',
  input: {
    height: 50
  }
});

export const SignUpButton = styled(Button)({
  height: 50
});

export const FormContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  [mq.small]: {
    flexDirection: 'column'
  }
});

export const Heading = styled.h3(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h3 as any),
  display: 'inline-block',
  marginBottom: theme.custom.spacing.large
}));

const InfoIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  verticalAlign: 'middle',
  fontSize: '2rem',
  color: theme.custom.states.link.default,
  cursor: 'pointer'
}));

export const InfoToolTip = styled(ToolTip)({
  display: 'inline'
});

export const ToolTipContent: any = styled.div({
  maxWidth: 250
});

export const Title = styled.span();

export const TitleContainer = styled.span(({ theme }: ThemeProps) => ({
  marginRight: theme.custom.spacing.xSmall
}));

const StyledGoogleNotice = styled(GoogleNotice)(({ theme }: ThemeProps) => ({
  marginTop: theme.custom.spacing.small
}));

export interface NewsLetterSignUpFormProps {
  submitted: boolean;
  onSubmit: (email: string) => void;
}

export const NewsletterSignUpForm = ({ submitted, onSubmit }: NewsLetterSignUpFormProps) => {
  const t: any = useI18NextContext();
  const theme: Theme = useTheme();
  const handleValidate = (values: any) => {
    const formValidation = validateEmailSignUpForm(values);
    return formValidation.errors;
  };
  const handleOnSubmit = (values: any) => {
    if (values.email) {
      onSubmit(values.email);
    }
  };
  return (
    <Container data-testid={TestId.newsletterSignUpForm.main}>
      <Heading>
        <TitleContainer>
          <Title>{t('email-signup__title--label')}</Title>
        </TitleContainer>
        <InfoToolTip content={<ToolTipContent>{t('email-signup__tooltip--content')}</ToolTipContent>}>
          <InfoIcon name={Icons.INFO} />
        </InfoToolTip>
      </Heading>
      {submitted && (
        <Grid container spacing={theme!.custom.gridSpacing.default}>
          <Grid item xs={12} sm={6}>
            <Snackbar state={State.SUCCESS} icon={Icons.SUCCESS} text={'newsletter-sign-up__success--label'} />
          </Grid>
        </Grid>
      )}
      {!submitted && (
        <Formik
          initialValues={{ email: '' }}
          onSubmit={handleOnSubmit}
          validate={handleValidate}
          validateOnMount
          validateOnBlur
        >
          {({ handleChange, handleBlur, values, submitForm, errors, touched }: FormikProps<FormikValues>) => {
            return (
              <FormContainer>
                <Grid container spacing={theme!.custom.gridSpacing.default} alignItems={'flex-end'}>
                  <Grid item xs={12} sm={6} md={4}>
                    <EmailForm
                      type={'text'}
                      name={'email'}
                      label={t('email-signup__email--title')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      maxLength={TEXT_FIELD_MAX_LENGTH}
                      placeholder={t('email-signup__email-placeholder')}
                      error={getErrors(touched, errors, 'email')}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} md={2}>
                    <SignUpButton
                      label={'common__label--sign-up'}
                      color={ButtonColor.SECONDARY}
                      variant={ButtonVariant.OUTLINED}
                      size={ButtonSize.FLUID}
                      onClick={submitForm}
                    />
                  </Grid>
                </Grid>
                <StyledGoogleNotice />
              </FormContainer>
            );
          }}
        </Formik>
      )}
    </Container>
  );
};
