import { SortDirection, SortOptions } from '@model/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedAirport,
  getSelectedMonth,
  getSelectedSortDirection,
  getSelectedSortOption,
  getTripTypeFilter,
  setSelectedAirport,
  setSelectedMonth,
  setSelectedSortDirection,
  setSelectedSortOption,
  setTripTypeFilter
} from '@state/navigation';
import { TripTypes } from '@model/contentful';

export default function optionsProps() {
  const dispatch = useDispatch();

  const selectedOptions = {
    selectedAirport: useSelector(getSelectedAirport),
    selectedMonth: useSelector(getSelectedMonth),
    selectedSortOption: useSelector(getSelectedSortOption),
    selectedFilter: useSelector(getTripTypeFilter),
    selectedSortDirection: useSelector(getSelectedSortDirection)
  };

  const setOptions = {
    setSelectedAirport: (payload: string) => dispatch(setSelectedAirport(payload)),
    setSelectedFilter: (payload: TripTypes) => dispatch(setTripTypeFilter(payload)),
    setSelectedMonth: (payload: string) => dispatch(setSelectedMonth(payload)),
    setSelectedSortOption: (payload: SortOptions) => dispatch(setSelectedSortOption(payload)),
    setSelectedSortDirection: (payload: SortDirection) => dispatch(setSelectedSortDirection(payload))
  };

  return { selectedOptions, setOptions };
}
