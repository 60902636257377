import React, { useState } from 'react';
import { EnvConfig } from '@model/config/env-config';
import { Environment } from '@model/config/environment';
import styled from '@emotion/styled';
import { useAsync } from 'react-use';
import { ZIndex } from '@styles/z-index';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@components/common/generic-icon';
import { Icons } from '@model/common/icons';
import { CustomApi } from '@model/iceberg/service/custom';
import { SearchList } from '@components/dev-banner/deal-finder/SearchList';
import { ThemeProps } from '@theme/base';

const Banner = styled.div(({ theme }: ThemeProps) => ({
  position: 'fixed',
  top: 'calc(50% - 25px)',
  left: 0,
  height: 50,
  zIndex: ZIndex.DEV_BANNER,
  backgroundColor: theme.custom.colors.group1.base,
  writingMode: 'vertical-lr',
  padding: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  cursor: 'pointer'
}));

const TitleContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
});

const LiveIVectorBanner = styled.div({
  background: 'red',
  color: 'white',
  fontSize: '2rem',
  height: 32,
  left: 0,
  position: 'absolute',
  textAlign: 'center',
  top: 0,
  width: '100%',
  zIndex: 9999
});

export const DevBanner = () => {
  const env = EnvConfig.get().APP_ENV;
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (env !== Environment.PRODUCTION) {
    const isLiveIVector = useAsync(async () => {
      return new CustomApi().isLiveIVector();
    }, []);
    return (
      <div>
        {isLiveIVector.value && <LiveIVectorBanner>DEV IS POINTING TO LIVE IVECTOR</LiveIVectorBanner>}
        <Banner onClick={handleDrawerOpen} suppressHydrationWarning>
          {env}
        </Banner>
        <Drawer variant="persistent" anchor="left" open={open}>
          <TitleContainer>
            <IconButton onClick={handleDrawerClose} size="large">
              <Icon name={Icons.CLEAR} />
            </IconButton>
          </TitleContainer>
          <Divider />
          <SearchList onClose={handleDrawerClose} />
          <Divider />
        </Drawer>
      </div>
    );
  }
  return null;
};
