import algoliaSearch from 'algoliasearch';
import React from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { EnvConfig } from '@model/config/env-config';
import { AlgoliaApi } from '@model/iceberg/service/algolia/AlgoliaApi';

export interface AlgoliaSearchProps {
  children: any;
  index: AlgoliaIndex;
  filters?: string;
  facetFilters?: any;
}

export const getClient = () => {
  const algoliaAppId: string = EnvConfig.get().ALGOLIA_APP_ID;
  const algoliaAppKey: string = EnvConfig.get().ALGOLIA_API_KEY;
  return algoliaSearch(algoliaAppId, algoliaAppKey);
};

export enum AlgoliaIndex {
  GEOGRAPHY = 'GEOGRAPHY',
  AIRPORT = 'AIRPORT',
  TOURS = 'TOURS',
  PRIVATE_TOURS = 'PRIVATE_TOURS',
  RIVER_CRUISES = 'RIVER_CRUISES',
  MULTI_CENTRE = 'MULTI_CENTRE',
  HELP_CENTRE = 'HELP_CENTRE'
}

export const indexMap = {
  [AlgoliaIndex.GEOGRAPHY]: 'geography',
  [AlgoliaIndex.AIRPORT]: 'airport',
  [AlgoliaIndex.TOURS]: 'tour',
  [AlgoliaIndex.PRIVATE_TOURS]: 'private-tour',
  [AlgoliaIndex.RIVER_CRUISES]: 'river-cruises',
  [AlgoliaIndex.MULTI_CENTRE]: 'multicentre',
  [AlgoliaIndex.HELP_CENTRE]: 'help_centre_articles'
};

const toursSearch = (requests: any) => {
  if (requests.every(({ params }: any) => !params.query)) {
    return Promise.resolve({
      results: requests.map(() => ({
        hits: [],
        nbHits: 0,
        nbPages: 0,
        processingTimeMS: 0
      }))
    });
  }
  return getClient().search(requests);
};

const searchClientIndex = {
  [AlgoliaIndex.GEOGRAPHY]: {
    search: (requests: any) => {
      if (requests.every(({ params }: any) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0
          }))
        });
      }
      return getClient().search(requests);
    }
  },
  [AlgoliaIndex.TOURS]: {
    search: toursSearch
  },
  [AlgoliaIndex.PRIVATE_TOURS]: {
    search: toursSearch
  },
  [AlgoliaIndex.RIVER_CRUISES]: {
    search: toursSearch
  },
  [AlgoliaIndex.MULTI_CENTRE]: {
    search: toursSearch
  },
  [AlgoliaIndex.AIRPORT]: {
    search: (requests: any) => getClient().search(requests)
  },
  [AlgoliaIndex.HELP_CENTRE]: {
    search: (requests: any) => {
      return getClient().search(requests);
    }
  }
};

export const AlgoliaSearch = (props: AlgoliaSearchProps) => {
  const { index, filters, children, facetFilters } = props;
  const indexName: string = new AlgoliaApi().getIndex(indexMap[index] as AlgoliaIndex);
  const searchClient = searchClientIndex[index];

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <Configure getRankingInfo filters={filters} facetFilters={facetFilters} hitsPerPage={100} />
      {children}
    </InstantSearch>
  );
};
