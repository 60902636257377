import { useEffect, useState } from 'react';
import Cookies, { Cookie } from '@model/common/cookie/cookie';

export function useIsAgent() {
  const hasTradeAuthorizationCookie = !!new Cookies().get(Cookie.TRADE_AUTHORIZATION);
  const [isAgent, setIsAgent] = useState(hasTradeAuthorizationCookie);
  useEffect(() => {
    if (hasTradeAuthorizationCookie) {
      setIsAgent(true);
    } else {
      setIsAgent(false);
    }
  }, [hasTradeAuthorizationCookie]);

  return isAgent;
}
