import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';

export const NavContainer = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 55
});

export const NavHeading: any = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  marginTop: 10,

  ['span']: {
    ...(theme.custom.typography.paragraph as any)
  },

  ['a, a:visited']: {
    textDecoration: 'none',

    ['&:hover']: {
      color: theme.custom.colors.group1.base
    }
  }
}));

export const NavItem = styled.li(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.medium,

  ['a, a:visited, a:active']: {
    color: theme.custom.colors.group4.base,
    display: 'flex',
    alignItems: 'flex-start',
    ...(theme.custom.typography.paragraph as any),
    cursor: 'pointer',

    ['&:hover']: {
      color: theme.custom.colors.group1.base
    }
  }
}));

interface LinksListContainerProps extends ThemeProps {
  displayInColumns?: boolean;
}
export const LinksListContainer: any = styled.ul(({ theme, displayInColumns }: LinksListContainerProps) => ({
  flex: 1,
  padding: 0,
  ...(displayInColumns && { columnCount: 4 }),
  paddingTop: theme.custom.spacing.xLarge,
  paddingBottom: theme.custom.spacing.xLarge,
  listStyleType: 'none',

  [`@media (max-width: ${theme.custom.contentSizes.maxContentWidth}px)`]: {
    marginRight: theme.custom.spacing.medium
  }
}));
