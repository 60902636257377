import { OpeningTimes } from './OpeningTimes';
import { useSelector } from 'react-redux';
import { getCMSOpeningTimes, getCMSContactDetails } from '@state/cms';
import React from 'react';

export interface OpeningTimesContainerProps {
  testId?: string;
  show: boolean;
}

export const OpeningTimesContainer = (props: OpeningTimesContainerProps) => {
  const openingTimes = useSelector(getCMSOpeningTimes);
  const contactDetails = useSelector(getCMSContactDetails);
  if (!openingTimes || !contactDetails) return null;
  return <OpeningTimes {...props} openingTimes={openingTimes} contactDetails={contactDetails} />;
};
