import Link from 'next/link';
import React from 'react';
import { DestinationLinkBuilder, LinkItem } from '@model/iceberg/geography';
import { TripTypes } from '@model/contentful';
import { Page } from '@model/common';
import { PlainLinkStyles } from '@styles/common';

export interface GuideLinkProps {
  path: string;
  children?: JSX.Element;
  guidePath: string;
  tripType?: TripTypes;
  guidePage: Page;
}

export const GuideLink = ({ path, guidePath, tripType, guidePage, children }: GuideLinkProps) => {
  const builder: DestinationLinkBuilder = new DestinationLinkBuilder();
  const guideLink: LinkItem = builder.getGuideLink(path, guidePath, guidePage, tripType);
  return (
    <Link prefetch={false} {...guideLink} passHref style={PlainLinkStyles}>
      {children}
    </Link>
  );
};
