import { useDispatch } from 'react-redux';
import { closeModal } from '@state/modal/modalOperations';
import React from 'react';
import { SearchFormGuestsModal } from '@components/modal/search-form-guests/SearchFormGuestsModal';

export const SearchFormGuestsModalContainer = () => {
  const dispatch = useDispatch();
  const handleOnClick = () => {
    dispatch(closeModal());
  };
  return <SearchFormGuestsModal onClick={handleOnClick} />;
};
