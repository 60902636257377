import { withTheme } from '@emotion/react';
import React from 'react';
import { components } from 'react-select';

export const MenuListComponent = (props: any) => {
  const { MenuList } = components;
  return <MenuList {...props}>{props.children}</MenuList>;
};

export const MenuList = withTheme(MenuListComponent);
