import { css } from '@emotion/css';
import React from 'react';
import { components } from 'react-select';
import { Theme } from '@theme/base';
import { useTheme } from '@emotion/react';

const inputStyles = (theme: Theme) =>
  css({
    ...(theme.custom.typography.paragraph as any),
    height: '100%',
    input: {
      ...(theme.custom.typography.labels.default as any)
    } as any
  });

export const Input = (props: any) => {
  const { Input } = components;
  const theme: any = useTheme();
  return <Input {...props} className={inputStyles(theme)} />;
};
