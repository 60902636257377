import { ContentTypes } from '../../model/navigation';
import { NavigationRow } from '@components/navigation';
import { CMSTripType, TripTypes } from '@model/contentful';
import { AppVariant } from '@model/config/brands';

const getTripTypeNavRows: (tripTypes: Array<CMSTripType>) => Array<NavigationRow> = (tripTypes: Array<CMSTripType>) =>
  tripTypes
    .map((tripType: CMSTripType) => ({
      label: tripType.title,
      mobileContent: ContentTypes.DESTINATIONS_BY_TRIP_TYPE,
      heading: tripType.title,
      tripType: tripType.tripTypeId
    }))
    .filter((navRow: any) => navRow.tripType !== TripTypes.ALL);

export interface NavConfig {
  isAgent?: boolean;
  tripTypes?: Array<CMSTripType>;
}

export const getNavConfig: (config?: NavConfig) => Array<NavigationRow> = ({ tripTypes, isAgent }: NavConfig = {}) => {
  const isWhiteLabel = AppVariant.isWhitelabel();

  const items = [
    {
      label: ContentTypes.DESTINATIONS,
      desktopContent: ContentTypes.DESTINATIONS,
      nodes: tripTypes && tripTypes.length ? getTripTypeNavRows(tripTypes) : []
    },
    {
      label: ContentTypes.HERE_TO_HELP,
      desktopContent: ContentTypes.HERE_TO_HELP,
      mobileContent: ContentTypes.HERE_TO_HELP
    }
  ];
  if (!isWhiteLabel) {
    items.unshift({
      label: ContentTypes.OFFERS,
      desktopContent: ContentTypes.OFFERS,
      mobileContent: ContentTypes.OFFERS
    });
  }

  if (isAgent) {
    items.push({ label: ContentTypes.AGENTS, desktopContent: ContentTypes.AGENTS, mobileContent: ContentTypes.AGENTS });
  }
  return items;
};
