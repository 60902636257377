import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { mq, breakpoints, isMobile } from '@styles/breakpoints';
import { Icon } from '@components/common/generic-icon';
import { ModalContainer, Container as BaseContainer, Heading as BaseHeading } from '@styles/modal';
import { TILE_FALLBACK_IMAGE } from '@mock/fallback';
import { ImageGallery } from '@components/common/image-gallery/ImageGallery';
import { getImageUrl } from '@util/images';
import { Fade } from '@components/common/modal/info-card';
import { TextMarkdown } from '@components/common/description';
import ReactPlayer from 'react-player';
import { INLINES } from '@contentful/rich-text-types';
import { useRef } from 'react';

/* ***************** *
 *       Types       *
 * ***************** */
export interface PromoBannerModalProps {
  onDismiss: () => void;
  modalData: any;
}

/* ****************** *
 *       Styles       *
 * ****************** */
const Container: any = styled(BaseContainer)(({ theme }: ThemeProps) => ({
  backgroundColor: theme.custom.colors.white,
  alignItems: '',
  width: '85%',
  borderRadius: 2,
  position: 'relative',
  overflowY: 'scroll',
  maxHeight: '100vh',

  [mq.small]: {
    maxWidth: 600
  }
}));

const Heading: any = styled(BaseHeading)(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.medium,
  margin: 0,

  [mq.small]: {
    padding: theme.custom.spacing.large
  }
}));

interface PromoTextProps extends ThemeProps {
  hasGallery: boolean;
  centerAlign: boolean;
}
const PromoText: any = styled.div(({ theme, hasGallery, centerAlign }: PromoTextProps) => ({
  padding: theme.custom.spacing.medium,
  overflowY: 'auto',
  maxHeight: hasGallery ? 225 : 600,
  paddingBottom: theme.custom.spacing.xLarge,
  textAlign: centerAlign ? 'center' : 'left',

  [mq.small]: {
    padding: theme.custom.spacing.large,
    paddingBottom: theme.custom.spacing.xLarge
  }
}));

const VideoWrapper: any = styled.span(({ theme }: ThemeProps) => ({
  background: theme.custom.colors.black,
  display: 'block',
  height: 260,
  margin: `-${theme.custom.spacing.medium}px -${theme.custom.spacing.medium}px 0`,
  paddingTop: theme.custom.spacing.medium,
  position: 'relative',
  width: `calc(100% + ${2 * theme.custom.spacing.medium}px)`,
  [mq.small]: {
    height: 340,
    margin: `-${theme.custom.spacing.large}px -${theme.custom.spacing.large}px 0`,
    paddingBottom: theme.custom.spacing.large,
    paddingTop: theme.custom.spacing.large,
    width: `calc(100% + ${2 * theme.custom.spacing.large}px)`
  },
  ['.player']: {
    opacity: 1,
    transition: 'opacity 2s',
    ['&.hidden']: {
      opacity: 0
    }
  }
}));

const CloseIcon: any = styled(Icon)({
  cursor: 'pointer',
  fontSize: '2rem'
});

const ModalContent: any = styled.div({});

export const PromoBannerModal = ({ onDismiss, modalData }: PromoBannerModalProps) => {
  const ref: any = useRef();

  const { title, gallery = [], centreAlignText } = modalData;

  /* *** LOGIC *** */
  const getGalleryImageUrls = () =>
    gallery.map((cmsGalleryItem: any) => ({
      thumbnail: getImageUrl(cmsGalleryItem.fields?.file?.url, breakpoints.small) || TILE_FALLBACK_IMAGE,
      original: getImageUrl(cmsGalleryItem.fields?.file?.url, breakpoints.small) || TILE_FALLBACK_IMAGE
    }));

  const showPlayer = () => {
    ref.current.classList.remove('hidden');
  };
  const isMobileView = isMobile();

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        const {
          content,
          data: { uri }
        } = node;
        if (/\bvimeo\b/.test(uri)) {
          return (
            <VideoWrapper>
              <div className="player hidden" ref={ref}>
                <ReactPlayer
                  url={uri}
                  controls={true}
                  width={isMobileView ? '100%' : 600}
                  height={isMobileView ? 220 : 300}
                  onReady={showPlayer}
                />
              </div>
            </VideoWrapper>
          );
        }
        return <a href={uri}>{content.find(({ nodeType }) => nodeType === 'text').value}</a>;
      }
    }
  };

  return (
    <ModalContainer>
      <Container>
        <Heading>
          {title}
          <CloseIcon name={'mhi-clear'} onClick={onDismiss} />
        </Heading>
        <ModalContent>
          {!!gallery?.length && <ImageGallery galleryImages={getGalleryImageUrls()} showGalleryDots={true} />}
          <PromoText hasGallery={!!gallery?.length} centerAlign={centreAlignText}>
            <TextMarkdown description={modalData.markdown}>{options}</TextMarkdown>
          </PromoText>
          <Fade />
        </ModalContent>
      </Container>
    </ModalContainer>
  );
};
