import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { withTheme } from '@emotion/react';
import React, { CSSProperties } from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps, ErrorThemeProps, CssThemeProps } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { FormControl, Select, MenuItem } from '@mui/material';
import { boxShadowMixins, mixins } from '@styles/mixins';
import { Icon } from '@components/common/generic-icon';
import { Icons } from '@model/common/icons';
import { Keyable } from '@model/common';

/* ***************** *
 *       Types       *
 * ***************** */
export interface DropdownProps {
  theme?: Theme;
  testId?: string;
  name: string;
  options: Array<DropdownOption>;
  onChange: (event: any) => any;
  onBlur?: (event: any) => any;
  value?: number | string;
  placeholder?: string;
  css?: CSSProperties;
  isError?: boolean;
  className?: string;
  disabled?: boolean;
  icon?: string;
  menuStyles?: Keyable;
  listItemStyles?: Keyable;
  renderMenuItems?: (options: Array<Keyable>) => Array<JSX.Element>;
}

export interface DropdownOption {
  value: number | string;
  name: string;
}

/* ***************** *
 *       Styles      *
 * ***************** */
interface ContainerProps extends CssThemeProps, ErrorThemeProps {}
const Container: any = styled.div(({ theme, css, isError }: ContainerProps) => ({
  ['.MuiFormControl-root']: {
    width: '100%',
    height: 40,
    border: '1px solid',
    borderColor: isError ? theme.custom.states.error.dark : theme.custom.colors.group10.light,
    boxShadow: boxShadowMixins(theme.custom.colors.group10.lighter).lighter,
    transition: `border-color ${theme.custom.transitions.default}`,
    borderRadius: 2,
    ['&:hover']: {
      borderColor: theme.custom.colors.group7.base,
      i: {
        color: theme.custom.states.hover.dark
      },
      ['.MuiSelect-icon']: {
        color: theme.custom.colors.group7.base
      }
    }
  },

  ['.MuiInputBase-input']: {
    padding: 7
  },

  ['.MuiSelect-select:focus']: {
    backgroundColor: 'transparent'
  },

  ['.MuiOutlinedInput-notchedOutline']: {
    border: 'none'
  },

  ['.MuiInput-underline::before, .MuiInput-underline::after']: {
    display: 'none'
  },

  ['.MuiSelect-select']: {
    ...(theme.custom.typography.paragraph as any),
    height: 25,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.custom.spacing.xSmall * 2,
    paddingRight: 35,
    ...mixins(theme).truncate
  },

  ['.MuiSelect-icon']: {
    top: 'calc(50% - 12px)',
    transition: `color ${theme.custom.transitions.default}`,

    width: 25,
    height: 25,
    right: 10,
    color: theme.custom.colors.group10.base
  },

  ['.MuiMenuItem-root']: {
    ...(theme.custom.typography.paragraph as any)
  },
  ...css
}));

const selectMenuStyles = (theme: Theme, menuStyles?: CSSProperties, listItemStyles?: CSSProperties) => ({
  list: css({
    ['.MuiButtonBase-root']: {
      ...(theme.custom.typography.paragraph as any),
      color: theme.custom.colors.group4.base,
      justifyContent: 'flex-start',
      paddingLeft: theme.custom.spacing.medium,

      ['&:hover']: {
        backgroundColor: theme.custom.colors.group1.lighter
      },

      ['&:focus']: {
        backgroundColor: 'transparent'
      },
      ...listItemStyles
    },

    ['.Mui-selected']: {
      backgroundColor: 'transparent',
      color: theme.custom.colors.group5.base
    },

    ['.MuiMenuItem-root']: {
      display: 'flex'
    },
    ...menuStyles
  } as any)
});

const DropdownIcon: any = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '1.8rem',
  color: theme.custom.colors.group4.light
}));

const StyledChevronIcon: any = styled(Icon)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
  '.MuiSelect-iconOpen': {
    transform: `rotate(180deg)`
  }
});

/* *********************** *
 *    DropdownComponent    *
 * *********************** */

export const DropdownComponent = (props: DropdownProps) => {
  const {
    css,
    name,
    options,
    onChange,
    value,
    placeholder,
    theme,
    onBlur,
    isError,
    className,
    disabled,
    icon,
    testId,
    menuStyles,
    listItemStyles,
    renderMenuItems
  } = props;
  const t: any = useI18NextContext();

  /* *** RENDERERS *** */
  const renderDropdownOptions = () =>
    options.map((option: DropdownOption) => (
      <MenuItem data-testid={TestId.dropdown.option} key={option.value} value={option.value}>
        {t(option.name)}
      </MenuItem>
    ));

  return (
    <Container data-testid={testId || TestId.dropdown.main} css={css} isError={isError} className={className}>
      <FormControl variant="outlined">
        <Select
          data-testid={TestId.dropdown.dropdownElement}
          displayEmpty
          name={name}
          onChange={onChange}
          value={value || ''}
          variant="outlined"
          MenuProps={{
            classes: selectMenuStyles(theme!, menuStyles, listItemStyles)
          }}
          onBlur={onBlur}
          disabled={disabled}
          startAdornment={icon && <DropdownIcon name={icon} />}
          IconComponent={({ className }: any) => {
            return <StyledChevronIcon className={className} name={Icons.CARET_DOWN} />;
          }}
        >
          {placeholder && (
            <MenuItem value="" disabled>
              {t(placeholder)}
            </MenuItem>
          )}
          {renderMenuItems ? renderMenuItems(options) : renderDropdownOptions()}
        </Select>
      </FormControl>
    </Container>
  );
};

export const Dropdown = withTheme(DropdownComponent);
