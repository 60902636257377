import { Icon } from '@components/common/generic-icon';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { Highlight } from 'react-instantsearch-dom';
import { components } from 'react-select';
import { ThemeProps } from '@theme/base';

export interface OptionComponentProps {
  getSubtitle?: (data: any) => string;
  getIcon?: (data: any) => string;
  attribute: string;
  isFocused: boolean;
  data: any;
}

const HighlightStyled = styled(Highlight)(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 'bold'
}));

interface OptionWrapperProps extends ThemeProps {
  focused: boolean;
}
const OptionWrapper: any = styled.div(({ theme, focused }: OptionWrapperProps) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '.global-hover': {
    color: focused ? theme.custom.colors.group7.base : undefined,
    em: {
      color: focused ? theme.custom.colors.group7.base : theme.custom.colors.black,
      fontStyle: 'normal'
    },
    span: {
      color: focused ? theme.custom.colors.group7.base : theme.custom.colors.group10.dark
    }
  }
}));

const SubtitleWrapper = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  ...(theme.custom.typography.labels.attribute as any),
  fontWeight: 500
}));

const TitleWrapper = styled.div({
  display: 'inline-block'
});

const Title = styled.div({});

const iconStyles = {
  fontSize: 20,
  marginRight: 20
};

export const OptionComponent = (props: OptionComponentProps) => {
  const { data, attribute, getIcon, getSubtitle, isFocused } = props;
  return (
    <components.Option {...(props as any)}>
      <OptionWrapper focused={isFocused}>
        {getIcon && <Icon name={getIcon(data)} css={iconStyles} className={'global-hover'} />}
        <TitleWrapper>
          <Title className={'global-hover'}>
            <HighlightStyled hit={data} attribute={attribute} />
          </Title>
          {getSubtitle && <SubtitleWrapper className={'global-hover'}>{getSubtitle(data)}</SubtitleWrapper>}
        </TitleWrapper>
      </OptionWrapper>
    </components.Option>
  );
};

export const Option = withTheme(OptionComponent);
