import { AgentsNav } from '@components/agents/nav/AgentsNav';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useIsAgent } from '@components/hooks/isAgent';
import { useDispatch, useSelector } from 'react-redux';
import { getAgent } from '@state/agent/agentSelectors';
import { clearAgent, performFetchAgent, performLogoutAgent } from '@state/agent/agentOperations';
import { usePrevious } from 'react-use';

export const AgentsNavContainer = () => {
  const dispatch = useDispatch();
  const isAgent: boolean = useIsAgent();
  const prevIsAgent = usePrevious(isAgent);
  const agent = useSelector(getAgent);
  const router = useRouter();
  const routerReady = router.isReady;

  useEffect(() => {
    if (isAgent) {
      if (routerReady) {
        dispatch(performFetchAgent());
      }
    } else if (!isAgent && prevIsAgent) {
      dispatch(clearAgent());
    }
  }, [isAgent, routerReady]);
  const handleOnLogout = () => {
    dispatch(performLogoutAgent());
  };
  if (!agent.data.abta || !isAgent) return null;
  return <AgentsNav agent={agent.data} onLogout={handleOnLogout} />;
};
