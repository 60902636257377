import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';
import React, { FC } from 'react';
import { SerializedStyles } from '@emotion/serialize';
import { TestId } from '@components/test-ids';
import { useI18NextContext } from '@components/hooks';
import { Type } from '@components/footer/type';
import { mixins } from '@styles/mixins';
import { ThemeProps, CssThemeProps } from '@theme/base';

export interface OpeningTimesTableProps {
  title?: string;
  days: Array<string>;
  hours: Array<string>;
  variant?: Type;
  contentCss?: SerializedStyles;
}

const OpeningTimesContainer = styled.div({
  fontWeight: 'normal'
});

interface OpeningTimesTitleProps extends ThemeProps {
  isCheckout: boolean;
}
const OpeningTimesTitle: any = styled.div(({ theme, isCheckout }: OpeningTimesTitleProps) => ({
  ...(theme.custom.typography.paragraph as any),
  ...(!isCheckout && {
    color: theme.custom.colors.white,
    ...mixins(theme).textShadow
  })
}));

const OpeningTimesContent: any = styled.div(({ theme, css }: CssThemeProps) => ({
  marginTop: theme.custom.spacing.medium,
  display: 'flex',
  [mq.small]: {
    justifyContent: 'flex-end'
  },
  ...css
}));

const OpeningTimesDays: any = styled.div(({ theme, isCheckout }: OpeningTimesTitleProps) => ({
  ...(theme.custom.typography.paragraph as any),
  ...(!isCheckout && {
    color: theme.custom.colors.white,
    ...mixins(theme).textShadow
  }),
  marginRight: theme.custom.spacing.xSmall
}));

const OpeningTimesHours: any = styled.div(({ theme, isCheckout }: OpeningTimesTitleProps) => ({
  ...(theme.custom.typography.paragraph as any),
  ...(!isCheckout && {
    color: theme.custom.colors.white,
    ...mixins(theme).textShadow
  })
}));

const OpeningTimesRow = styled.div(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.xSmall
}));

export interface ListItemProps {
  list: Array<string>;
}

const ListItems: FC<ListItemProps> = (props: ListItemProps) => (
  <>
    {props.list.map((item: string, index: number) => (
      <OpeningTimesRow key={index}>{item}</OpeningTimesRow>
    ))}
  </>
);

export const OpeningTimesTable = ({ variant, title, contentCss, days, hours }: OpeningTimesTableProps) => {
  const isCheckout = variant === Type.CHECKOUT;
  const t: any = useI18NextContext();

  return (
    <OpeningTimesContainer data-testid={TestId.openingTimesTable.main}>
      {title && <OpeningTimesTitle isCheckout={isCheckout}>{t(title)}</OpeningTimesTitle>}
      <OpeningTimesContent css={contentCss}>
        <OpeningTimesDays isCheckout={isCheckout}>
          <ListItems list={days} />
        </OpeningTimesDays>
        <OpeningTimesHours isCheckout={isCheckout}>
          <ListItems list={hours} />
        </OpeningTimesHours>
      </OpeningTimesContent>
    </OpeningTimesContainer>
  );
};
