import React from 'react';
import { useSelector } from 'react-redux';
import { getModalData } from '@state/modal/modalSelectors';
import { ErrorModal } from '@components/common/modal/alert/error/ErrorModal';
import { useRouter } from 'next/router';
import { Page } from '@model/common';

export const BasketErrorModalContainer = () => {
  const router = useRouter();
  const handleOnClick = () => router.push(Page.HOME);
  const modalData = useSelector(getModalData);
  return <ErrorModal onClick={handleOnClick} code={modalData?.code} />;
};
