import Link from 'next/link';
import React from 'react';
import { DestinationLinkBuilder, LinkItem } from '@model/iceberg/geography';
import { PlainLinkStyles } from '@styles/common';

export interface ResortsLinkProps {
  path: string;
  children?: JSX.Element;
}

export const ResortsLink = ({ path, children }: ResortsLinkProps) => {
  const linkBuilder: DestinationLinkBuilder = new DestinationLinkBuilder();
  const link: LinkItem = linkBuilder.getResortLink(path);
  return (
    <Link prefetch={false} {...link} passHref style={PlainLinkStyles}>
      {children}
    </Link>
  );
};
