import Link from 'next/link';
import React from 'react';
import { DestinationLinkBuilder, LinkItem } from '@model/iceberg/geography';
import { TripTypes } from '@model/contentful';
import { Page } from '@model/common';
import { PlainLinkStyles } from '@styles/common';

export interface GuidesLinkProps {
  path: string;
  children?: JSX.Element;
  tripType?: TripTypes;
  guidePage: Page.TRAVEL_GUIDES | Page.VIDEO_GUIDES;
}

export const GuidesLink = ({ path, children, tripType, guidePage }: GuidesLinkProps) => {
  const builder: DestinationLinkBuilder = new DestinationLinkBuilder();
  const travelGuideLink: LinkItem = builder.getGuidesLink(path, guidePage, tripType);
  return (
    <Link prefetch={false} {...travelGuideLink} passHref style={PlainLinkStyles}>
      {children}
    </Link>
  );
};
