import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';
import { Theme, ThemeProps } from '@theme/base/theme';
import { boxShadowMixins } from '@styles/mixins';

export const LINE_HEIGHT = 30;
export const SEARCH_HOVER: string = 'search-hover';

export const Active = ({ theme }: ThemeProps) => ({
  borderColor: 'transparent',
  boxShadow: boxShadowMixins(theme.custom.colors.group10.base).glow,
  ['> i']: {
    color: theme.custom.colors.group1.base
  }
});

export const Error = ({ theme }: ThemeProps) => ({
  borderColor: theme.custom.states.error.dark,
  '.search-hover': {
    color: theme.custom.states.error.dark
  }
});

export const Hover = ({ theme }: ThemeProps) => ({
  borderColor: 'transparent',
  boxShadow: boxShadowMixins(theme.custom.colors.group10.base).glow,
  ['> i']: {
    color: theme.custom.colors.group1.base
  }
});

export const Container: any = styled.div(({ theme }: ThemeProps) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  borderRadius: 2,
  flex: 1,
  backgroundColor: theme.custom.colors.white,
  cursor: 'pointer',
  outline: 'none',
  border: '1px solid',
  borderColor: theme.custom.colors.group10.base,
  height: 42,
  boxShadow: 'none',
  [mq.large]: {
    border: `1px solid ${theme.custom.colors.group10.base}`
  },
  '&.active': Active({ theme }),
  ':hover': Hover({ theme }),
  '&.error': Error({ theme })
}));

export const Title = styled.h4(({ theme }: ThemeProps) => ({
  margin: 0,
  padding: 0,
  [mq.large]: {
    ...(theme.custom.typography.labels.small as any),
    fontWeight: 500
  }
}));

export const InputContainer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%'
}));

export const iconStyles: any = (theme: Theme) => ({
  color: theme.custom.colors.group4.light,
  fontSize: '2rem',
  paddingLeft: theme.custom.spacing.small,
  paddingRight: theme.custom.spacing.small,
  [mq.small]: {
    paddingLeft: theme.custom.spacing.medium,
    paddingRight: theme.custom.spacing.medium
  },
  [mq.ie()]: {
    top: '50%',
    marginTop: '-1rem'
  }
});

export const LabelBase: any = styled.div(({ theme }: ThemeProps) => ({
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  height: LINE_HEIGHT,
  ...(theme.custom.typography.labels.default as any)
}));
