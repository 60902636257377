import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';
import { Active, Error, Hover } from '@components/search/tabs/common/search-container';
import React from 'react';
import classNames from 'classnames';
import { ErrorLabel } from '../styles/Styles';
import { useI18NextContext } from '@components/hooks';

const FULL_HEIGHT = 80;
const CONDENSED_HEIGHT = 40;

export interface SearchContainerProps {
  children: any;
  onMouseDown?: () => void;
  onClick: (event: any) => void;
  active: boolean;
  condensed: boolean;
  error?: string;
  containerRef?: any;
  tabIndex?: number;
  onBlur?: any;
}

const Container: any = styled.div((props: any) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  borderRadius: 2,
  flex: '1 0 auto',
  backgroundColor: props.theme.custom.colors.white,
  cursor: 'pointer',
  outline: 'none',
  border: '1px solid',
  borderColor: props.theme.custom.colors.group10.base,
  height: CONDENSED_HEIGHT,
  boxShadow: 'none',
  [mq.large]: {
    border: `1px solid ${props.theme.custom.colors.group10.base}`,
    height: !props.condensed ? FULL_HEIGHT : CONDENSED_HEIGHT
  },
  '&.active': Active(props),
  ':hover': Hover(props),
  '&.error': Error(props)
}));

export const SearchContainer = ({
  children,
  onClick,
  active,
  condensed,
  error,
  containerRef,
  tabIndex,
  onBlur,
  onMouseDown
}: SearchContainerProps) => {
  const t: any = useI18NextContext();
  return (
    <>
      <Container
        ref={containerRef}
        onClick={onClick}
        condensed={condensed}
        tabIndex={tabIndex}
        onBlur={onBlur}
        onMouseDown={onMouseDown}
        className={classNames({ active, error }, 'search-input-wrapper')}
      >
        {children}
      </Container>
      {error && <ErrorLabel condensed={condensed}>{t(error)}</ErrorLabel>}
    </>
  );
};
