import styled from '@emotion/styled';
import React from 'react';
import { TestId } from '@components/test-ids';
import { ThemeProps } from '@theme/base';
import { mq } from '@styles/breakpoints';
import { ZIndex } from '@styles/z-index';

/* ***************** *
 *       Types       *
 * ***************** */
export interface PromoBannerProps {
  testId?: string;
  bannerTextColor: string;
  bannerBackgroundColor: string;
  bannerLinkText: string;
  bannerText: string;
  onClick: () => void;
  className?: string;
}

/* ***************** *
 *       Styles      *
 * ***************** */
interface ContainerProps extends ThemeProps {
  backgroundColor: string;
}
const Container: any = styled.div<any>(({ theme, backgroundColor }: ContainerProps) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 10,
  backgroundColor: backgroundColor || theme.custom.colors.group3.darker,
  zIndex: ZIndex.PROMO_BANNER,

  [mq.small]: {
    flexDirection: 'row',
    justifyContent: 'center'
  }
}));

interface ColorProps extends ThemeProps {
  color: string;
}
const BannerText: any = styled.a(({ theme, color }: ColorProps) => ({
  ...(theme.custom.typography.paragraph as any),
  cursor: 'pointer',
  display: 'flex',
  fontWeight: 'bold',
  textAlign: 'center',
  textDecoration: 'none',
  marginRight: 10,
  color: color || theme.custom.colors.white,

  ['&:hover']: {
    textDecoration: 'underline'
  }
}));

const BannerLink = styled.a<any>(({ theme, color }: ColorProps) => ({
  ...(theme.custom.typography.paragraph as any),
  margin: 0,
  color: color || theme.custom.colors.white,
  cursor: 'pointer',
  textDecoration: 'none',

  ['&:hover']: {
    color: color || theme.custom.colors.white,
    textDecoration: 'underline'
  }
}));

/* **************** *
 *    PromoBanner   *
 * **************** */

export const BannerBase = ({
  bannerBackgroundColor,
  bannerTextColor,
  testId,
  bannerText,
  bannerLinkText,
  onClick,
  className
}: PromoBannerProps) => {
  /* *** LOGIC *** */
  const handleOpenModal = () => onClick();

  return (
    <Container
      backgroundColor={bannerBackgroundColor}
      data-testid={testId || TestId.promoBanner.main}
      className={className}
    >
      <BannerText color={bannerTextColor} suppressHydrationWarning onClick={handleOpenModal}>
        {bannerText}
      </BannerText>
      <BannerLink color={bannerTextColor} suppressHydrationWarning onClick={handleOpenModal}>
        {bannerLinkText}
      </BannerLink>
    </Container>
  );
};
