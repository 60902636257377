import { ZIndex } from '@styles/z-index';
import { Theme } from '@theme/base';
import { CSSObject } from '@emotion/serialize';

export const styles = (theme: Theme) => ({
  container: () =>
    ({
      position: 'static'
    }) as CSSObject,
  control: (base: any) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    borderRadius: 0,
    margin: 0,
    padding: 0,
    ...(theme.custom.typography.labels.default as any),
    backgroundColor: theme.custom.colors.white,
    minHeight: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  }),
  input: () => ({
    margin: 0,
    padding: 0
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 0,
    paddingLeft: 4,
    paddingRight: 4,
    '&:hover': {
      color: theme.custom.colors.group11.base
    }
  }),
  valueContainer: (base: any) => ({
    ...base,
    margin: 0,
    padding: 0
  }),
  placeholder: (base: any) => ({
    ...base,
    ...(theme.custom.typography.labels.responsive as any),
    color: theme.custom.colors.group4.light,
    display: 'flex',
    alignItems: 'center'
  }),
  singleValue: (base: any) => ({
    ...base,
    ...(theme.custom.typography.labels.default as any),
    '&:focus': {
      display: 'none'
    }
  }),
  menu: (base: any) => ({
    ...base,
    left: 0,
    marginTop: 0,
    top: 40,
    borderRadius: 2,
    zIndex: ZIndex.SEARCH_DROPDOWN,
    boxShadow: 'none',
    width: '100%',
    '> div': {
      paddingBottom: 0,
      paddingTop: 0
    }
  }),
  menuList: (base: any) => ({
    ...base,
    ...(theme.custom.typography.labels.default as any),
    border: `1px solid ${theme.custom.colors.group10.light}`
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    ...(theme.custom.typography.labels.default as any)
  }),
  option: (base: any) => ({
    ...base,
    backgroundColor: theme.custom.colors.white,
    color: theme.custom.colors.group4.base,
    padding: '4px 12px',
    '&:active': {
      backgroundColor: theme.custom.colors.white
    }
  })
});
