import { ClickAwayListener } from '@mui/material';
import styled from '@emotion/styled';
import { ThemeProps, DisableableThemeProps } from '@theme/base';
import { ZIndex } from '@styles/z-index';
import { boxShadowMixins } from '@styles/mixins';
import { mq } from '@styles/breakpoints';
import { TestId } from '@components/test-ids';
import React from 'react';

/* ***************** *
 *       Types       *
 * ***************** */
export interface DestinationsListDropdownProps {
  onClickAway: () => void;
  condensed: boolean;
  destinations: Array<any>;
  renderDestination: (destination: any) => JSX.Element;
  show: boolean;
  searchableDestination: string;
  testId?: string;
}

/* ****************** *
 *       Styles       *
 * ****************** */

interface SearchableDestinationsDropdownContainerProps extends ThemeProps {
  condensed: boolean;
}
const SearchableDestinationsDropdownContainer: any = styled.div(
  ({ theme, condensed }: SearchableDestinationsDropdownContainerProps) => ({
    position: 'absolute',
    top: 55,
    maxWidth: condensed ? theme.custom.contentSizes.maxContentWidth - 2 * theme.custom.spacing.medium : 'auto',
    width: `calc(100% - ${
      condensed ? 2 * theme.custom.spacing.medium : theme.custom.spacing.medium + theme.custom.spacing.xSmall
    }px)`,
    maxHeight: 500,
    overflowY: 'scroll',
    backgroundColor: theme.custom.colors.white,
    zIndex: ZIndex.SEARCHABLE_DESTINATIONS_DROPDOWN,
    boxShadow: boxShadowMixins(theme.custom.colors.group4.light).default,

    [mq.large]: {
      top: condensed ? 55 : 95
    }
  })
);

const SearchableDestinationsDropdown: any = styled.ul(({ theme }: ThemeProps) => ({
  display: 'block',
  columnCount: 1,
  margin: 0,
  padding: `${theme.custom.spacing.medium / 2}px ${theme.custom.spacing.medium}px`,

  [mq.small]: {
    columnCount: 3
  },

  [mq.medium]: {
    columnCount: 5
  }
}));

export const SearchableDestinationItem: any = styled.li(({ theme, isDisabled }: DisableableThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingBottom: theme.custom.spacing.xSmall / 2,
  paddingTop: theme.custom.spacing.xSmall / 2,
  ...(theme.custom.typography.paragraph as any),
  transition: `color ${theme.custom.transitions.default}`,
  fontWeight: isDisabled ? 'normal' : 500,
  color: isDisabled ? theme.custom.colors.group4.light : theme.custom.colors.group4.base,
  cursor: isDisabled ? 'default' : 'pointer',

  ['&:hover, &:hover i']: {
    color: isDisabled ? theme.custom.colors.group4.light : theme.custom.colors.group1.base
  }
}));

/* ************************************ *
 *       DestinationsListDropdown       *
 * ************************************ */

export const DestinationsListDropdown = ({
  show,
  onClickAway,
  condensed,
  destinations,
  testId,
  renderDestination
}: DestinationsListDropdownProps) => {
  /* *** RENDERERS *** */
  const renderSearchableDestinations = () => destinations.map((destination: any) => renderDestination(destination));

  if (!show) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <SearchableDestinationsDropdownContainer
        condensed={condensed}
        data-testid={testId || TestId.searchDestinationsListDropdown.main}
      >
        <SearchableDestinationsDropdown>{renderSearchableDestinations()}</SearchableDestinationsDropdown>
      </SearchableDestinationsDropdownContainer>
    </ClickAwayListener>
  );
};
